/*!
*   _____       _             __                 _____                               ______ _       _   _
*  |_   _|     | |           / _|               |_   _|                             |  ____| |     | | (_)
*    | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___    | |  ___ ___  _ __  ___   ______  | |__  | | __ _| |_ _  ___ ___  _ __
*    | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \   | | / __/ _ \| '_ \/ __| |______| |  __| | |/ _` | __| |/ __/ _ \| '_ \
*   _| |_| | | | ||  __/ |  | || (_| | (_|  __/  _| || (_| (_) | | | \__ \          | |    | | (_| | |_| | (_| (_) | | | |
*  |_____|_| |_|\__\___|_|  |_| \__,_|\___\___| |_____\___\___/|_| |_|___/          |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*                         UIcons 2.1.0 - https://www.flaticon.com/uicons/interface-icons
*/
@font-face {
    font-family: "uicons-solid-rounded";
    src: url("../../webfonts/uicons-solid-rounded.woff2") format("woff2"),
    url("../../webfonts/uicons-solid-rounded.woff") format("woff"),
    url("../../webfonts/uicons-solid-rounded.eot#iefix") format("embedded-opentype");
    font-display: swap;
}
    i[class^="fi-sr-"]:before, i[class*=" fi-sr-"]:before, span[class^="fi-sr-"]:before, span[class*="fi-sr-"]:before {
font-family: uicons-solid-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-sr-0:before {
    content: "\f101";
    }
        .fi-sr-1:before {
    content: "\f102";
    }
        .fi-sr-2:before {
    content: "\f103";
    }
        .fi-sr-3:before {
    content: "\f104";
    }
        .fi-sr-360-degrees:before {
    content: "\f105";
    }
        .fi-sr-4:before {
    content: "\f106";
    }
        .fi-sr-5:before {
    content: "\f107";
    }
        .fi-sr-6:before {
    content: "\f108";
    }
        .fi-sr-7:before {
    content: "\f109";
    }
        .fi-sr-8:before {
    content: "\f10a";
    }
        .fi-sr-9:before {
    content: "\f10b";
    }
        .fi-sr-a:before {
    content: "\f10c";
    }
        .fi-sr-accident:before {
    content: "\f10d";
    }
        .fi-sr-acorn:before {
    content: "\f10e";
    }
        .fi-sr-ad:before {
    content: "\f10f";
    }
        .fi-sr-add-document:before {
    content: "\f110";
    }
        .fi-sr-add-folder:before {
    content: "\f111";
    }
        .fi-sr-add-image:before {
    content: "\f112";
    }
        .fi-sr-add:before {
    content: "\f113";
    }
        .fi-sr-address-book:before {
    content: "\f114";
    }
        .fi-sr-address-card:before {
    content: "\f115";
    }
        .fi-sr-admin-alt:before {
    content: "\f116";
    }
        .fi-sr-admin:before {
    content: "\f117";
    }
        .fi-sr-air-conditioner:before {
    content: "\f118";
    }
        .fi-sr-air-freshener:before {
    content: "\f119";
    }
        .fi-sr-airplane-journey:before {
    content: "\f11a";
    }
        .fi-sr-airplay:before {
    content: "\f11b";
    }
        .fi-sr-alarm-clock:before {
    content: "\f11c";
    }
        .fi-sr-alarm-exclamation:before {
    content: "\f11d";
    }
        .fi-sr-alarm-plus:before {
    content: "\f11e";
    }
        .fi-sr-alarm-snooze:before {
    content: "\f11f";
    }
        .fi-sr-album-circle-plus:before {
    content: "\f120";
    }
        .fi-sr-album-circle-user:before {
    content: "\f121";
    }
        .fi-sr-album-collection:before {
    content: "\f122";
    }
        .fi-sr-album:before {
    content: "\f123";
    }
        .fi-sr-algorithm:before {
    content: "\f124";
    }
        .fi-sr-alicorn:before {
    content: "\f125";
    }
        .fi-sr-alien:before {
    content: "\f126";
    }
        .fi-sr-align-center:before {
    content: "\f127";
    }
        .fi-sr-align-justify:before {
    content: "\f128";
    }
        .fi-sr-align-left:before {
    content: "\f129";
    }
        .fi-sr-align-slash:before {
    content: "\f12a";
    }
        .fi-sr-ambulance:before {
    content: "\f12b";
    }
        .fi-sr-amusement-park:before {
    content: "\f12c";
    }
        .fi-sr-anchor:before {
    content: "\f12d";
    }
        .fi-sr-angel:before {
    content: "\f12e";
    }
        .fi-sr-angle-90:before {
    content: "\f12f";
    }
        .fi-sr-angle-circle-down:before {
    content: "\f130";
    }
        .fi-sr-angle-circle-left:before {
    content: "\f131";
    }
        .fi-sr-angle-circle-right:before {
    content: "\f132";
    }
        .fi-sr-angle-circle-up:before {
    content: "\f133";
    }
        .fi-sr-angle-double-left:before {
    content: "\f134";
    }
        .fi-sr-angle-double-right:before {
    content: "\f135";
    }
        .fi-sr-angle-double-small-down:before {
    content: "\f136";
    }
        .fi-sr-angle-double-small-left:before {
    content: "\f137";
    }
        .fi-sr-angle-double-small-right:before {
    content: "\f138";
    }
        .fi-sr-angle-double-small-up:before {
    content: "\f139";
    }
        .fi-sr-angle-down:before {
    content: "\f13a";
    }
        .fi-sr-angle-left:before {
    content: "\f13b";
    }
        .fi-sr-angle-right:before {
    content: "\f13c";
    }
        .fi-sr-angle-small-down:before {
    content: "\f13d";
    }
        .fi-sr-angle-small-left:before {
    content: "\f13e";
    }
        .fi-sr-angle-small-right:before {
    content: "\f13f";
    }
        .fi-sr-angle-small-up:before {
    content: "\f140";
    }
        .fi-sr-angle-square-down:before {
    content: "\f141";
    }
        .fi-sr-angle-square-left:before {
    content: "\f142";
    }
        .fi-sr-angle-square-right:before {
    content: "\f143";
    }
        .fi-sr-angle-square-up:before {
    content: "\f144";
    }
        .fi-sr-angle-up:before {
    content: "\f145";
    }
        .fi-sr-angle:before {
    content: "\f146";
    }
        .fi-sr-angry:before {
    content: "\f147";
    }
        .fi-sr-animated-icon:before {
    content: "\f148";
    }
        .fi-sr-answer-alt:before {
    content: "\f149";
    }
        .fi-sr-answer:before {
    content: "\f14a";
    }
        .fi-sr-apartment:before {
    content: "\f14b";
    }
        .fi-sr-aperture:before {
    content: "\f14c";
    }
        .fi-sr-api:before {
    content: "\f14d";
    }
        .fi-sr-apple-core:before {
    content: "\f14e";
    }
        .fi-sr-apple-crate:before {
    content: "\f14f";
    }
        .fi-sr-apple-whole:before {
    content: "\f150";
    }
        .fi-sr-apps-add:before {
    content: "\f151";
    }
        .fi-sr-apps-delete:before {
    content: "\f152";
    }
        .fi-sr-apps-sort:before {
    content: "\f153";
    }
        .fi-sr-apps:before {
    content: "\f154";
    }
        .fi-sr-archive:before {
    content: "\f155";
    }
        .fi-sr-archway:before {
    content: "\f156";
    }
        .fi-sr-arrow-alt-circle-down:before {
    content: "\f157";
    }
        .fi-sr-arrow-alt-circle-left:before {
    content: "\f158";
    }
        .fi-sr-arrow-alt-circle-right:before {
    content: "\f159";
    }
        .fi-sr-arrow-alt-circle-up:before {
    content: "\f15a";
    }
        .fi-sr-arrow-alt-down:before {
    content: "\f15b";
    }
        .fi-sr-arrow-alt-from-bottom:before {
    content: "\f15c";
    }
        .fi-sr-arrow-alt-from-left:before {
    content: "\f15d";
    }
        .fi-sr-arrow-alt-from-right:before {
    content: "\f15e";
    }
        .fi-sr-arrow-alt-from-top:before {
    content: "\f15f";
    }
        .fi-sr-arrow-alt-left:before {
    content: "\f160";
    }
        .fi-sr-arrow-alt-right:before {
    content: "\f161";
    }
        .fi-sr-arrow-alt-square-down:before {
    content: "\f162";
    }
        .fi-sr-arrow-alt-square-left:before {
    content: "\f163";
    }
        .fi-sr-arrow-alt-square-right:before {
    content: "\f164";
    }
        .fi-sr-arrow-alt-square-up:before {
    content: "\f165";
    }
        .fi-sr-arrow-alt-to-bottom:before {
    content: "\f166";
    }
        .fi-sr-arrow-alt-to-left:before {
    content: "\f167";
    }
        .fi-sr-arrow-alt-to-right:before {
    content: "\f168";
    }
        .fi-sr-arrow-alt-to-top:before {
    content: "\f169";
    }
        .fi-sr-arrow-alt-up:before {
    content: "\f16a";
    }
        .fi-sr-arrow-circle-down:before {
    content: "\f16b";
    }
        .fi-sr-arrow-circle-left:before {
    content: "\f16c";
    }
        .fi-sr-arrow-circle-right:before {
    content: "\f16d";
    }
        .fi-sr-arrow-circle-up:before {
    content: "\f16e";
    }
        .fi-sr-arrow-down-from-dotted-line:before {
    content: "\f16f";
    }
        .fi-sr-arrow-down-left:before {
    content: "\f170";
    }
        .fi-sr-arrow-down-small-big:before {
    content: "\f171";
    }
        .fi-sr-arrow-down-to-dotted-line:before {
    content: "\f172";
    }
        .fi-sr-arrow-down-to-square:before {
    content: "\f173";
    }
        .fi-sr-arrow-down-triangle-square:before {
    content: "\f174";
    }
        .fi-sr-arrow-down:before {
    content: "\f175";
    }
        .fi-sr-arrow-from-bottom:before {
    content: "\f176";
    }
        .fi-sr-arrow-from-left:before {
    content: "\f177";
    }
        .fi-sr-arrow-from-right:before {
    content: "\f178";
    }
        .fi-sr-arrow-from-top:before {
    content: "\f179";
    }
        .fi-sr-arrow-left-from-line:before {
    content: "\f17a";
    }
        .fi-sr-arrow-left:before {
    content: "\f17b";
    }
        .fi-sr-arrow-progress:before {
    content: "\f17c";
    }
        .fi-sr-arrow-right-to-bracket:before {
    content: "\f17d";
    }
        .fi-sr-arrow-right:before {
    content: "\f17e";
    }
        .fi-sr-arrow-small-down:before {
    content: "\f17f";
    }
        .fi-sr-arrow-small-left:before {
    content: "\f180";
    }
        .fi-sr-arrow-small-right:before {
    content: "\f181";
    }
        .fi-sr-arrow-small-up:before {
    content: "\f182";
    }
        .fi-sr-arrow-square-down:before {
    content: "\f183";
    }
        .fi-sr-arrow-square-left:before {
    content: "\f184";
    }
        .fi-sr-arrow-square-right:before {
    content: "\f185";
    }
        .fi-sr-arrow-square-up:before {
    content: "\f186";
    }
        .fi-sr-arrow-to-bottom:before {
    content: "\f187";
    }
        .fi-sr-arrow-to-left:before {
    content: "\f188";
    }
        .fi-sr-arrow-to-right:before {
    content: "\f189";
    }
        .fi-sr-arrow-to-top:before {
    content: "\f18a";
    }
        .fi-sr-arrow-trend-down:before {
    content: "\f18b";
    }
        .fi-sr-arrow-trend-up:before {
    content: "\f18c";
    }
        .fi-sr-arrow-turn-down-left:before {
    content: "\f18d";
    }
        .fi-sr-arrow-turn-down-right:before {
    content: "\f18e";
    }
        .fi-sr-arrow-up-from-dotted-line:before {
    content: "\f18f";
    }
        .fi-sr-arrow-up-from-square:before {
    content: "\f190";
    }
        .fi-sr-arrow-up-left-from-circle:before {
    content: "\f191";
    }
        .fi-sr-arrow-up-left:before {
    content: "\f192";
    }
        .fi-sr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f193";
    }
        .fi-sr-arrow-up-right-from-square:before {
    content: "\f194";
    }
        .fi-sr-arrow-up-right:before {
    content: "\f195";
    }
        .fi-sr-arrow-up-small-big:before {
    content: "\f196";
    }
        .fi-sr-arrow-up-square-triangle:before {
    content: "\f197";
    }
        .fi-sr-arrow-up-to-dotted-line:before {
    content: "\f198";
    }
        .fi-sr-arrow-up:before {
    content: "\f199";
    }
        .fi-sr-arrows-alt-h:before {
    content: "\f19a";
    }
        .fi-sr-arrows-alt-v:before {
    content: "\f19b";
    }
        .fi-sr-arrows-alt:before {
    content: "\f19c";
    }
        .fi-sr-arrows-cross:before {
    content: "\f19d";
    }
        .fi-sr-arrows-from-dotted-line:before {
    content: "\f19e";
    }
        .fi-sr-arrows-from-line:before {
    content: "\f19f";
    }
        .fi-sr-arrows-h-copy:before {
    content: "\f1a0";
    }
        .fi-sr-arrows-h:before {
    content: "\f1a1";
    }
        .fi-sr-arrows-repeat-1:before {
    content: "\f1a2";
    }
        .fi-sr-arrows-repeat:before {
    content: "\f1a3";
    }
        .fi-sr-arrows-retweet:before {
    content: "\f1a4";
    }
        .fi-sr-arrows-to-dotted-line:before {
    content: "\f1a5";
    }
        .fi-sr-arrows-to-eye:before {
    content: "\f1a6";
    }
        .fi-sr-arrows-to-line:before {
    content: "\f1a7";
    }
        .fi-sr-arrows:before {
    content: "\f1a8";
    }
        .fi-sr-artificial-intelligence:before {
    content: "\f1a9";
    }
        .fi-sr-assept-document:before {
    content: "\f1aa";
    }
        .fi-sr-assistive-listening-systems:before {
    content: "\f1ab";
    }
        .fi-sr-asterik:before {
    content: "\f1ac";
    }
        .fi-sr-at:before {
    content: "\f1ad";
    }
        .fi-sr-attribution-pen:before {
    content: "\f1ae";
    }
        .fi-sr-attribution-pencil:before {
    content: "\f1af";
    }
        .fi-sr-aubergine:before {
    content: "\f1b0";
    }
        .fi-sr-auction:before {
    content: "\f1b1";
    }
        .fi-sr-audio-description-slash:before {
    content: "\f1b2";
    }
        .fi-sr-audit-alt:before {
    content: "\f1b3";
    }
        .fi-sr-audit:before {
    content: "\f1b4";
    }
        .fi-sr-austral-sign:before {
    content: "\f1b5";
    }
        .fi-sr-avocado:before {
    content: "\f1b6";
    }
        .fi-sr-award:before {
    content: "\f1b7";
    }
        .fi-sr-axe-battle:before {
    content: "\f1b8";
    }
        .fi-sr-axe:before {
    content: "\f1b9";
    }
        .fi-sr-b:before {
    content: "\f1ba";
    }
        .fi-sr-baby-carriage:before {
    content: "\f1bb";
    }
        .fi-sr-baby:before {
    content: "\f1bc";
    }
        .fi-sr-background:before {
    content: "\f1bd";
    }
        .fi-sr-backpack:before {
    content: "\f1be";
    }
        .fi-sr-bacon:before {
    content: "\f1bf";
    }
        .fi-sr-bacteria:before {
    content: "\f1c0";
    }
        .fi-sr-bacterium:before {
    content: "\f1c1";
    }
        .fi-sr-badge-check:before {
    content: "\f1c2";
    }
        .fi-sr-badge-dollar:before {
    content: "\f1c3";
    }
        .fi-sr-badge-percent:before {
    content: "\f1c4";
    }
        .fi-sr-badge-sheriff:before {
    content: "\f1c5";
    }
        .fi-sr-badge:before {
    content: "\f1c6";
    }
        .fi-sr-badger-honey:before {
    content: "\f1c7";
    }
        .fi-sr-badminton:before {
    content: "\f1c8";
    }
        .fi-sr-bag-seedling:before {
    content: "\f1c9";
    }
        .fi-sr-bags-shopping:before {
    content: "\f1ca";
    }
        .fi-sr-baguette:before {
    content: "\f1cb";
    }
        .fi-sr-bahai:before {
    content: "\f1cc";
    }
        .fi-sr-baht-sign:before {
    content: "\f1cd";
    }
        .fi-sr-balance-scale-left:before {
    content: "\f1ce";
    }
        .fi-sr-balance-scale-right:before {
    content: "\f1cf";
    }
        .fi-sr-ball-pile:before {
    content: "\f1d0";
    }
        .fi-sr-balloon:before {
    content: "\f1d1";
    }
        .fi-sr-balloons:before {
    content: "\f1d2";
    }
        .fi-sr-ballot-check:before {
    content: "\f1d3";
    }
        .fi-sr-ballot:before {
    content: "\f1d4";
    }
        .fi-sr-ban-bug:before {
    content: "\f1d5";
    }
        .fi-sr-ban:before {
    content: "\f1d6";
    }
        .fi-sr-banana:before {
    content: "\f1d7";
    }
        .fi-sr-band-aid:before {
    content: "\f1d8";
    }
        .fi-sr-bangladeshi-taka-sign:before {
    content: "\f1d9";
    }
        .fi-sr-banjo:before {
    content: "\f1da";
    }
        .fi-sr-bank:before {
    content: "\f1db";
    }
        .fi-sr-barber-shop:before {
    content: "\f1dc";
    }
        .fi-sr-barcode-read:before {
    content: "\f1dd";
    }
        .fi-sr-barcode-scan:before {
    content: "\f1de";
    }
        .fi-sr-barcode:before {
    content: "\f1df";
    }
        .fi-sr-bars-filter:before {
    content: "\f1e0";
    }
        .fi-sr-bars-progress:before {
    content: "\f1e1";
    }
        .fi-sr-bars-sort:before {
    content: "\f1e2";
    }
        .fi-sr-bars-staggered:before {
    content: "\f1e3";
    }
        .fi-sr-baseball-alt:before {
    content: "\f1e4";
    }
        .fi-sr-baseball:before {
    content: "\f1e5";
    }
        .fi-sr-basket-shopping-simple:before {
    content: "\f1e6";
    }
        .fi-sr-basketball-hoop:before {
    content: "\f1e7";
    }
        .fi-sr-basketball:before {
    content: "\f1e8";
    }
        .fi-sr-bat:before {
    content: "\f1e9";
    }
        .fi-sr-bath:before {
    content: "\f1ea";
    }
        .fi-sr-battery-bolt:before {
    content: "\f1eb";
    }
        .fi-sr-battery-empty:before {
    content: "\f1ec";
    }
        .fi-sr-battery-exclamation:before {
    content: "\f1ed";
    }
        .fi-sr-battery-full:before {
    content: "\f1ee";
    }
        .fi-sr-battery-half:before {
    content: "\f1ef";
    }
        .fi-sr-battery-quarter:before {
    content: "\f1f0";
    }
        .fi-sr-battery-slash:before {
    content: "\f1f1";
    }
        .fi-sr-battery-three-quarters:before {
    content: "\f1f2";
    }
        .fi-sr-beacon:before {
    content: "\f1f3";
    }
        .fi-sr-bed-alt:before {
    content: "\f1f4";
    }
        .fi-sr-bed-bunk:before {
    content: "\f1f5";
    }
        .fi-sr-bed-empty:before {
    content: "\f1f6";
    }
        .fi-sr-bed:before {
    content: "\f1f7";
    }
        .fi-sr-bee:before {
    content: "\f1f8";
    }
        .fi-sr-beer-mug-empty:before {
    content: "\f1f9";
    }
        .fi-sr-beer:before {
    content: "\f1fa";
    }
        .fi-sr-bell-concierge:before {
    content: "\f1fb";
    }
        .fi-sr-bell-ring:before {
    content: "\f1fc";
    }
        .fi-sr-bell-school:before {
    content: "\f1fd";
    }
        .fi-sr-bell-slash:before {
    content: "\f1fe";
    }
        .fi-sr-bell:before {
    content: "\f1ff";
    }
        .fi-sr-bells:before {
    content: "\f200";
    }
        .fi-sr-bench-tree:before {
    content: "\f201";
    }
        .fi-sr-betamax:before {
    content: "\f202";
    }
        .fi-sr-bicycle-journey:before {
    content: "\f203";
    }
        .fi-sr-bike:before {
    content: "\f204";
    }
        .fi-sr-biking-mountain:before {
    content: "\f205";
    }
        .fi-sr-biking:before {
    content: "\f206";
    }
        .fi-sr-billiard:before {
    content: "\f207";
    }
        .fi-sr-binary-circle-check:before {
    content: "\f208";
    }
        .fi-sr-binary-lock:before {
    content: "\f209";
    }
        .fi-sr-binary-slash:before {
    content: "\f20a";
    }
        .fi-sr-binary:before {
    content: "\f20b";
    }
        .fi-sr-binoculars:before {
    content: "\f20c";
    }
        .fi-sr-biohazard:before {
    content: "\f20d";
    }
        .fi-sr-bird:before {
    content: "\f20e";
    }
        .fi-sr-bitcoin-sign:before {
    content: "\f20f";
    }
        .fi-sr-blanket:before {
    content: "\f210";
    }
        .fi-sr-blender-phone:before {
    content: "\f211";
    }
        .fi-sr-blender:before {
    content: "\f212";
    }
        .fi-sr-blinds-open:before {
    content: "\f213";
    }
        .fi-sr-blinds-raised:before {
    content: "\f214";
    }
        .fi-sr-blinds:before {
    content: "\f215";
    }
        .fi-sr-block-brick:before {
    content: "\f216";
    }
        .fi-sr-block-question:before {
    content: "\f217";
    }
        .fi-sr-block-quote:before {
    content: "\f218";
    }
        .fi-sr-block:before {
    content: "\f219";
    }
        .fi-sr-blog-pencil:before {
    content: "\f21a";
    }
        .fi-sr-blog-text:before {
    content: "\f21b";
    }
        .fi-sr-blossom:before {
    content: "\f21c";
    }
        .fi-sr-blueberries:before {
    content: "\f21d";
    }
        .fi-sr-bold:before {
    content: "\f21e";
    }
        .fi-sr-bolt-auto:before {
    content: "\f21f";
    }
        .fi-sr-bolt-slash:before {
    content: "\f220";
    }
        .fi-sr-bolt:before {
    content: "\f221";
    }
        .fi-sr-bomb:before {
    content: "\f222";
    }
        .fi-sr-bone-break:before {
    content: "\f223";
    }
        .fi-sr-bone:before {
    content: "\f224";
    }
        .fi-sr-bong:before {
    content: "\f225";
    }
        .fi-sr-book-alt:before {
    content: "\f226";
    }
        .fi-sr-book-arrow-right:before {
    content: "\f227";
    }
        .fi-sr-book-arrow-up:before {
    content: "\f228";
    }
        .fi-sr-book-atlas:before {
    content: "\f229";
    }
        .fi-sr-book-bookmark:before {
    content: "\f22a";
    }
        .fi-sr-book-circle-arrow-right:before {
    content: "\f22b";
    }
        .fi-sr-book-circle-arrow-up:before {
    content: "\f22c";
    }
        .fi-sr-book-copy:before {
    content: "\f22d";
    }
        .fi-sr-book-dead:before {
    content: "\f22e";
    }
        .fi-sr-book-font:before {
    content: "\f22f";
    }
        .fi-sr-book-medical:before {
    content: "\f230";
    }
        .fi-sr-book-open-cover:before {
    content: "\f231";
    }
        .fi-sr-book-open-reader:before {
    content: "\f232";
    }
        .fi-sr-book-quran:before {
    content: "\f233";
    }
        .fi-sr-book-section:before {
    content: "\f234";
    }
        .fi-sr-book-spells:before {
    content: "\f235";
    }
        .fi-sr-book-tanakh:before {
    content: "\f236";
    }
        .fi-sr-book-user:before {
    content: "\f237";
    }
        .fi-sr-book:before {
    content: "\f238";
    }
        .fi-sr-bookmark-slash:before {
    content: "\f239";
    }
        .fi-sr-bookmark:before {
    content: "\f23a";
    }
        .fi-sr-books-medical:before {
    content: "\f23b";
    }
        .fi-sr-books:before {
    content: "\f23c";
    }
        .fi-sr-boot-heeled:before {
    content: "\f23d";
    }
        .fi-sr-boot:before {
    content: "\f23e";
    }
        .fi-sr-border-all:before {
    content: "\f23f";
    }
        .fi-sr-border-bottom:before {
    content: "\f240";
    }
        .fi-sr-border-center-h:before {
    content: "\f241";
    }
        .fi-sr-border-center-v:before {
    content: "\f242";
    }
        .fi-sr-border-inner:before {
    content: "\f243";
    }
        .fi-sr-border-left:before {
    content: "\f244";
    }
        .fi-sr-border-none:before {
    content: "\f245";
    }
        .fi-sr-border-outer:before {
    content: "\f246";
    }
        .fi-sr-border-right:before {
    content: "\f247";
    }
        .fi-sr-border-style-alt:before {
    content: "\f248";
    }
        .fi-sr-border-style:before {
    content: "\f249";
    }
        .fi-sr-border-top:before {
    content: "\f24a";
    }
        .fi-sr-boss:before {
    content: "\f24b";
    }
        .fi-sr-bottle-droplet:before {
    content: "\f24c";
    }
        .fi-sr-bottle:before {
    content: "\f24d";
    }
        .fi-sr-bow-arrow:before {
    content: "\f24e";
    }
        .fi-sr-bowl-chopsticks-noodles:before {
    content: "\f24f";
    }
        .fi-sr-bowl-chopsticks:before {
    content: "\f250";
    }
        .fi-sr-bowl-rice:before {
    content: "\f251";
    }
        .fi-sr-bowl-scoop:before {
    content: "\f252";
    }
        .fi-sr-bowl-scoops:before {
    content: "\f253";
    }
        .fi-sr-bowl-soft-serve:before {
    content: "\f254";
    }
        .fi-sr-bowl-spoon:before {
    content: "\f255";
    }
        .fi-sr-bowling-ball:before {
    content: "\f256";
    }
        .fi-sr-bowling-pins:before {
    content: "\f257";
    }
        .fi-sr-bowling:before {
    content: "\f258";
    }
        .fi-sr-box-alt:before {
    content: "\f259";
    }
        .fi-sr-box-ballot:before {
    content: "\f25a";
    }
        .fi-sr-box-check:before {
    content: "\f25b";
    }
        .fi-sr-box-circle-check:before {
    content: "\f25c";
    }
        .fi-sr-box-dollar:before {
    content: "\f25d";
    }
        .fi-sr-box-fragile:before {
    content: "\f25e";
    }
        .fi-sr-box-heart:before {
    content: "\f25f";
    }
        .fi-sr-box-open-full:before {
    content: "\f260";
    }
        .fi-sr-box-open:before {
    content: "\f261";
    }
        .fi-sr-box-tissue:before {
    content: "\f262";
    }
        .fi-sr-box-up:before {
    content: "\f263";
    }
        .fi-sr-box:before {
    content: "\f264";
    }
        .fi-sr-boxes:before {
    content: "\f265";
    }
        .fi-sr-boxing-glove:before {
    content: "\f266";
    }
        .fi-sr-bracket-curly-right:before {
    content: "\f267";
    }
        .fi-sr-bracket-curly:before {
    content: "\f268";
    }
        .fi-sr-bracket-round-right:before {
    content: "\f269";
    }
        .fi-sr-bracket-round:before {
    content: "\f26a";
    }
        .fi-sr-bracket-square-right:before {
    content: "\f26b";
    }
        .fi-sr-bracket-square:before {
    content: "\f26c";
    }
        .fi-sr-brackets-curly:before {
    content: "\f26d";
    }
        .fi-sr-brackets-round:before {
    content: "\f26e";
    }
        .fi-sr-brackets-square:before {
    content: "\f26f";
    }
        .fi-sr-braille-a:before {
    content: "\f270";
    }
        .fi-sr-braille-b:before {
    content: "\f271";
    }
        .fi-sr-braille-c:before {
    content: "\f272";
    }
        .fi-sr-braille-d:before {
    content: "\f273";
    }
        .fi-sr-braille-e:before {
    content: "\f274";
    }
        .fi-sr-braille-g:before {
    content: "\f275";
    }
        .fi-sr-braille-h:before {
    content: "\f276";
    }
        .fi-sr-braille-i:before {
    content: "\f277";
    }
        .fi-sr-braille-j:before {
    content: "\f278";
    }
        .fi-sr-braille-k:before {
    content: "\f279";
    }
        .fi-sr-braille-l:before {
    content: "\f27a";
    }
        .fi-sr-braille-m:before {
    content: "\f27b";
    }
        .fi-sr-braille-n-alt:before {
    content: "\f27c";
    }
        .fi-sr-braille-n:before {
    content: "\f27d";
    }
        .fi-sr-braille-o:before {
    content: "\f27e";
    }
        .fi-sr-braille-p:before {
    content: "\f27f";
    }
        .fi-sr-braille-q:before {
    content: "\f280";
    }
        .fi-sr-braille-r:before {
    content: "\f281";
    }
        .fi-sr-braille-s:before {
    content: "\f282";
    }
        .fi-sr-braille-t:before {
    content: "\f283";
    }
        .fi-sr-braille-u:before {
    content: "\f284";
    }
        .fi-sr-braille-v:before {
    content: "\f285";
    }
        .fi-sr-braille-w:before {
    content: "\f286";
    }
        .fi-sr-braille-x:before {
    content: "\f287";
    }
        .fi-sr-braille-y:before {
    content: "\f288";
    }
        .fi-sr-braille-z:before {
    content: "\f289";
    }
        .fi-sr-braille:before {
    content: "\f28a";
    }
        .fi-sr-brain-circuit:before {
    content: "\f28b";
    }
        .fi-sr-brain:before {
    content: "\f28c";
    }
        .fi-sr-bread-loaf:before {
    content: "\f28d";
    }
        .fi-sr-bread-slice-butter:before {
    content: "\f28e";
    }
        .fi-sr-bread-slice:before {
    content: "\f28f";
    }
        .fi-sr-bread:before {
    content: "\f290";
    }
        .fi-sr-briefcase-arrow-right:before {
    content: "\f291";
    }
        .fi-sr-briefcase-blank:before {
    content: "\f292";
    }
        .fi-sr-briefcase:before {
    content: "\f293";
    }
        .fi-sr-brightness-low:before {
    content: "\f294";
    }
        .fi-sr-brightness:before {
    content: "\f295";
    }
        .fi-sr-bring-forward:before {
    content: "\f296";
    }
        .fi-sr-bring-front:before {
    content: "\f297";
    }
        .fi-sr-broadcast-tower:before {
    content: "\f298";
    }
        .fi-sr-broccoli:before {
    content: "\f299";
    }
        .fi-sr-broken-image:before {
    content: "\f29a";
    }
        .fi-sr-broom-ball:before {
    content: "\f29b";
    }
        .fi-sr-broom:before {
    content: "\f29c";
    }
        .fi-sr-browser-ui:before {
    content: "\f29d";
    }
        .fi-sr-browser:before {
    content: "\f29e";
    }
        .fi-sr-browsers:before {
    content: "\f29f";
    }
        .fi-sr-brush:before {
    content: "\f2a0";
    }
        .fi-sr-bucket:before {
    content: "\f2a1";
    }
        .fi-sr-budget-alt:before {
    content: "\f2a2";
    }
        .fi-sr-budget:before {
    content: "\f2a3";
    }
        .fi-sr-bug-slash:before {
    content: "\f2a4";
    }
        .fi-sr-bug:before {
    content: "\f2a5";
    }
        .fi-sr-bugs:before {
    content: "\f2a6";
    }
        .fi-sr-build-alt:before {
    content: "\f2a7";
    }
        .fi-sr-build:before {
    content: "\f2a8";
    }
        .fi-sr-building-ngo:before {
    content: "\f2a9";
    }
        .fi-sr-building:before {
    content: "\f2aa";
    }
        .fi-sr-bulb:before {
    content: "\f2ab";
    }
        .fi-sr-bullet:before {
    content: "\f2ac";
    }
        .fi-sr-bullhorn:before {
    content: "\f2ad";
    }
        .fi-sr-bullseye-arrow:before {
    content: "\f2ae";
    }
        .fi-sr-bullseye-pointer:before {
    content: "\f2af";
    }
        .fi-sr-bullseye:before {
    content: "\f2b0";
    }
        .fi-sr-burger-alt:before {
    content: "\f2b1";
    }
        .fi-sr-burger-fries:before {
    content: "\f2b2";
    }
        .fi-sr-burger-glass:before {
    content: "\f2b3";
    }
        .fi-sr-burrito:before {
    content: "\f2b4";
    }
        .fi-sr-bus-alt:before {
    content: "\f2b5";
    }
        .fi-sr-bus:before {
    content: "\f2b6";
    }
        .fi-sr-business-time:before {
    content: "\f2b7";
    }
        .fi-sr-Butter:before {
    content: "\f2b8";
    }
        .fi-sr-butterfly:before {
    content: "\f2b9";
    }
        .fi-sr-c:before {
    content: "\f2ba";
    }
        .fi-sr-cabin:before {
    content: "\f2bb";
    }
        .fi-sr-cactus:before {
    content: "\f2bc";
    }
        .fi-sr-cake-birthday:before {
    content: "\f2bd";
    }
        .fi-sr-cake-slice:before {
    content: "\f2be";
    }
        .fi-sr-cake-wedding:before {
    content: "\f2bf";
    }
        .fi-sr-calculator-bill:before {
    content: "\f2c0";
    }
        .fi-sr-calculator-money:before {
    content: "\f2c1";
    }
        .fi-sr-calculator-simple:before {
    content: "\f2c2";
    }
        .fi-sr-calculator:before {
    content: "\f2c3";
    }
        .fi-sr-calendar-arrow-down:before {
    content: "\f2c4";
    }
        .fi-sr-calendar-arrow-up:before {
    content: "\f2c5";
    }
        .fi-sr-calendar-check:before {
    content: "\f2c6";
    }
        .fi-sr-calendar-clock:before {
    content: "\f2c7";
    }
        .fi-sr-calendar-day:before {
    content: "\f2c8";
    }
        .fi-sr-calendar-days:before {
    content: "\f2c9";
    }
        .fi-sr-calendar-exclamation:before {
    content: "\f2ca";
    }
        .fi-sr-calendar-heart:before {
    content: "\f2cb";
    }
        .fi-sr-calendar-image:before {
    content: "\f2cc";
    }
        .fi-sr-calendar-lines-pen:before {
    content: "\f2cd";
    }
        .fi-sr-calendar-lines:before {
    content: "\f2ce";
    }
        .fi-sr-calendar-minus:before {
    content: "\f2cf";
    }
        .fi-sr-calendar-pen:before {
    content: "\f2d0";
    }
        .fi-sr-calendar-plus:before {
    content: "\f2d1";
    }
        .fi-sr-calendar-star:before {
    content: "\f2d2";
    }
        .fi-sr-calendar-week:before {
    content: "\f2d3";
    }
        .fi-sr-calendar-xmark:before {
    content: "\f2d4";
    }
        .fi-sr-calendar:before {
    content: "\f2d5";
    }
        .fi-sr-calendars:before {
    content: "\f2d6";
    }
        .fi-sr-call-history:before {
    content: "\f2d7";
    }
        .fi-sr-call-incoming:before {
    content: "\f2d8";
    }
        .fi-sr-call-missed:before {
    content: "\f2d9";
    }
        .fi-sr-call-outgoing:before {
    content: "\f2da";
    }
        .fi-sr-camcorder:before {
    content: "\f2db";
    }
        .fi-sr-camera-cctv:before {
    content: "\f2dc";
    }
        .fi-sr-camera-movie:before {
    content: "\f2dd";
    }
        .fi-sr-camera-retro:before {
    content: "\f2de";
    }
        .fi-sr-camera-rotate:before {
    content: "\f2df";
    }
        .fi-sr-camera-security:before {
    content: "\f2e0";
    }
        .fi-sr-camera-slash:before {
    content: "\f2e1";
    }
        .fi-sr-camera-viewfinder:before {
    content: "\f2e2";
    }
        .fi-sr-camera:before {
    content: "\f2e3";
    }
        .fi-sr-campfire:before {
    content: "\f2e4";
    }
        .fi-sr-camping:before {
    content: "\f2e5";
    }
        .fi-sr-can-food:before {
    content: "\f2e6";
    }
        .fi-sr-candle-holder:before {
    content: "\f2e7";
    }
        .fi-sr-candy-alt:before {
    content: "\f2e8";
    }
        .fi-sr-candy-bar:before {
    content: "\f2e9";
    }
        .fi-sr-candy-cane:before {
    content: "\f2ea";
    }
        .fi-sr-candy-corn:before {
    content: "\f2eb";
    }
        .fi-sr-candy-sweet:before {
    content: "\f2ec";
    }
        .fi-sr-candy:before {
    content: "\f2ed";
    }
        .fi-sr-Cannabis:before {
    content: "\f2ee";
    }
        .fi-sr-canned-food:before {
    content: "\f2ef";
    }
        .fi-sr-capsules:before {
    content: "\f2f0";
    }
        .fi-sr-car-alt:before {
    content: "\f2f1";
    }
        .fi-sr-car-battery:before {
    content: "\f2f2";
    }
        .fi-sr-car-bolt:before {
    content: "\f2f3";
    }
        .fi-sr-car-building:before {
    content: "\f2f4";
    }
        .fi-sr-car-bump:before {
    content: "\f2f5";
    }
        .fi-sr-car-bus:before {
    content: "\f2f6";
    }
        .fi-sr-car-circle-bolt:before {
    content: "\f2f7";
    }
        .fi-sr-car-crash:before {
    content: "\f2f8";
    }
        .fi-sr-car-garage:before {
    content: "\f2f9";
    }
        .fi-sr-car-journey:before {
    content: "\f2fa";
    }
        .fi-sr-car-mechanic:before {
    content: "\f2fb";
    }
        .fi-sr-car-rear:before {
    content: "\f2fc";
    }
        .fi-sr-car-side-bolt:before {
    content: "\f2fd";
    }
        .fi-sr-car-side:before {
    content: "\f2fe";
    }
        .fi-sr-car-tilt:before {
    content: "\f2ff";
    }
        .fi-sr-car-wash:before {
    content: "\f300";
    }
        .fi-sr-car:before {
    content: "\f301";
    }
        .fi-sr-caravan-alt:before {
    content: "\f302";
    }
        .fi-sr-caravan:before {
    content: "\f303";
    }
        .fi-sr-card-club:before {
    content: "\f304";
    }
        .fi-sr-card-diamond:before {
    content: "\f305";
    }
        .fi-sr-card-heart:before {
    content: "\f306";
    }
        .fi-sr-card-spade:before {
    content: "\f307";
    }
        .fi-sr-caret-circle-down:before {
    content: "\f308";
    }
        .fi-sr-caret-circle-right:before {
    content: "\f309";
    }
        .fi-sr-caret-circle-up:before {
    content: "\f30a";
    }
        .fi-sr-caret-down:before {
    content: "\f30b";
    }
        .fi-sr-caret-left:before {
    content: "\f30c";
    }
        .fi-sr-caret-quare-up:before {
    content: "\f30d";
    }
        .fi-sr-caret-right:before {
    content: "\f30e";
    }
        .fi-sr-caret-square-down:before {
    content: "\f30f";
    }
        .fi-sr-caret-square-left_1:before {
    content: "\f310";
    }
        .fi-sr-caret-square-left:before {
    content: "\f311";
    }
        .fi-sr-caret-square-right:before {
    content: "\f312";
    }
        .fi-sr-caret-up:before {
    content: "\f313";
    }
        .fi-sr-carrot:before {
    content: "\f314";
    }
        .fi-sr-cars-crash:before {
    content: "\f315";
    }
        .fi-sr-cars:before {
    content: "\f316";
    }
        .fi-sr-cart-arrow-down:before {
    content: "\f317";
    }
        .fi-sr-cart-minus:before {
    content: "\f318";
    }
        .fi-sr-cart-shopping-fast:before {
    content: "\f319";
    }
        .fi-sr-cash-register:before {
    content: "\f31a";
    }
        .fi-sr-cassette-tape:before {
    content: "\f31b";
    }
        .fi-sr-cassette-vhs:before {
    content: "\f31c";
    }
        .fi-sr-castle:before {
    content: "\f31d";
    }
        .fi-sr-cat-space:before {
    content: "\f31e";
    }
        .fi-sr-cat:before {
    content: "\f31f";
    }
        .fi-sr-catalog-alt:before {
    content: "\f320";
    }
        .fi-sr-catalog-magazine:before {
    content: "\f321";
    }
        .fi-sr-catalog:before {
    content: "\f322";
    }
        .fi-sr-category-alt:before {
    content: "\f323";
    }
        .fi-sr-category:before {
    content: "\f324";
    }
        .fi-sr-cauldron:before {
    content: "\f325";
    }
        .fi-sr-cedi-sign:before {
    content: "\f326";
    }
        .fi-sr-cello:before {
    content: "\f327";
    }
        .fi-sr-cent-sign:before {
    content: "\f328";
    }
        .fi-sr-chair-office:before {
    content: "\f329";
    }
        .fi-sr-chair:before {
    content: "\f32a";
    }
        .fi-sr-chalkboard-user:before {
    content: "\f32b";
    }
        .fi-sr-challenge-alt:before {
    content: "\f32c";
    }
        .fi-sr-challenge:before {
    content: "\f32d";
    }
        .fi-sr-charging-station:before {
    content: "\f32e";
    }
        .fi-sr-chart-area:before {
    content: "\f32f";
    }
        .fi-sr-chart-bullet:before {
    content: "\f330";
    }
        .fi-sr-chart-candlestick:before {
    content: "\f331";
    }
        .fi-sr-chart-connected:before {
    content: "\f332";
    }
        .fi-sr-chart-gantt:before {
    content: "\f333";
    }
        .fi-sr-chart-histogram:before {
    content: "\f334";
    }
        .fi-sr-chart-line-up:before {
    content: "\f335";
    }
        .fi-sr-chart-mixed:before {
    content: "\f336";
    }
        .fi-sr-chart-network:before {
    content: "\f337";
    }
        .fi-sr-chart-pie-alt:before {
    content: "\f338";
    }
        .fi-sr-chart-pie:before {
    content: "\f339";
    }
        .fi-sr-chart-pyramid:before {
    content: "\f33a";
    }
        .fi-sr-chart-radar:before {
    content: "\f33b";
    }
        .fi-sr-chart-scatter-3d:before {
    content: "\f33c";
    }
        .fi-sr-chart-scatter-bubble:before {
    content: "\f33d";
    }
        .fi-sr-chart-scatter:before {
    content: "\f33e";
    }
        .fi-sr-chart-set-theory:before {
    content: "\f33f";
    }
        .fi-sr-chart-simple-horizontal:before {
    content: "\f340";
    }
        .fi-sr-chart-simple:before {
    content: "\f341";
    }
        .fi-sr-chart-tree-map:before {
    content: "\f342";
    }
        .fi-sr-chart-tree:before {
    content: "\f343";
    }
        .fi-sr-chart-user:before {
    content: "\f344";
    }
        .fi-sr-chart-waterfall:before {
    content: "\f345";
    }
        .fi-sr-chat-arrow-down:before {
    content: "\f346";
    }
        .fi-sr-chat-arrow-grow:before {
    content: "\f347";
    }
        .fi-sr-chatbot-speech-bubble:before {
    content: "\f348";
    }
        .fi-sr-chatbot:before {
    content: "\f349";
    }
        .fi-sr-check-circle:before {
    content: "\f34a";
    }
        .fi-sr-check-double:before {
    content: "\f34b";
    }
        .fi-sr-check:before {
    content: "\f34c";
    }
        .fi-sr-checkbox:before {
    content: "\f34d";
    }
        .fi-sr-cheese-alt:before {
    content: "\f34e";
    }
        .fi-sr-cheese:before {
    content: "\f34f";
    }
        .fi-sr-cheeseburger:before {
    content: "\f350";
    }
        .fi-sr-cherry:before {
    content: "\f351";
    }
        .fi-sr-chess-bishop:before {
    content: "\f352";
    }
        .fi-sr-chess-board:before {
    content: "\f353";
    }
        .fi-sr-chess-clock-alt:before {
    content: "\f354";
    }
        .fi-sr-chess-clock:before {
    content: "\f355";
    }
        .fi-sr-chess-king-alt:before {
    content: "\f356";
    }
        .fi-sr-chess-king:before {
    content: "\f357";
    }
        .fi-sr-chess-knight-alt:before {
    content: "\f358";
    }
        .fi-sr-chess-knight:before {
    content: "\f359";
    }
        .fi-sr-chess-pawn-alt:before {
    content: "\f35a";
    }
        .fi-sr-chess-pawn:before {
    content: "\f35b";
    }
        .fi-sr-chess-piece:before {
    content: "\f35c";
    }
        .fi-sr-chess-queen-alt:before {
    content: "\f35d";
    }
        .fi-sr-chess-queen:before {
    content: "\f35e";
    }
        .fi-sr-chess-rook-alt:before {
    content: "\f35f";
    }
        .fi-sr-chess-rook:before {
    content: "\f360";
    }
        .fi-sr-chess:before {
    content: "\f361";
    }
        .fi-sr-chevron-double-down:before {
    content: "\f362";
    }
        .fi-sr-chevron-double-up:before {
    content: "\f363";
    }
        .fi-sr-child-head:before {
    content: "\f364";
    }
        .fi-sr-child:before {
    content: "\f365";
    }
        .fi-sr-chimney:before {
    content: "\f366";
    }
        .fi-sr-chocolate:before {
    content: "\f367";
    }
        .fi-sr-choose-alt:before {
    content: "\f368";
    }
        .fi-sr-choose:before {
    content: "\f369";
    }
        .fi-sr-circle-0:before {
    content: "\f36a";
    }
        .fi-sr-circle-1:before {
    content: "\f36b";
    }
        .fi-sr-circle-2:before {
    content: "\f36c";
    }
        .fi-sr-circle-3:before {
    content: "\f36d";
    }
        .fi-sr-circle-4:before {
    content: "\f36e";
    }
        .fi-sr-circle-5:before {
    content: "\f36f";
    }
        .fi-sr-circle-6:before {
    content: "\f370";
    }
        .fi-sr-circle-7:before {
    content: "\f371";
    }
        .fi-sr-circle-8:before {
    content: "\f372";
    }
        .fi-sr-circle-9:before {
    content: "\f373";
    }
        .fi-sr-circle-a:before {
    content: "\f374";
    }
        .fi-sr-circle-b:before {
    content: "\f375";
    }
        .fi-sr-circle-bolt:before {
    content: "\f376";
    }
        .fi-sr-circle-book-open:before {
    content: "\f377";
    }
        .fi-sr-circle-bookmark:before {
    content: "\f378";
    }
        .fi-sr-circle-c:before {
    content: "\f379";
    }
        .fi-sr-circle-calendar:before {
    content: "\f37a";
    }
        .fi-sr-circle-camera:before {
    content: "\f37b";
    }
        .fi-sr-circle-d:before {
    content: "\f37c";
    }
        .fi-sr-circle-dashed:before {
    content: "\f37d";
    }
        .fi-sr-circle-e:before {
    content: "\f37e";
    }
        .fi-sr-circle-ellipsis-vertical:before {
    content: "\f37f";
    }
        .fi-sr-circle-ellipsis:before {
    content: "\f380";
    }
        .fi-sr-circle-envelope:before {
    content: "\f381";
    }
        .fi-sr-circle-exclamation-check:before {
    content: "\f382";
    }
        .fi-sr-circle-f:before {
    content: "\f383";
    }
        .fi-sr-circle-g:before {
    content: "\f384";
    }
        .fi-sr-circle-h:before {
    content: "\f385";
    }
        .fi-sr-circle-half-stroke:before {
    content: "\f386";
    }
        .fi-sr-circle-half:before {
    content: "\f387";
    }
        .fi-sr-circle-heart:before {
    content: "\f388";
    }
        .fi-sr-circle-i:before {
    content: "\f389";
    }
        .fi-sr-circle-j:before {
    content: "\f38a";
    }
        .fi-sr-circle-k:before {
    content: "\f38b";
    }
        .fi-sr-circle-l:before {
    content: "\f38c";
    }
        .fi-sr-circle-m:before {
    content: "\f38d";
    }
        .fi-sr-circle-microphone-lines:before {
    content: "\f38e";
    }
        .fi-sr-circle-microphone:before {
    content: "\f38f";
    }
        .fi-sr-circle-n:before {
    content: "\f390";
    }
        .fi-sr-circle-o:before {
    content: "\f391";
    }
        .fi-sr-circle-p:before {
    content: "\f392";
    }
        .fi-sr-circle-phone-flip:before {
    content: "\f393";
    }
        .fi-sr-circle-phone-hangup:before {
    content: "\f394";
    }
        .fi-sr-circle-phone:before {
    content: "\f395";
    }
        .fi-sr-circle-q:before {
    content: "\f396";
    }
        .fi-sr-circle-quarter:before {
    content: "\f397";
    }
        .fi-sr-circle-quarters-alt:before {
    content: "\f398";
    }
        .fi-sr-circle-r:before {
    content: "\f399";
    }
        .fi-sr-circle-s:before {
    content: "\f39a";
    }
        .fi-sr-circle-small:before {
    content: "\f39b";
    }
        .fi-sr-circle-star:before {
    content: "\f39c";
    }
        .fi-sr-circle-t:before {
    content: "\f39d";
    }
        .fi-sr-circle-three-quarters:before {
    content: "\f39e";
    }
        .fi-sr-circle-trash:before {
    content: "\f39f";
    }
        .fi-sr-circle-u:before {
    content: "\f3a0";
    }
        .fi-sr-circle-user:before {
    content: "\f3a1";
    }
        .fi-sr-circle-v:before {
    content: "\f3a2";
    }
        .fi-sr-circle-video:before {
    content: "\f3a3";
    }
        .fi-sr-circle-w:before {
    content: "\f3a4";
    }
        .fi-sr-circle-waveform-lines:before {
    content: "\f3a5";
    }
        .fi-sr-circle-x:before {
    content: "\f3a6";
    }
        .fi-sr-circle-xmark:before {
    content: "\f3a7";
    }
        .fi-sr-circle-y:before {
    content: "\f3a8";
    }
        .fi-sr-circle-z:before {
    content: "\f3a9";
    }
        .fi-sr-circle:before {
    content: "\f3aa";
    }
        .fi-sr-citrus-slice:before {
    content: "\f3ab";
    }
        .fi-sr-citrus:before {
    content: "\f3ac";
    }
        .fi-sr-city:before {
    content: "\f3ad";
    }
        .fi-sr-clapperboard-play:before {
    content: "\f3ae";
    }
        .fi-sr-clapperboard:before {
    content: "\f3af";
    }
        .fi-sr-clarinet:before {
    content: "\f3b0";
    }
        .fi-sr-claw-marks:before {
    content: "\f3b1";
    }
        .fi-sr-clear-alt:before {
    content: "\f3b2";
    }
        .fi-sr-clip:before {
    content: "\f3b3";
    }
        .fi-sr-clipboard-check:before {
    content: "\f3b4";
    }
        .fi-sr-clipboard-list-check:before {
    content: "\f3b5";
    }
        .fi-sr-clipboard-list:before {
    content: "\f3b6";
    }
        .fi-sr-clipboard-prescription:before {
    content: "\f3b7";
    }
        .fi-sr-clipboard-user:before {
    content: "\f3b8";
    }
        .fi-sr-clipboard:before {
    content: "\f3b9";
    }
        .fi-sr-clock-desk:before {
    content: "\f3ba";
    }
        .fi-sr-clock-eight-thirty:before {
    content: "\f3bb";
    }
        .fi-sr-clock-eleven-thirty:before {
    content: "\f3bc";
    }
        .fi-sr-clock-eleven:before {
    content: "\f3bd";
    }
        .fi-sr-clock-five-thirty:before {
    content: "\f3be";
    }
        .fi-sr-clock-five:before {
    content: "\f3bf";
    }
        .fi-sr-clock-four-thirty:before {
    content: "\f3c0";
    }
        .fi-sr-clock-nine-thirty:before {
    content: "\f3c1";
    }
        .fi-sr-clock-nine:before {
    content: "\f3c2";
    }
        .fi-sr-clock-one-thirty:before {
    content: "\f3c3";
    }
        .fi-sr-clock-one:before {
    content: "\f3c4";
    }
        .fi-sr-clock-seven-thirty:before {
    content: "\f3c5";
    }
        .fi-sr-clock-seven:before {
    content: "\f3c6";
    }
        .fi-sr-clock-six-thirty:before {
    content: "\f3c7";
    }
        .fi-sr-clock-six:before {
    content: "\f3c8";
    }
        .fi-sr-clock-ten-thirty:before {
    content: "\f3c9";
    }
        .fi-sr-clock-ten:before {
    content: "\f3ca";
    }
        .fi-sr-clock-three-thirty:before {
    content: "\f3cb";
    }
        .fi-sr-clock-three:before {
    content: "\f3cc";
    }
        .fi-sr-clock-twelve-thirty:before {
    content: "\f3cd";
    }
        .fi-sr-clock-twelve:before {
    content: "\f3ce";
    }
        .fi-sr-clock-two-thirty:before {
    content: "\f3cf";
    }
        .fi-sr-clock-two:before {
    content: "\f3d0";
    }
        .fi-sr-clock:before {
    content: "\f3d1";
    }
        .fi-sr-clone:before {
    content: "\f3d2";
    }
        .fi-sr-closed-captioning-slash:before {
    content: "\f3d3";
    }
        .fi-sr-clothes-hanger:before {
    content: "\f3d4";
    }
        .fi-sr-cloud-check:before {
    content: "\f3d5";
    }
        .fi-sr-cloud-code:before {
    content: "\f3d6";
    }
        .fi-sr-cloud-disabled:before {
    content: "\f3d7";
    }
        .fi-sr-cloud-download-alt:before {
    content: "\f3d8";
    }
        .fi-sr-cloud-download:before {
    content: "\f3d9";
    }
        .fi-sr-cloud-drizzle:before {
    content: "\f3da";
    }
        .fi-sr-cloud-exclamation:before {
    content: "\f3db";
    }
        .fi-sr-cloud-hail-mixed:before {
    content: "\f3dc";
    }
        .fi-sr-cloud-hail:before {
    content: "\f3dd";
    }
        .fi-sr-cloud-meatball:before {
    content: "\f3de";
    }
        .fi-sr-cloud-moon-rain:before {
    content: "\f3df";
    }
        .fi-sr-cloud-moon:before {
    content: "\f3e0";
    }
        .fi-sr-cloud-question:before {
    content: "\f3e1";
    }
        .fi-sr-cloud-rain:before {
    content: "\f3e2";
    }
        .fi-sr-cloud-rainbow:before {
    content: "\f3e3";
    }
        .fi-sr-cloud-share:before {
    content: "\f3e4";
    }
        .fi-sr-cloud-showers-heavy:before {
    content: "\f3e5";
    }
        .fi-sr-cloud-showers:before {
    content: "\f3e6";
    }
        .fi-sr-cloud-sleet:before {
    content: "\f3e7";
    }
        .fi-sr-cloud-snow:before {
    content: "\f3e8";
    }
        .fi-sr-cloud-sun-rain:before {
    content: "\f3e9";
    }
        .fi-sr-cloud-sun:before {
    content: "\f3ea";
    }
        .fi-sr-cloud-upload-alt:before {
    content: "\f3eb";
    }
        .fi-sr-cloud-upload:before {
    content: "\f3ec";
    }
        .fi-sr-cloud:before {
    content: "\f3ed";
    }
        .fi-sr-clouds-moon:before {
    content: "\f3ee";
    }
        .fi-sr-clouds-sun:before {
    content: "\f3ef";
    }
        .fi-sr-clouds:before {
    content: "\f3f0";
    }
        .fi-sr-clover-alt:before {
    content: "\f3f1";
    }
        .fi-sr-club:before {
    content: "\f3f2";
    }
        .fi-sr-cocktail-alt:before {
    content: "\f3f3";
    }
        .fi-sr-cocktail:before {
    content: "\f3f4";
    }
        .fi-sr-coconut:before {
    content: "\f3f5";
    }
        .fi-sr-code-branch:before {
    content: "\f3f6";
    }
        .fi-sr-code-commit:before {
    content: "\f3f7";
    }
        .fi-sr-code-compare:before {
    content: "\f3f8";
    }
        .fi-sr-code-fork:before {
    content: "\f3f9";
    }
        .fi-sr-code-merge:before {
    content: "\f3fa";
    }
        .fi-sr-code-pull-request-closed:before {
    content: "\f3fb";
    }
        .fi-sr-code-pull-request-draft:before {
    content: "\f3fc";
    }
        .fi-sr-code-pull-request:before {
    content: "\f3fd";
    }
        .fi-sr-code-simple:before {
    content: "\f3fe";
    }
        .fi-sr-coffee-bean:before {
    content: "\f3ff";
    }
        .fi-sr-coffee-beans:before {
    content: "\f400";
    }
        .fi-sr-coffee-pot:before {
    content: "\f401";
    }
        .fi-sr-coffee:before {
    content: "\f402";
    }
        .fi-sr-coffin-cross:before {
    content: "\f403";
    }
        .fi-sr-coffin:before {
    content: "\f404";
    }
        .fi-sr-coin-up-arrow:before {
    content: "\f405";
    }
        .fi-sr-coin:before {
    content: "\f406";
    }
        .fi-sr-coins:before {
    content: "\f407";
    }
        .fi-sr-colon-sign:before {
    content: "\f408";
    }
        .fi-sr-columns-3:before {
    content: "\f409";
    }
        .fi-sr-comet:before {
    content: "\f40a";
    }
        .fi-sr-command:before {
    content: "\f40b";
    }
        .fi-sr-comment-alt-check:before {
    content: "\f40c";
    }
        .fi-sr-comment-alt-dots:before {
    content: "\f40d";
    }
        .fi-sr-comment-alt-edit:before {
    content: "\f40e";
    }
        .fi-sr-comment-alt-medical:before {
    content: "\f40f";
    }
        .fi-sr-comment-alt-middle-top:before {
    content: "\f410";
    }
        .fi-sr-comment-alt-middle:before {
    content: "\f411";
    }
        .fi-sr-comment-alt-minus:before {
    content: "\f412";
    }
        .fi-sr-comment-alt-music:before {
    content: "\f413";
    }
        .fi-sr-comment-alt:before {
    content: "\f414";
    }
        .fi-sr-comment-arrow-down:before {
    content: "\f415";
    }
        .fi-sr-comment-arrow-up-right:before {
    content: "\f416";
    }
        .fi-sr-comment-arrow-up:before {
    content: "\f417";
    }
        .fi-sr-comment-check:before {
    content: "\f418";
    }
        .fi-sr-comment-code:before {
    content: "\f419";
    }
        .fi-sr-comment-dollar:before {
    content: "\f41a";
    }
        .fi-sr-comment-dots:before {
    content: "\f41b";
    }
        .fi-sr-comment-exclamation:before {
    content: "\f41c";
    }
        .fi-sr-comment-heart:before {
    content: "\f41d";
    }
        .fi-sr-comment-image:before {
    content: "\f41e";
    }
        .fi-sr-comment-info:before {
    content: "\f41f";
    }
        .fi-sr-comment-medical:before {
    content: "\f420";
    }
        .fi-sr-comment-minus:before {
    content: "\f421";
    }
        .fi-sr-comment-pen:before {
    content: "\f422";
    }
        .fi-sr-comment-question:before {
    content: "\f423";
    }
        .fi-sr-comment-quote:before {
    content: "\f424";
    }
        .fi-sr-comment-slash:before {
    content: "\f425";
    }
        .fi-sr-comment-smile:before {
    content: "\f426";
    }
        .fi-sr-comment-sms:before {
    content: "\f427";
    }
        .fi-sr-comment-text:before {
    content: "\f428";
    }
        .fi-sr-comment-user:before {
    content: "\f429";
    }
        .fi-sr-comment-xmark:before {
    content: "\f42a";
    }
        .fi-sr-comment:before {
    content: "\f42b";
    }
        .fi-sr-comments-dollar:before {
    content: "\f42c";
    }
        .fi-sr-comments-question-check:before {
    content: "\f42d";
    }
        .fi-sr-comments-question:before {
    content: "\f42e";
    }
        .fi-sr-comments:before {
    content: "\f42f";
    }
        .fi-sr-compass-slash:before {
    content: "\f430";
    }
        .fi-sr-completed:before {
    content: "\f431";
    }
        .fi-sr-compliance-clipboard:before {
    content: "\f432";
    }
        .fi-sr-compliance-document:before {
    content: "\f433";
    }
        .fi-sr-compliance:before {
    content: "\f434";
    }
        .fi-sr-compress-alt:before {
    content: "\f435";
    }
        .fi-sr-compress:before {
    content: "\f436";
    }
        .fi-sr-computer-classic:before {
    content: "\f437";
    }
        .fi-sr-computer-mouse:before {
    content: "\f438";
    }
        .fi-sr-computer-speaker:before {
    content: "\f439";
    }
        .fi-sr-computer:before {
    content: "\f43a";
    }
        .fi-sr-concierge-bell:before {
    content: "\f43b";
    }
        .fi-sr-condition-alt:before {
    content: "\f43c";
    }
        .fi-sr-condition:before {
    content: "\f43d";
    }
        .fi-sr-confetti:before {
    content: "\f43e";
    }
        .fi-sr-constellation:before {
    content: "\f43f";
    }
        .fi-sr-container-storage:before {
    content: "\f440";
    }
        .fi-sr-conveyor-belt-alt:before {
    content: "\f441";
    }
        .fi-sr-conveyor-belt-empty:before {
    content: "\f442";
    }
        .fi-sr-conveyor-belt:before {
    content: "\f443";
    }
        .fi-sr-cookie-alt:before {
    content: "\f444";
    }
        .fi-sr-cookie:before {
    content: "\f445";
    }
        .fi-sr-copy-alt:before {
    content: "\f446";
    }
        .fi-sr-copy-image:before {
    content: "\f447";
    }
        .fi-sr-copy:before {
    content: "\f448";
    }
        .fi-sr-copyright:before {
    content: "\f449";
    }
        .fi-sr-corn:before {
    content: "\f44a";
    }
        .fi-sr-couch:before {
    content: "\f44b";
    }
        .fi-sr-cow-alt:before {
    content: "\f44c";
    }
        .fi-sr-cow:before {
    content: "\f44d";
    }
        .fi-sr-cowbell-circle-plus:before {
    content: "\f44e";
    }
        .fi-sr-cowbell-more:before {
    content: "\f44f";
    }
        .fi-sr-cowbell:before {
    content: "\f450";
    }
        .fi-sr-crab:before {
    content: "\f451";
    }
        .fi-sr-crate-empty:before {
    content: "\f452";
    }
        .fi-sr-cream:before {
    content: "\f453";
    }
        .fi-sr-credit-card:before {
    content: "\f454";
    }
        .fi-sr-cricket:before {
    content: "\f455";
    }
        .fi-sr-croissant:before {
    content: "\f456";
    }
        .fi-sr-cross-circle:before {
    content: "\f457";
    }
        .fi-sr-cross-religion:before {
    content: "\f458";
    }
        .fi-sr-cross-small:before {
    content: "\f459";
    }
        .fi-sr-cross:before {
    content: "\f45a";
    }
        .fi-sr-crow:before {
    content: "\f45b";
    }
        .fi-sr-crown:before {
    content: "\f45c";
    }
        .fi-sr-crutch:before {
    content: "\f45d";
    }
        .fi-sr-crutches:before {
    content: "\f45e";
    }
        .fi-sr-cruzeiro-sign:before {
    content: "\f45f";
    }
        .fi-sr-crystal-ball:before {
    content: "\f460";
    }
        .fi-sr-cube:before {
    content: "\f461";
    }
        .fi-sr-cubes-stacked:before {
    content: "\f462";
    }
        .fi-sr-cubes:before {
    content: "\f463";
    }
        .fi-sr-cucumber:before {
    content: "\f464";
    }
        .fi-sr-cup-straw-swoosh:before {
    content: "\f465";
    }
        .fi-sr-cup-straw:before {
    content: "\f466";
    }
        .fi-sr-cup-togo:before {
    content: "\f467";
    }
        .fi-sr-cupcake-alt:before {
    content: "\f468";
    }
        .fi-sr-cupcake:before {
    content: "\f469";
    }
        .fi-sr-curling:before {
    content: "\f46a";
    }
        .fi-sr-cursor-finger:before {
    content: "\f46b";
    }
        .fi-sr-cursor-plus:before {
    content: "\f46c";
    }
        .fi-sr-cursor-text-alt:before {
    content: "\f46d";
    }
        .fi-sr-cursor-text:before {
    content: "\f46e";
    }
        .fi-sr-cursor:before {
    content: "\f46f";
    }
        .fi-sr-custard:before {
    content: "\f470";
    }
        .fi-sr-customer-care:before {
    content: "\f471";
    }
        .fi-sr-customer-service:before {
    content: "\f472";
    }
        .fi-sr-d:before {
    content: "\f473";
    }
        .fi-sr-dagger:before {
    content: "\f474";
    }
        .fi-sr-dart:before {
    content: "\f475";
    }
        .fi-sr-dashboard:before {
    content: "\f476";
    }
        .fi-sr-data-transfer:before {
    content: "\f477";
    }
        .fi-sr-database:before {
    content: "\f478";
    }
        .fi-sr-deer-rudolph:before {
    content: "\f479";
    }
        .fi-sr-deer:before {
    content: "\f47a";
    }
        .fi-sr-delete-document:before {
    content: "\f47b";
    }
        .fi-sr-delete-right:before {
    content: "\f47c";
    }
        .fi-sr-delete-user:before {
    content: "\f47d";
    }
        .fi-sr-delete:before {
    content: "\f47e";
    }
        .fi-sr-democrat:before {
    content: "\f47f";
    }
        .fi-sr-deposit-alt:before {
    content: "\f480";
    }
        .fi-sr-deposit:before {
    content: "\f481";
    }
        .fi-sr-description-alt:before {
    content: "\f482";
    }
        .fi-sr-description:before {
    content: "\f483";
    }
        .fi-sr-desktop-arrow-down:before {
    content: "\f484";
    }
        .fi-sr-desktop-wallpaper:before {
    content: "\f485";
    }
        .fi-sr-devices:before {
    content: "\f486";
    }
        .fi-sr-dewpoint:before {
    content: "\f487";
    }
        .fi-sr-dharmachakra:before {
    content: "\f488";
    }
        .fi-sr-diagram-cells:before {
    content: "\f489";
    }
        .fi-sr-diagram-lean-canvas:before {
    content: "\f48a";
    }
        .fi-sr-diagram-nested:before {
    content: "\f48b";
    }
        .fi-sr-diagram-next:before {
    content: "\f48c";
    }
        .fi-sr-diagram-predecessor:before {
    content: "\f48d";
    }
        .fi-sr-diagram-previous:before {
    content: "\f48e";
    }
        .fi-sr-diagram-project:before {
    content: "\f48f";
    }
        .fi-sr-diagram-sankey:before {
    content: "\f490";
    }
        .fi-sr-diagram-subtask:before {
    content: "\f491";
    }
        .fi-sr-diagram-successor:before {
    content: "\f492";
    }
        .fi-sr-diagram-venn:before {
    content: "\f493";
    }
        .fi-sr-dial-high:before {
    content: "\f494";
    }
        .fi-sr-dial-low:before {
    content: "\f495";
    }
        .fi-sr-dial-max:before {
    content: "\f496";
    }
        .fi-sr-dial-med-low:before {
    content: "\f497";
    }
        .fi-sr-dial-med:before {
    content: "\f498";
    }
        .fi-sr-dial-min:before {
    content: "\f499";
    }
        .fi-sr-dial-off:before {
    content: "\f49a";
    }
        .fi-sr-dial:before {
    content: "\f49b";
    }
        .fi-sr-diamond-exclamation:before {
    content: "\f49c";
    }
        .fi-sr-diamond-turn-right:before {
    content: "\f49d";
    }
        .fi-sr-diamond:before {
    content: "\f49e";
    }
        .fi-sr-diary-bookmark-down:before {
    content: "\f49f";
    }
        .fi-sr-diary-bookmarks:before {
    content: "\f4a0";
    }
        .fi-sr-diary-clasp:before {
    content: "\f4a1";
    }
        .fi-sr-dice-alt:before {
    content: "\f4a2";
    }
        .fi-sr-dice-d10:before {
    content: "\f4a3";
    }
        .fi-sr-dice-d12:before {
    content: "\f4a4";
    }
        .fi-sr-dice-d20:before {
    content: "\f4a5";
    }
        .fi-sr-dice-d4:before {
    content: "\f4a6";
    }
        .fi-sr-dice-d6:before {
    content: "\f4a7";
    }
        .fi-sr-dice-d8:before {
    content: "\f4a8";
    }
        .fi-sr-dice-four:before {
    content: "\f4a9";
    }
        .fi-sr-dice-one:before {
    content: "\f4aa";
    }
        .fi-sr-dice-six:before {
    content: "\f4ab";
    }
        .fi-sr-dice-three:before {
    content: "\f4ac";
    }
        .fi-sr-dice-two:before {
    content: "\f4ad";
    }
        .fi-sr-dice:before {
    content: "\f4ae";
    }
        .fi-sr-digging:before {
    content: "\f4af";
    }
        .fi-sr-digital-tachograph:before {
    content: "\f4b0";
    }
        .fi-sr-diploma:before {
    content: "\f4b1";
    }
        .fi-sr-direction-signal-arrow:before {
    content: "\f4b2";
    }
        .fi-sr-direction-signal:before {
    content: "\f4b3";
    }
        .fi-sr-disc-drive:before {
    content: "\f4b4";
    }
        .fi-sr-discover:before {
    content: "\f4b5";
    }
        .fi-sr-disease:before {
    content: "\f4b6";
    }
        .fi-sr-disk:before {
    content: "\f4b7";
    }
        .fi-sr-display-arrow-down:before {
    content: "\f4b8";
    }
        .fi-sr-display-code:before {
    content: "\f4b9";
    }
        .fi-sr-display-medical:before {
    content: "\f4ba";
    }
        .fi-sr-display-slash:before {
    content: "\f4bb";
    }
        .fi-sr-distribute-spacing-horizontal:before {
    content: "\f4bc";
    }
        .fi-sr-distribute-spacing-vertical:before {
    content: "\f4bd";
    }
        .fi-sr-dizzy:before {
    content: "\f4be";
    }
        .fi-sr-dna:before {
    content: "\f4bf";
    }
        .fi-sr-do-not-enter:before {
    content: "\f4c0";
    }
        .fi-sr-doctor:before {
    content: "\f4c1";
    }
        .fi-sr-document-signed:before {
    content: "\f4c2";
    }
        .fi-sr-document:before {
    content: "\f4c3";
    }
        .fi-sr-dog:before {
    content: "\f4c4";
    }
        .fi-sr-dollar:before {
    content: "\f4c5";
    }
        .fi-sr-dolly-flatbed-alt:before {
    content: "\f4c6";
    }
        .fi-sr-dolly-flatbed-empty:before {
    content: "\f4c7";
    }
        .fi-sr-dolly-flatbed:before {
    content: "\f4c8";
    }
        .fi-sr-dolphin:before {
    content: "\f4c9";
    }
        .fi-sr-donate:before {
    content: "\f4ca";
    }
        .fi-sr-dong-sign:before {
    content: "\f4cb";
    }
        .fi-sr-donut:before {
    content: "\f4cc";
    }
        .fi-sr-door-closed:before {
    content: "\f4cd";
    }
        .fi-sr-door-open:before {
    content: "\f4ce";
    }
        .fi-sr-dot-circle:before {
    content: "\f4cf";
    }
        .fi-sr-dove:before {
    content: "\f4d0";
    }
        .fi-sr-down-left-and-up-right-to-center:before {
    content: "\f4d1";
    }
        .fi-sr-down-left:before {
    content: "\f4d2";
    }
        .fi-sr-down-right:before {
    content: "\f4d3";
    }
        .fi-sr-down-to-line:before {
    content: "\f4d4";
    }
        .fi-sr-down:before {
    content: "\f4d5";
    }
        .fi-sr-download:before {
    content: "\f4d6";
    }
        .fi-sr-drafting-compass:before {
    content: "\f4d7";
    }
        .fi-sr-dragon:before {
    content: "\f4d8";
    }
        .fi-sr-draw-polygon:before {
    content: "\f4d9";
    }
        .fi-sr-draw-square:before {
    content: "\f4da";
    }
        .fi-sr-dreidel:before {
    content: "\f4db";
    }
        .fi-sr-drink-alt:before {
    content: "\f4dc";
    }
        .fi-sr-driver-man:before {
    content: "\f4dd";
    }
        .fi-sr-driver-woman:before {
    content: "\f4de";
    }
        .fi-sr-drone-alt:before {
    content: "\f4df";
    }
        .fi-sr-drone-front:before {
    content: "\f4e0";
    }
        .fi-sr-drone:before {
    content: "\f4e1";
    }
        .fi-sr-drop-down:before {
    content: "\f4e2";
    }
        .fi-sr-drum-steelpan:before {
    content: "\f4e3";
    }
        .fi-sr-drum:before {
    content: "\f4e4";
    }
        .fi-sr-drumstick-bite:before {
    content: "\f4e5";
    }
        .fi-sr-drumstick:before {
    content: "\f4e6";
    }
        .fi-sr-dryer-alt:before {
    content: "\f4e7";
    }
        .fi-sr-dryer:before {
    content: "\f4e8";
    }
        .fi-sr-duck:before {
    content: "\f4e9";
    }
        .fi-sr-dumpster-fire:before {
    content: "\f4ea";
    }
        .fi-sr-dumpster:before {
    content: "\f4eb";
    }
        .fi-sr-dungeon:before {
    content: "\f4ec";
    }
        .fi-sr-duplicate:before {
    content: "\f4ed";
    }
        .fi-sr-e-learning:before {
    content: "\f4ee";
    }
        .fi-sr-e:before {
    content: "\f4ef";
    }
        .fi-sr-ear-deaf:before {
    content: "\f4f0";
    }
        .fi-sr-ear-muffs:before {
    content: "\f4f1";
    }
        .fi-sr-ear:before {
    content: "\f4f2";
    }
        .fi-sr-earnings:before {
    content: "\f4f3";
    }
        .fi-sr-earth-africa:before {
    content: "\f4f4";
    }
        .fi-sr-earth-americas:before {
    content: "\f4f5";
    }
        .fi-sr-earth-asia:before {
    content: "\f4f6";
    }
        .fi-sr-earth-europa:before {
    content: "\f4f7";
    }
        .fi-sr-eclipse-alt:before {
    content: "\f4f8";
    }
        .fi-sr-eclipse:before {
    content: "\f4f9";
    }
        .fi-sr-edit-alt:before {
    content: "\f4fa";
    }
        .fi-sr-edit:before {
    content: "\f4fb";
    }
        .fi-sr-egg-fried:before {
    content: "\f4fc";
    }
        .fi-sr-egg:before {
    content: "\f4fd";
    }
        .fi-sr-eject:before {
    content: "\f4fe";
    }
        .fi-sr-elephant:before {
    content: "\f4ff";
    }
        .fi-sr-elevator:before {
    content: "\f500";
    }
        .fi-sr-employee-alt:before {
    content: "\f501";
    }
        .fi-sr-employee-man-alt:before {
    content: "\f502";
    }
        .fi-sr-employee-man:before {
    content: "\f503";
    }
        .fi-sr-engine-warning:before {
    content: "\f504";
    }
        .fi-sr-engine:before {
    content: "\f505";
    }
        .fi-sr-enter:before {
    content: "\f506";
    }
        .fi-sr-entertainment:before {
    content: "\f507";
    }
        .fi-sr-envelope-ban:before {
    content: "\f508";
    }
        .fi-sr-envelope-bulk:before {
    content: "\f509";
    }
        .fi-sr-envelope-dot:before {
    content: "\f50a";
    }
        .fi-sr-envelope-download:before {
    content: "\f50b";
    }
        .fi-sr-envelope-marker:before {
    content: "\f50c";
    }
        .fi-sr-envelope-open-dollar:before {
    content: "\f50d";
    }
        .fi-sr-envelope-open-text:before {
    content: "\f50e";
    }
        .fi-sr-envelope-open:before {
    content: "\f50f";
    }
        .fi-sr-envelope-plus:before {
    content: "\f510";
    }
        .fi-sr-envelope:before {
    content: "\f511";
    }
        .fi-sr-envelopes:before {
    content: "\f512";
    }
        .fi-sr-equality:before {
    content: "\f513";
    }
        .fi-sr-equipment:before {
    content: "\f514";
    }
        .fi-sr-eraser:before {
    content: "\f515";
    }
        .fi-sr-escalator:before {
    content: "\f516";
    }
        .fi-sr-ethernet:before {
    content: "\f517";
    }
        .fi-sr-euro:before {
    content: "\f518";
    }
        .fi-sr-exchange-alt:before {
    content: "\f519";
    }
        .fi-sr-exchange:before {
    content: "\f51a";
    }
        .fi-sr-exclamation:before {
    content: "\f51b";
    }
        .fi-sr-exit-alt:before {
    content: "\f51c";
    }
        .fi-sr-exit:before {
    content: "\f51d";
    }
        .fi-sr-expand-arrows-alt:before {
    content: "\f51e";
    }
        .fi-sr-expand-arrows:before {
    content: "\f51f";
    }
        .fi-sr-expand:before {
    content: "\f520";
    }
        .fi-sr-external-hard-drive:before {
    content: "\f521";
    }
        .fi-sr-external-world:before {
    content: "\f522";
    }
        .fi-sr-eye-crossed:before {
    content: "\f523";
    }
        .fi-sr-eye-dropper-half:before {
    content: "\f524";
    }
        .fi-sr-eye:before {
    content: "\f525";
    }
        .fi-sr-eyes:before {
    content: "\f526";
    }
        .fi-sr-f:before {
    content: "\f527";
    }
        .fi-sr-face-angry-horns:before {
    content: "\f528";
    }
        .fi-sr-face-anguished:before {
    content: "\f529";
    }
        .fi-sr-face-anxious-sweat:before {
    content: "\f52a";
    }
        .fi-sr-face-astonished:before {
    content: "\f52b";
    }
        .fi-sr-face-awesome:before {
    content: "\f52c";
    }
        .fi-sr-face-beam-hand-over-mouth:before {
    content: "\f52d";
    }
        .fi-sr-face-confounded:before {
    content: "\f52e";
    }
        .fi-sr-face-confused:before {
    content: "\f52f";
    }
        .fi-sr-face-cowboy-hat:before {
    content: "\f530";
    }
        .fi-sr-face-disappointed:before {
    content: "\f531";
    }
        .fi-sr-face-disguise:before {
    content: "\f532";
    }
        .fi-sr-face-downcast-sweat:before {
    content: "\f533";
    }
        .fi-sr-face-drooling:before {
    content: "\f534";
    }
        .fi-sr-face-explode:before {
    content: "\f535";
    }
        .fi-sr-face-expressionless:before {
    content: "\f536";
    }
        .fi-sr-face-eyes-xmarks:before {
    content: "\f537";
    }
        .fi-sr-face-fearful:before {
    content: "\f538";
    }
        .fi-sr-face-glasses:before {
    content: "\f539";
    }
        .fi-sr-face-grin-tongue-wink:before {
    content: "\f53a";
    }
        .fi-sr-face-hand-yawn:before {
    content: "\f53b";
    }
        .fi-sr-face-head-bandage:before {
    content: "\f53c";
    }
        .fi-sr-face-hushed:before {
    content: "\f53d";
    }
        .fi-sr-face-icicles:before {
    content: "\f53e";
    }
        .fi-sr-face-lying:before {
    content: "\f53f";
    }
        .fi-sr-face-mask:before {
    content: "\f540";
    }
        .fi-sr-face-monocle:before {
    content: "\f541";
    }
        .fi-sr-face-nauseated:before {
    content: "\f542";
    }
        .fi-sr-face-nose-steam:before {
    content: "\f543";
    }
        .fi-sr-face-party:before {
    content: "\f544";
    }
        .fi-sr-face-pensive:before {
    content: "\f545";
    }
        .fi-sr-face-persevering:before {
    content: "\f546";
    }
        .fi-sr-face-pleading:before {
    content: "\f547";
    }
        .fi-sr-face-raised-eyebrow:before {
    content: "\f548";
    }
        .fi-sr-face-relieved:before {
    content: "\f549";
    }
        .fi-sr-face-sad-sweat:before {
    content: "\f54a";
    }
        .fi-sr-face-scream:before {
    content: "\f54b";
    }
        .fi-sr-face-shush:before {
    content: "\f54c";
    }
        .fi-sr-face-sleeping:before {
    content: "\f54d";
    }
        .fi-sr-face-sleepy:before {
    content: "\f54e";
    }
        .fi-sr-face-smile-halo:before {
    content: "\f54f";
    }
        .fi-sr-face-smile-hearts:before {
    content: "\f550";
    }
        .fi-sr-face-smile-horns:before {
    content: "\f551";
    }
        .fi-sr-face-smile-tear:before {
    content: "\f552";
    }
        .fi-sr-face-smile-tongue:before {
    content: "\f553";
    }
        .fi-sr-face-smile-upside-down:before {
    content: "\f554";
    }
        .fi-sr-face-smiling-hands:before {
    content: "\f555";
    }
        .fi-sr-face-smirking:before {
    content: "\f556";
    }
        .fi-sr-face-sunglasses:before {
    content: "\f557";
    }
        .fi-sr-face-swear:before {
    content: "\f558";
    }
        .fi-sr-face-thermometer:before {
    content: "\f559";
    }
        .fi-sr-face-thinking:before {
    content: "\f55a";
    }
        .fi-sr-face-tissue:before {
    content: "\f55b";
    }
        .fi-sr-face-tongue-money:before {
    content: "\f55c";
    }
        .fi-sr-face-tongue-sweat:before {
    content: "\f55d";
    }
        .fi-sr-face-unamused:before {
    content: "\f55e";
    }
        .fi-sr-face-viewfinder:before {
    content: "\f55f";
    }
        .fi-sr-face-vomit:before {
    content: "\f560";
    }
        .fi-sr-face-weary:before {
    content: "\f561";
    }
        .fi-sr-face-woozy:before {
    content: "\f562";
    }
        .fi-sr-face-worried:before {
    content: "\f563";
    }
        .fi-sr-face-zany:before {
    content: "\f564";
    }
        .fi-sr-face-zipper:before {
    content: "\f565";
    }
        .fi-sr-falafel:before {
    content: "\f566";
    }
        .fi-sr-family-dress:before {
    content: "\f567";
    }
        .fi-sr-family-pants:before {
    content: "\f568";
    }
        .fi-sr-family:before {
    content: "\f569";
    }
        .fi-sr-fan-table:before {
    content: "\f56a";
    }
        .fi-sr-fan:before {
    content: "\f56b";
    }
        .fi-sr-farm:before {
    content: "\f56c";
    }
        .fi-sr-faucet-drip:before {
    content: "\f56d";
    }
        .fi-sr-faucet:before {
    content: "\f56e";
    }
        .fi-sr-fax:before {
    content: "\f56f";
    }
        .fi-sr-feather-pointed:before {
    content: "\f570";
    }
        .fi-sr-feather:before {
    content: "\f571";
    }
        .fi-sr-features-alt:before {
    content: "\f572";
    }
        .fi-sr-features:before {
    content: "\f573";
    }
        .fi-sr-feedback-alt:before {
    content: "\f574";
    }
        .fi-sr-feedback-hand:before {
    content: "\f575";
    }
        .fi-sr-feedback-review:before {
    content: "\f576";
    }
        .fi-sr-feedback:before {
    content: "\f577";
    }
        .fi-sr-fence:before {
    content: "\f578";
    }
        .fi-sr-field-hockey:before {
    content: "\f579";
    }
        .fi-sr-fighter-jet:before {
    content: "\f57a";
    }
        .fi-sr-file-ai:before {
    content: "\f57b";
    }
        .fi-sr-file-audio:before {
    content: "\f57c";
    }
        .fi-sr-file-binary:before {
    content: "\f57d";
    }
        .fi-sr-file-chart-line:before {
    content: "\f57e";
    }
        .fi-sr-file-chart-pie:before {
    content: "\f57f";
    }
        .fi-sr-file-circle-info:before {
    content: "\f580";
    }
        .fi-sr-file-code:before {
    content: "\f581";
    }
        .fi-sr-file-csv:before {
    content: "\f582";
    }
        .fi-sr-file-download:before {
    content: "\f583";
    }
        .fi-sr-file-edit:before {
    content: "\f584";
    }
        .fi-sr-file-eps:before {
    content: "\f585";
    }
        .fi-sr-file-excel:before {
    content: "\f586";
    }
        .fi-sr-file-exclamation:before {
    content: "\f587";
    }
        .fi-sr-file-export:before {
    content: "\f588";
    }
        .fi-sr-file-image:before {
    content: "\f589";
    }
        .fi-sr-file-import:before {
    content: "\f58a";
    }
        .fi-sr-file-invoice-dollar:before {
    content: "\f58b";
    }
        .fi-sr-file-invoice:before {
    content: "\f58c";
    }
        .fi-sr-file-medical-alt:before {
    content: "\f58d";
    }
        .fi-sr-file-medical:before {
    content: "\f58e";
    }
        .fi-sr-file-minus:before {
    content: "\f58f";
    }
        .fi-sr-file-pdf:before {
    content: "\f590";
    }
        .fi-sr-file-powerpoint:before {
    content: "\f591";
    }
        .fi-sr-file-prescription:before {
    content: "\f592";
    }
        .fi-sr-file-psd:before {
    content: "\f593";
    }
        .fi-sr-file-signature:before {
    content: "\f594";
    }
        .fi-sr-file-spreadsheet:before {
    content: "\f595";
    }
        .fi-sr-file-upload:before {
    content: "\f596";
    }
        .fi-sr-file-user:before {
    content: "\f597";
    }
        .fi-sr-file-video:before {
    content: "\f598";
    }
        .fi-sr-file-word:before {
    content: "\f599";
    }
        .fi-sr-file-zipper:before {
    content: "\f59a";
    }
        .fi-sr-file:before {
    content: "\f59b";
    }
        .fi-sr-files-medical:before {
    content: "\f59c";
    }
        .fi-sr-fill:before {
    content: "\f59d";
    }
        .fi-sr-film-canister:before {
    content: "\f59e";
    }
        .fi-sr-film-slash:before {
    content: "\f59f";
    }
        .fi-sr-film:before {
    content: "\f5a0";
    }
        .fi-sr-films:before {
    content: "\f5a1";
    }
        .fi-sr-filter-slash:before {
    content: "\f5a2";
    }
        .fi-sr-filter:before {
    content: "\f5a3";
    }
        .fi-sr-filters:before {
    content: "\f5a4";
    }
        .fi-sr-fingerprint:before {
    content: "\f5a5";
    }
        .fi-sr-fire-burner:before {
    content: "\f5a6";
    }
        .fi-sr-fire-extinguisher:before {
    content: "\f5a7";
    }
        .fi-sr-fire-flame-curved:before {
    content: "\f5a8";
    }
        .fi-sr-fire-flame-simple:before {
    content: "\f5a9";
    }
        .fi-sr-fire-hydrant:before {
    content: "\f5aa";
    }
        .fi-sr-fire-smoke:before {
    content: "\f5ab";
    }
        .fi-sr-fireplace:before {
    content: "\f5ac";
    }
        .fi-sr-first-award:before {
    content: "\f5ad";
    }
        .fi-sr-first-laurel:before {
    content: "\f5ae";
    }
        .fi-sr-first-medal:before {
    content: "\f5af";
    }
        .fi-sr-first:before {
    content: "\f5b0";
    }
        .fi-sr-fish-bones:before {
    content: "\f5b1";
    }
        .fi-sr-fish-cooked:before {
    content: "\f5b2";
    }
        .fi-sr-fish:before {
    content: "\f5b3";
    }
        .fi-sr-fishing-rod:before {
    content: "\f5b4";
    }
        .fi-sr-flag-alt:before {
    content: "\f5b5";
    }
        .fi-sr-flag-checkered:before {
    content: "\f5b6";
    }
        .fi-sr-flag-usa:before {
    content: "\f5b7";
    }
        .fi-sr-flag:before {
    content: "\f5b8";
    }
        .fi-sr-flame:before {
    content: "\f5b9";
    }
        .fi-sr-flashlight:before {
    content: "\f5ba";
    }
        .fi-sr-flask-poison:before {
    content: "\f5bb";
    }
        .fi-sr-flask-potion:before {
    content: "\f5bc";
    }
        .fi-sr-flask:before {
    content: "\f5bd";
    }
        .fi-sr-flatbread-stuffed:before {
    content: "\f5be";
    }
        .fi-sr-flatbread:before {
    content: "\f5bf";
    }
        .fi-sr-flip-horizontal:before {
    content: "\f5c0";
    }
        .fi-sr-floppy-disk-circle-arrow-right:before {
    content: "\f5c1";
    }
        .fi-sr-floppy-disk-circle-xmark:before {
    content: "\f5c2";
    }
        .fi-sr-floppy-disk-pen:before {
    content: "\f5c3";
    }
        .fi-sr-floppy-disks:before {
    content: "\f5c4";
    }
        .fi-sr-florin-sign:before {
    content: "\f5c5";
    }
        .fi-sr-flower-daffodil:before {
    content: "\f5c6";
    }
        .fi-sr-flower-tulip:before {
    content: "\f5c7";
    }
        .fi-sr-flower:before {
    content: "\f5c8";
    }
        .fi-sr-flushed:before {
    content: "\f5c9";
    }
        .fi-sr-flute:before {
    content: "\f5ca";
    }
        .fi-sr-flux-capacitor:before {
    content: "\f5cb";
    }
        .fi-sr-fly-insect:before {
    content: "\f5cc";
    }
        .fi-sr-flying-disc:before {
    content: "\f5cd";
    }
        .fi-sr-fog:before {
    content: "\f5ce";
    }
        .fi-sr-folder-download:before {
    content: "\f5cf";
    }
        .fi-sr-folder-minus:before {
    content: "\f5d0";
    }
        .fi-sr-folder-open:before {
    content: "\f5d1";
    }
        .fi-sr-folder-times:before {
    content: "\f5d2";
    }
        .fi-sr-folder-tree:before {
    content: "\f5d3";
    }
        .fi-sr-folder-upload:before {
    content: "\f5d4";
    }
        .fi-sr-folder-xmark:before {
    content: "\f5d5";
    }
        .fi-sr-folder:before {
    content: "\f5d6";
    }
        .fi-sr-folders:before {
    content: "\f5d7";
    }
        .fi-sr-follow-folder:before {
    content: "\f5d8";
    }
        .fi-sr-followcollection:before {
    content: "\f5d9";
    }
        .fi-sr-following:before {
    content: "\f5da";
    }
        .fi-sr-fondue-pot:before {
    content: "\f5db";
    }
        .fi-sr-football:before {
    content: "\f5dc";
    }
        .fi-sr-fork:before {
    content: "\f5dd";
    }
        .fi-sr-forklift:before {
    content: "\f5de";
    }
        .fi-sr-form:before {
    content: "\f5df";
    }
        .fi-sr-fort:before {
    content: "\f5e0";
    }
        .fi-sr-forward-fast:before {
    content: "\f5e1";
    }
        .fi-sr-forward:before {
    content: "\f5e2";
    }
        .fi-sr-fox:before {
    content: "\f5e3";
    }
        .fi-sr-frame:before {
    content: "\f5e4";
    }
        .fi-sr-franc-sign:before {
    content: "\f5e5";
    }
        .fi-sr-french-fries:before {
    content: "\f5e6";
    }
        .fi-sr-frog:before {
    content: "\f5e7";
    }
        .fi-sr-frown:before {
    content: "\f5e8";
    }
        .fi-sr-ftp:before {
    content: "\f5e9";
    }
        .fi-sr-funnel-dollar:before {
    content: "\f5ea";
    }
        .fi-sr-g:before {
    content: "\f5eb";
    }
        .fi-sr-galaxy-alt:before {
    content: "\f5ec";
    }
        .fi-sr-galaxy-planet:before {
    content: "\f5ed";
    }
        .fi-sr-galaxy-star:before {
    content: "\f5ee";
    }
        .fi-sr-galaxy:before {
    content: "\f5ef";
    }
        .fi-sr-gallery-thumbnails:before {
    content: "\f5f0";
    }
        .fi-sr-gallery:before {
    content: "\f5f1";
    }
        .fi-sr-game-board-alt:before {
    content: "\f5f2";
    }
        .fi-sr-game:before {
    content: "\f5f3";
    }
        .fi-sr-gamepad:before {
    content: "\f5f4";
    }
        .fi-sr-games:before {
    content: "\f5f5";
    }
        .fi-sr-garage-car:before {
    content: "\f5f6";
    }
        .fi-sr-garage-open:before {
    content: "\f5f7";
    }
        .fi-sr-garage:before {
    content: "\f5f8";
    }
        .fi-sr-garlic-alt:before {
    content: "\f5f9";
    }
        .fi-sr-garlic:before {
    content: "\f5fa";
    }
        .fi-sr-gas-pump-alt:before {
    content: "\f5fb";
    }
        .fi-sr-gas-pump-slash:before {
    content: "\f5fc";
    }
        .fi-sr-gas-pump:before {
    content: "\f5fd";
    }
        .fi-sr-gauge-circle-bolt:before {
    content: "\f5fe";
    }
        .fi-sr-gauge-circle-minus:before {
    content: "\f5ff";
    }
        .fi-sr-gauge-circle-plus:before {
    content: "\f600";
    }
        .fi-sr-gavel:before {
    content: "\f601";
    }
        .fi-sr-gears:before {
    content: "\f602";
    }
        .fi-sr-gem:before {
    content: "\f603";
    }
        .fi-sr-ghost:before {
    content: "\f604";
    }
        .fi-sr-gif-square:before {
    content: "\f605";
    }
        .fi-sr-gif:before {
    content: "\f606";
    }
        .fi-sr-gift-card:before {
    content: "\f607";
    }
        .fi-sr-gift:before {
    content: "\f608";
    }
        .fi-sr-gifts:before {
    content: "\f609";
    }
        .fi-sr-gingerbread-man:before {
    content: "\f60a";
    }
        .fi-sr-glass-champagne:before {
    content: "\f60b";
    }
        .fi-sr-glass-cheers:before {
    content: "\f60c";
    }
        .fi-sr-glass-citrus:before {
    content: "\f60d";
    }
        .fi-sr-glass-empty:before {
    content: "\f60e";
    }
        .fi-sr-glass-half:before {
    content: "\f60f";
    }
        .fi-sr-glass-water-droplet:before {
    content: "\f610";
    }
        .fi-sr-glass-whiskey-rocks:before {
    content: "\f611";
    }
        .fi-sr-glass-whiskey:before {
    content: "\f612";
    }
        .fi-sr-glass:before {
    content: "\f613";
    }
        .fi-sr-glasses:before {
    content: "\f614";
    }
        .fi-sr-globe-alt:before {
    content: "\f615";
    }
        .fi-sr-globe-snow:before {
    content: "\f616";
    }
        .fi-sr-globe:before {
    content: "\f617";
    }
        .fi-sr-goal-net:before {
    content: "\f618";
    }
        .fi-sr-golf-ball:before {
    content: "\f619";
    }
        .fi-sr-golf-club:before {
    content: "\f61a";
    }
        .fi-sr-golf:before {
    content: "\f61b";
    }
        .fi-sr-government-flag:before {
    content: "\f61c";
    }
        .fi-sr-government-user:before {
    content: "\f61d";
    }
        .fi-sr-graduation-cap:before {
    content: "\f61e";
    }
        .fi-sr-gramophone:before {
    content: "\f61f";
    }
        .fi-sr-grape:before {
    content: "\f620";
    }
        .fi-sr-graphic-style:before {
    content: "\f621";
    }
        .fi-sr-graphic-tablet:before {
    content: "\f622";
    }
        .fi-sr-grate-droplet:before {
    content: "\f623";
    }
        .fi-sr-grate:before {
    content: "\f624";
    }
        .fi-sr-grid-alt:before {
    content: "\f625";
    }
        .fi-sr-grid-dividers:before {
    content: "\f626";
    }
        .fi-sr-grid:before {
    content: "\f627";
    }
        .fi-sr-grill-hot-alt:before {
    content: "\f628";
    }
        .fi-sr-grill:before {
    content: "\f629";
    }
        .fi-sr-grimace:before {
    content: "\f62a";
    }
        .fi-sr-grin-alt:before {
    content: "\f62b";
    }
        .fi-sr-grin-beam-sweat:before {
    content: "\f62c";
    }
        .fi-sr-grin-beam:before {
    content: "\f62d";
    }
        .fi-sr-grin-hearts:before {
    content: "\f62e";
    }
        .fi-sr-grin-squint-tears:before {
    content: "\f62f";
    }
        .fi-sr-grin-squint:before {
    content: "\f630";
    }
        .fi-sr-grin-stars:before {
    content: "\f631";
    }
        .fi-sr-grin-tears:before {
    content: "\f632";
    }
        .fi-sr-grin-tongue-squint:before {
    content: "\f633";
    }
        .fi-sr-grin-tongue-wink:before {
    content: "\f634";
    }
        .fi-sr-grin-tongue:before {
    content: "\f635";
    }
        .fi-sr-grin-wink:before {
    content: "\f636";
    }
        .fi-sr-grin:before {
    content: "\f637";
    }
        .fi-sr-grip-dots-vertical:before {
    content: "\f638";
    }
        .fi-sr-grip-dots:before {
    content: "\f639";
    }
        .fi-sr-grip-horizontal:before {
    content: "\f63a";
    }
        .fi-sr-grip-lines-vertical:before {
    content: "\f63b";
    }
        .fi-sr-grip-lines:before {
    content: "\f63c";
    }
        .fi-sr-grip-vertical:before {
    content: "\f63d";
    }
        .fi-sr-guarani-sign:before {
    content: "\f63e";
    }
        .fi-sr-guide:before {
    content: "\f63f";
    }
        .fi-sr-guitar-electric:before {
    content: "\f640";
    }
        .fi-sr-guitar:before {
    content: "\f641";
    }
        .fi-sr-guitars:before {
    content: "\f642";
    }
        .fi-sr-gun-squirt:before {
    content: "\f643";
    }
        .fi-sr-gym:before {
    content: "\f644";
    }
        .fi-sr-h-square:before {
    content: "\f645";
    }
        .fi-sr-h:before {
    content: "\f646";
    }
        .fi-sr-h1:before {
    content: "\f647";
    }
        .fi-sr-h2:before {
    content: "\f648";
    }
        .fi-sr-h3:before {
    content: "\f649";
    }
        .fi-sr-h4:before {
    content: "\f64a";
    }
        .fi-sr-hamburger-soda:before {
    content: "\f64b";
    }
        .fi-sr-hamburger:before {
    content: "\f64c";
    }
        .fi-sr-hammer-crash:before {
    content: "\f64d";
    }
        .fi-sr-hammer-war:before {
    content: "\f64e";
    }
        .fi-sr-hammer:before {
    content: "\f64f";
    }
        .fi-sr-hand-back-fist:before {
    content: "\f650";
    }
        .fi-sr-hand-back-point-down:before {
    content: "\f651";
    }
        .fi-sr-hand-back-point-left:before {
    content: "\f652";
    }
        .fi-sr-hand-back-point-ribbon:before {
    content: "\f653";
    }
        .fi-sr-hand-back-point-right:before {
    content: "\f654";
    }
        .fi-sr-hand-dots:before {
    content: "\f655";
    }
        .fi-sr-hand-fingers-crossed:before {
    content: "\f656";
    }
        .fi-sr-hand-fist:before {
    content: "\f657";
    }
        .fi-sr-hand-heart:before {
    content: "\f658";
    }
        .fi-sr-hand-holding-box:before {
    content: "\f659";
    }
        .fi-sr-hand-holding-droplet:before {
    content: "\f65a";
    }
        .fi-sr-hand-holding-heart:before {
    content: "\f65b";
    }
        .fi-sr-hand-holding-magic:before {
    content: "\f65c";
    }
        .fi-sr-hand-holding-medical:before {
    content: "\f65d";
    }
        .fi-sr-hand-holding-seeding:before {
    content: "\f65e";
    }
        .fi-sr-hand-holding-skull:before {
    content: "\f65f";
    }
        .fi-sr-hand-holding-usd:before {
    content: "\f660";
    }
        .fi-sr-hand-holding-water:before {
    content: "\f661";
    }
        .fi-sr-hand-horns:before {
    content: "\f662";
    }
        .fi-sr-hand-lizard:before {
    content: "\f663";
    }
        .fi-sr-hand-love:before {
    content: "\f664";
    }
        .fi-sr-hand-middle-finger:before {
    content: "\f665";
    }
        .fi-sr-hand-paper:before {
    content: "\f666";
    }
        .fi-sr-hand-peace:before {
    content: "\f667";
    }
        .fi-sr-hand-point-ribbon:before {
    content: "\f668";
    }
        .fi-sr-hand-scissors:before {
    content: "\f669";
    }
        .fi-sr-hand-sparkles:before {
    content: "\f66a";
    }
        .fi-sr-hand-spock:before {
    content: "\f66b";
    }
        .fi-sr-hand-wave:before {
    content: "\f66c";
    }
        .fi-sr-hands-bubbles:before {
    content: "\f66d";
    }
        .fi-sr-hands-clapping:before {
    content: "\f66e";
    }
        .fi-sr-hands-heart:before {
    content: "\f66f";
    }
        .fi-sr-hands-holding-diamond:before {
    content: "\f670";
    }
        .fi-sr-hands-holding:before {
    content: "\f671";
    }
        .fi-sr-hands-usd:before {
    content: "\f672";
    }
        .fi-sr-handshake-angle:before {
    content: "\f673";
    }
        .fi-sr-handshake-simple-slash:before {
    content: "\f674";
    }
        .fi-sr-handshake:before {
    content: "\f675";
    }
        .fi-sr-hard-hat:before {
    content: "\f676";
    }
        .fi-sr-hastag:before {
    content: "\f677";
    }
        .fi-sr-hat-birthday:before {
    content: "\f678";
    }
        .fi-sr-hat-chef:before {
    content: "\f679";
    }
        .fi-sr-hat-cowboy-side:before {
    content: "\f67a";
    }
        .fi-sr-hat-cowboy:before {
    content: "\f67b";
    }
        .fi-sr-hat-santa:before {
    content: "\f67c";
    }
        .fi-sr-hat-winter:before {
    content: "\f67d";
    }
        .fi-sr-hat-witch:before {
    content: "\f67e";
    }
        .fi-sr-hat-wizard:before {
    content: "\f67f";
    }
        .fi-sr-hdd:before {
    content: "\f680";
    }
        .fi-sr-head-side-brain:before {
    content: "\f681";
    }
        .fi-sr-head-side-cough-slash:before {
    content: "\f682";
    }
        .fi-sr-head-side-cough:before {
    content: "\f683";
    }
        .fi-sr-head-side-headphones:before {
    content: "\f684";
    }
        .fi-sr-head-side-heart:before {
    content: "\f685";
    }
        .fi-sr-head-side-mask:before {
    content: "\f686";
    }
        .fi-sr-head-side-medical:before {
    content: "\f687";
    }
        .fi-sr-head-side-thinking:before {
    content: "\f688";
    }
        .fi-sr-head-side-virus:before {
    content: "\f689";
    }
        .fi-sr-head-side:before {
    content: "\f68a";
    }
        .fi-sr-head-vr:before {
    content: "\f68b";
    }
        .fi-sr-heading:before {
    content: "\f68c";
    }
        .fi-sr-headphones:before {
    content: "\f68d";
    }
        .fi-sr-headset:before {
    content: "\f68e";
    }
        .fi-sr-heart-arrow:before {
    content: "\f68f";
    }
        .fi-sr-heart-crack:before {
    content: "\f690";
    }
        .fi-sr-heart-half-stroke:before {
    content: "\f691";
    }
        .fi-sr-heart-half:before {
    content: "\f692";
    }
        .fi-sr-heart-rate:before {
    content: "\f693";
    }
        .fi-sr-heart:before {
    content: "\f694";
    }
        .fi-sr-heat:before {
    content: "\f695";
    }
        .fi-sr-helicopter-side:before {
    content: "\f696";
    }
        .fi-sr-helmet-battle:before {
    content: "\f697";
    }
        .fi-sr-hexagon-check:before {
    content: "\f698";
    }
        .fi-sr-hexagon-exclamation:before {
    content: "\f699";
    }
        .fi-sr-hexagon:before {
    content: "\f69a";
    }
        .fi-sr-high-definition:before {
    content: "\f69b";
    }
        .fi-sr-highlighter-line:before {
    content: "\f69c";
    }
        .fi-sr-highlighter:before {
    content: "\f69d";
    }
        .fi-sr-hiking:before {
    content: "\f69e";
    }
        .fi-sr-hippo:before {
    content: "\f69f";
    }
        .fi-sr-hockey-mask:before {
    content: "\f6a0";
    }
        .fi-sr-hockey-puck:before {
    content: "\f6a1";
    }
        .fi-sr-hockey-stick-puck:before {
    content: "\f6a2";
    }
        .fi-sr-hockey-sticks:before {
    content: "\f6a3";
    }
        .fi-sr-holly-berry:before {
    content: "\f6a4";
    }
        .fi-sr-home-heart:before {
    content: "\f6a5";
    }
        .fi-sr-home-location-alt:before {
    content: "\f6a6";
    }
        .fi-sr-home-location:before {
    content: "\f6a7";
    }
        .fi-sr-home:before {
    content: "\f6a8";
    }
        .fi-sr-honey-pot:before {
    content: "\f6a9";
    }
        .fi-sr-hood-cloak:before {
    content: "\f6aa";
    }
        .fi-sr-horizontal-rule:before {
    content: "\f6ab";
    }
        .fi-sr-horse-head:before {
    content: "\f6ac";
    }
        .fi-sr-horse-saddle:before {
    content: "\f6ad";
    }
        .fi-sr-horse:before {
    content: "\f6ae";
    }
        .fi-sr-hose-reel:before {
    content: "\f6af";
    }
        .fi-sr-hose:before {
    content: "\f6b0";
    }
        .fi-sr-hospital-symbol:before {
    content: "\f6b1";
    }
        .fi-sr-hospital-user:before {
    content: "\f6b2";
    }
        .fi-sr-hospital:before {
    content: "\f6b3";
    }
        .fi-sr-hospitals:before {
    content: "\f6b4";
    }
        .fi-sr-hot-tub:before {
    content: "\f6b5";
    }
        .fi-sr-hotdog:before {
    content: "\f6b6";
    }
        .fi-sr-hotel:before {
    content: "\f6b7";
    }
        .fi-sr-hourglass-end:before {
    content: "\f6b8";
    }
        .fi-sr-hourglass-start:before {
    content: "\f6b9";
    }
        .fi-sr-hourglass:before {
    content: "\f6ba";
    }
        .fi-sr-house-blank:before {
    content: "\f6bb";
    }
        .fi-sr-house-building:before {
    content: "\f6bc";
    }
        .fi-sr-house-chimney-blank:before {
    content: "\f6bd";
    }
        .fi-sr-house-chimney-crack:before {
    content: "\f6be";
    }
        .fi-sr-house-chimney-heart:before {
    content: "\f6bf";
    }
        .fi-sr-house-chimney-medical:before {
    content: "\f6c0";
    }
        .fi-sr-house-chimney-user:before {
    content: "\f6c1";
    }
        .fi-sr-house-chimney-window:before {
    content: "\f6c2";
    }
        .fi-sr-house-chimney:before {
    content: "\f6c3";
    }
        .fi-sr-house-crack:before {
    content: "\f6c4";
    }
        .fi-sr-house-day:before {
    content: "\f6c5";
    }
        .fi-sr-house-flood:before {
    content: "\f6c6";
    }
        .fi-sr-house-laptop:before {
    content: "\f6c7";
    }
        .fi-sr-house-leave:before {
    content: "\f6c8";
    }
        .fi-sr-house-medical:before {
    content: "\f6c9";
    }
        .fi-sr-house-night:before {
    content: "\f6ca";
    }
        .fi-sr-house-return:before {
    content: "\f6cb";
    }
        .fi-sr-house-signal:before {
    content: "\f6cc";
    }
        .fi-sr-house-tree:before {
    content: "\f6cd";
    }
        .fi-sr-house-tsunami:before {
    content: "\f6ce";
    }
        .fi-sr-house-turret:before {
    content: "\f6cf";
    }
        .fi-sr-house-user:before {
    content: "\f6d0";
    }
        .fi-sr-house-window:before {
    content: "\f6d1";
    }
        .fi-sr-hr-group:before {
    content: "\f6d2";
    }
        .fi-sr-hr-person:before {
    content: "\f6d3";
    }
        .fi-sr-hr:before {
    content: "\f6d4";
    }
        .fi-sr-hryvnia:before {
    content: "\f6d5";
    }
        .fi-sr-humidity:before {
    content: "\f6d6";
    }
        .fi-sr-hundred-points:before {
    content: "\f6d7";
    }
        .fi-sr-hurricane:before {
    content: "\f6d8";
    }
        .fi-sr-i:before {
    content: "\f6d9";
    }
        .fi-sr-ice-cream:before {
    content: "\f6da";
    }
        .fi-sr-icicles:before {
    content: "\f6db";
    }
        .fi-sr-icon-star:before {
    content: "\f6dc";
    }
        .fi-sr-id-badge:before {
    content: "\f6dd";
    }
        .fi-sr-id-card-clip-alt:before {
    content: "\f6de";
    }
        .fi-sr-igloo:before {
    content: "\f6df";
    }
        .fi-sr-image-slash:before {
    content: "\f6e0";
    }
        .fi-sr-images-user:before {
    content: "\f6e1";
    }
        .fi-sr-images:before {
    content: "\f6e2";
    }
        .fi-sr-inbox-in:before {
    content: "\f6e3";
    }
        .fi-sr-inbox-out:before {
    content: "\f6e4";
    }
        .fi-sr-inbox:before {
    content: "\f6e5";
    }
        .fi-sr-inboxes:before {
    content: "\f6e6";
    }
        .fi-sr-incognito:before {
    content: "\f6e7";
    }
        .fi-sr-indent:before {
    content: "\f6e8";
    }
        .fi-sr-indian-rupee-sign:before {
    content: "\f6e9";
    }
        .fi-sr-industry-alt:before {
    content: "\f6ea";
    }
        .fi-sr-industry-windows:before {
    content: "\f6eb";
    }
        .fi-sr-infinity:before {
    content: "\f6ec";
    }
        .fi-sr-info:before {
    content: "\f6ed";
    }
        .fi-sr-information:before {
    content: "\f6ee";
    }
        .fi-sr-inhaler:before {
    content: "\f6ef";
    }
        .fi-sr-input-numeric:before {
    content: "\f6f0";
    }
        .fi-sr-input-pipe:before {
    content: "\f6f1";
    }
        .fi-sr-input-text:before {
    content: "\f6f2";
    }
        .fi-sr-interactive:before {
    content: "\f6f3";
    }
        .fi-sr-interlining:before {
    content: "\f6f4";
    }
        .fi-sr-interrogation:before {
    content: "\f6f5";
    }
        .fi-sr-inventory-alt:before {
    content: "\f6f6";
    }
        .fi-sr-invest:before {
    content: "\f6f7";
    }
        .fi-sr-investment:before {
    content: "\f6f8";
    }
        .fi-sr-invite-alt:before {
    content: "\f6f9";
    }
        .fi-sr-invite:before {
    content: "\f6fa";
    }
        .fi-sr-island-tropical:before {
    content: "\f6fb";
    }
        .fi-sr-it-alt:before {
    content: "\f6fc";
    }
        .fi-sr-it-computer:before {
    content: "\f6fd";
    }
        .fi-sr-it:before {
    content: "\f6fe";
    }
        .fi-sr-italian-lira-sign:before {
    content: "\f6ff";
    }
        .fi-sr-italic:before {
    content: "\f700";
    }
        .fi-sr-j:before {
    content: "\f701";
    }
        .fi-sr-jam:before {
    content: "\f702";
    }
        .fi-sr-jar-alt:before {
    content: "\f703";
    }
        .fi-sr-jar-wheat:before {
    content: "\f704";
    }
        .fi-sr-joint:before {
    content: "\f705";
    }
        .fi-sr-journal-alt:before {
    content: "\f706";
    }
        .fi-sr-journal:before {
    content: "\f707";
    }
        .fi-sr-journey:before {
    content: "\f708";
    }
        .fi-sr-joystick:before {
    content: "\f709";
    }
        .fi-sr-jpg:before {
    content: "\f70a";
    }
        .fi-sr-jug-alt:before {
    content: "\f70b";
    }
        .fi-sr-jug-bottle:before {
    content: "\f70c";
    }
        .fi-sr-jug:before {
    content: "\f70d";
    }
        .fi-sr-k:before {
    content: "\f70e";
    }
        .fi-sr-kazoo:before {
    content: "\f70f";
    }
        .fi-sr-kerning:before {
    content: "\f710";
    }
        .fi-sr-key-skeleton-left-right:before {
    content: "\f711";
    }
        .fi-sr-key:before {
    content: "\f712";
    }
        .fi-sr-keyboard-brightness-low:before {
    content: "\f713";
    }
        .fi-sr-keyboard-brightness:before {
    content: "\f714";
    }
        .fi-sr-keyboard-down:before {
    content: "\f715";
    }
        .fi-sr-keyboard-left:before {
    content: "\f716";
    }
        .fi-sr-keyboard:before {
    content: "\f717";
    }
        .fi-sr-keynote:before {
    content: "\f718";
    }
        .fi-sr-kidneys:before {
    content: "\f719";
    }
        .fi-sr-kip-sign:before {
    content: "\f71a";
    }
        .fi-sr-kiss-beam:before {
    content: "\f71b";
    }
        .fi-sr-kiss-wink-heart:before {
    content: "\f71c";
    }
        .fi-sr-kiss:before {
    content: "\f71d";
    }
        .fi-sr-kite:before {
    content: "\f71e";
    }
        .fi-sr-kiwi-bird:before {
    content: "\f71f";
    }
        .fi-sr-kiwi-fruit:before {
    content: "\f720";
    }
        .fi-sr-knife-kitchen:before {
    content: "\f721";
    }
        .fi-sr-knife:before {
    content: "\f722";
    }
        .fi-sr-l:before {
    content: "\f723";
    }
        .fi-sr-label:before {
    content: "\f724";
    }
        .fi-sr-lacrosse-stick-ball:before {
    content: "\f725";
    }
        .fi-sr-lacrosse-stick:before {
    content: "\f726";
    }
        .fi-sr-lamp-desk:before {
    content: "\f727";
    }
        .fi-sr-lamp-floor:before {
    content: "\f728";
    }
        .fi-sr-lamp-street:before {
    content: "\f729";
    }
        .fi-sr-lamp:before {
    content: "\f72a";
    }
        .fi-sr-land-layer-location:before {
    content: "\f72b";
    }
        .fi-sr-land-layers:before {
    content: "\f72c";
    }
        .fi-sr-land-location:before {
    content: "\f72d";
    }
        .fi-sr-landmark-alt:before {
    content: "\f72e";
    }
        .fi-sr-language:before {
    content: "\f72f";
    }
        .fi-sr-laptop-arrow-down:before {
    content: "\f730";
    }
        .fi-sr-laptop-code:before {
    content: "\f731";
    }
        .fi-sr-laptop-medical:before {
    content: "\f732";
    }
        .fi-sr-laptop-mobile:before {
    content: "\f733";
    }
        .fi-sr-laptop-slash:before {
    content: "\f734";
    }
        .fi-sr-laptop:before {
    content: "\f735";
    }
        .fi-sr-lari-sign:before {
    content: "\f736";
    }
        .fi-sr-lasso-sparkles:before {
    content: "\f737";
    }
        .fi-sr-lasso:before {
    content: "\f738";
    }
        .fi-sr-laugh-beam:before {
    content: "\f739";
    }
        .fi-sr-laugh-squint:before {
    content: "\f73a";
    }
        .fi-sr-laugh-wink:before {
    content: "\f73b";
    }
        .fi-sr-laugh:before {
    content: "\f73c";
    }
        .fi-sr-layer-minus:before {
    content: "\f73d";
    }
        .fi-sr-layer-plus:before {
    content: "\f73e";
    }
        .fi-sr-layers:before {
    content: "\f73f";
    }
        .fi-sr-layout-fluid:before {
    content: "\f740";
    }
        .fi-sr-leader-alt:before {
    content: "\f741";
    }
        .fi-sr-leader:before {
    content: "\f742";
    }
        .fi-sr-leadership-alt:before {
    content: "\f743";
    }
        .fi-sr-leadership:before {
    content: "\f744";
    }
        .fi-sr-leaf-heart:before {
    content: "\f745";
    }
        .fi-sr-leaf-maple:before {
    content: "\f746";
    }
        .fi-sr-leaf-oak:before {
    content: "\f747";
    }
        .fi-sr-leaf:before {
    content: "\f748";
    }
        .fi-sr-leafy-green:before {
    content: "\f749";
    }
        .fi-sr-leave:before {
    content: "\f74a";
    }
        .fi-sr-left:before {
    content: "\f74b";
    }
        .fi-sr-legal:before {
    content: "\f74c";
    }
        .fi-sr-lemon:before {
    content: "\f74d";
    }
        .fi-sr-letter-case:before {
    content: "\f74e";
    }
        .fi-sr-lettuce:before {
    content: "\f74f";
    }
        .fi-sr-level-down-alt:before {
    content: "\f750";
    }
        .fi-sr-level-down:before {
    content: "\f751";
    }
        .fi-sr-level-up-alt:before {
    content: "\f752";
    }
        .fi-sr-level-up:before {
    content: "\f753";
    }
        .fi-sr-license:before {
    content: "\f754";
    }
        .fi-sr-life-ring:before {
    content: "\f755";
    }
        .fi-sr-light-ceiling:before {
    content: "\f756";
    }
        .fi-sr-light-emergency-on:before {
    content: "\f757";
    }
        .fi-sr-light-emergency:before {
    content: "\f758";
    }
        .fi-sr-light-switch-off:before {
    content: "\f759";
    }
        .fi-sr-light-switch-on:before {
    content: "\f75a";
    }
        .fi-sr-light-switch:before {
    content: "\f75b";
    }
        .fi-sr-lightbulb-dollar:before {
    content: "\f75c";
    }
        .fi-sr-lightbulb-exclamation:before {
    content: "\f75d";
    }
        .fi-sr-lightbulb-on:before {
    content: "\f75e";
    }
        .fi-sr-lightbulb-question:before {
    content: "\f75f";
    }
        .fi-sr-lightbulb-setting:before {
    content: "\f760";
    }
        .fi-sr-lightbulb-slash:before {
    content: "\f761";
    }
        .fi-sr-lights-holiday:before {
    content: "\f762";
    }
        .fi-sr-line-width:before {
    content: "\f763";
    }
        .fi-sr-link-alt:before {
    content: "\f764";
    }
        .fi-sr-link-horizontal-slash:before {
    content: "\f765";
    }
        .fi-sr-link-horizontal:before {
    content: "\f766";
    }
        .fi-sr-link-slash-alt:before {
    content: "\f767";
    }
        .fi-sr-link-slash:before {
    content: "\f768";
    }
        .fi-sr-link:before {
    content: "\f769";
    }
        .fi-sr-lips:before {
    content: "\f76a";
    }
        .fi-sr-lipstick:before {
    content: "\f76b";
    }
        .fi-sr-lira-sign:before {
    content: "\f76c";
    }
        .fi-sr-list-check:before {
    content: "\f76d";
    }
        .fi-sr-list-dropdown:before {
    content: "\f76e";
    }
        .fi-sr-list-music:before {
    content: "\f76f";
    }
        .fi-sr-list-timeline:before {
    content: "\f770";
    }
        .fi-sr-list:before {
    content: "\f771";
    }
        .fi-sr-litecoin-sign:before {
    content: "\f772";
    }
        .fi-sr-live-alt:before {
    content: "\f773";
    }
        .fi-sr-live:before {
    content: "\f774";
    }
        .fi-sr-loading:before {
    content: "\f775";
    }
        .fi-sr-loan:before {
    content: "\f776";
    }
        .fi-sr-lobster:before {
    content: "\f777";
    }
        .fi-sr-location-alt:before {
    content: "\f778";
    }
        .fi-sr-location-arrow:before {
    content: "\f779";
    }
        .fi-sr-location-crosshairs-slash:before {
    content: "\f77a";
    }
        .fi-sr-location-crosshairs:before {
    content: "\f77b";
    }
        .fi-sr-location-dot-slash:before {
    content: "\f77c";
    }
        .fi-sr-location-exclamation:before {
    content: "\f77d";
    }
        .fi-sr-lock-alt:before {
    content: "\f77e";
    }
        .fi-sr-lock-open-alt:before {
    content: "\f77f";
    }
        .fi-sr-lock:before {
    content: "\f780";
    }
        .fi-sr-locust:before {
    content: "\f781";
    }
        .fi-sr-loveseat:before {
    content: "\f782";
    }
        .fi-sr-low-vision:before {
    content: "\f783";
    }
        .fi-sr-luchador:before {
    content: "\f784";
    }
        .fi-sr-luggage-cart:before {
    content: "\f785";
    }
        .fi-sr-luggage-rolling:before {
    content: "\f786";
    }
        .fi-sr-lungs-virus:before {
    content: "\f787";
    }
        .fi-sr-lungs:before {
    content: "\f788";
    }
        .fi-sr-m:before {
    content: "\f789";
    }
        .fi-sr-mace:before {
    content: "\f78a";
    }
        .fi-sr-magnet-user:before {
    content: "\f78b";
    }
        .fi-sr-magnet:before {
    content: "\f78c";
    }
        .fi-sr-mailbox:before {
    content: "\f78d";
    }
        .fi-sr-makeup-brush:before {
    content: "\f78e";
    }
        .fi-sr-man-head:before {
    content: "\f78f";
    }
        .fi-sr-manat-sign:before {
    content: "\f790";
    }
        .fi-sr-mandolin:before {
    content: "\f791";
    }
        .fi-sr-mango:before {
    content: "\f792";
    }
        .fi-sr-manhole:before {
    content: "\f793";
    }
        .fi-sr-map-marker-check:before {
    content: "\f794";
    }
        .fi-sr-map-marker-cross:before {
    content: "\f795";
    }
        .fi-sr-map-marker-edit:before {
    content: "\f796";
    }
        .fi-sr-map-marker-home:before {
    content: "\f797";
    }
        .fi-sr-map-marker-minus:before {
    content: "\f798";
    }
        .fi-sr-map-marker-plus:before {
    content: "\f799";
    }
        .fi-sr-map-marker-question:before {
    content: "\f79a";
    }
        .fi-sr-map-marker-slash:before {
    content: "\f79b";
    }
        .fi-sr-map-marker-smile:before {
    content: "\f79c";
    }
        .fi-sr-map-marker:before {
    content: "\f79d";
    }
        .fi-sr-map-pin:before {
    content: "\f79e";
    }
        .fi-sr-map:before {
    content: "\f79f";
    }
        .fi-sr-marker-time:before {
    content: "\f7a0";
    }
        .fi-sr-marker:before {
    content: "\f7a1";
    }
        .fi-sr-mars-double:before {
    content: "\f7a2";
    }
        .fi-sr-mars-stroke-right:before {
    content: "\f7a3";
    }
        .fi-sr-mars-stroke-up:before {
    content: "\f7a4";
    }
        .fi-sr-mars:before {
    content: "\f7a5";
    }
        .fi-sr-martini-glass-citrus:before {
    content: "\f7a6";
    }
        .fi-sr-martini-glass-empty:before {
    content: "\f7a7";
    }
        .fi-sr-mask-carnival:before {
    content: "\f7a8";
    }
        .fi-sr-mask-face:before {
    content: "\f7a9";
    }
        .fi-sr-mask-snorkel:before {
    content: "\f7aa";
    }
        .fi-sr-massage:before {
    content: "\f7ab";
    }
        .fi-sr-match-fire:before {
    content: "\f7ac";
    }
        .fi-sr-mattress-pillow:before {
    content: "\f7ad";
    }
        .fi-sr-meat:before {
    content: "\f7ae";
    }
        .fi-sr-medal:before {
    content: "\f7af";
    }
        .fi-sr-medical-star:before {
    content: "\f7b0";
    }
        .fi-sr-medicine:before {
    content: "\f7b1";
    }
        .fi-sr-meeting-alt:before {
    content: "\f7b2";
    }
        .fi-sr-meeting:before {
    content: "\f7b3";
    }
        .fi-sr-megaphone:before {
    content: "\f7b4";
    }
        .fi-sr-meh-blank:before {
    content: "\f7b5";
    }
        .fi-sr-meh-rolling-eyes:before {
    content: "\f7b6";
    }
        .fi-sr-meh:before {
    content: "\f7b7";
    }
        .fi-sr-melon-alt:before {
    content: "\f7b8";
    }
        .fi-sr-melon:before {
    content: "\f7b9";
    }
        .fi-sr-memo-circle-check:before {
    content: "\f7ba";
    }
        .fi-sr-memo-pad:before {
    content: "\f7bb";
    }
        .fi-sr-memo:before {
    content: "\f7bc";
    }
        .fi-sr-memory:before {
    content: "\f7bd";
    }
        .fi-sr-menu-burger:before {
    content: "\f7be";
    }
        .fi-sr-menu-dots-vertical:before {
    content: "\f7bf";
    }
        .fi-sr-menu-dots:before {
    content: "\f7c0";
    }
        .fi-sr-mercury:before {
    content: "\f7c1";
    }
        .fi-sr-message-arrow-down:before {
    content: "\f7c2";
    }
        .fi-sr-message-arrow-up-right:before {
    content: "\f7c3";
    }
        .fi-sr-message-arrow-up:before {
    content: "\f7c4";
    }
        .fi-sr-message-bot:before {
    content: "\f7c5";
    }
        .fi-sr-message-code:before {
    content: "\f7c6";
    }
        .fi-sr-message-dollar:before {
    content: "\f7c7";
    }
        .fi-sr-message-image:before {
    content: "\f7c8";
    }
        .fi-sr-message-question:before {
    content: "\f7c9";
    }
        .fi-sr-message-quote:before {
    content: "\f7ca";
    }
        .fi-sr-message-slash:before {
    content: "\f7cb";
    }
        .fi-sr-message-sms:before {
    content: "\f7cc";
    }
        .fi-sr-message-text:before {
    content: "\f7cd";
    }
        .fi-sr-message-xmark:before {
    content: "\f7ce";
    }
        .fi-sr-messages-dollar:before {
    content: "\f7cf";
    }
        .fi-sr-messages-question:before {
    content: "\f7d0";
    }
        .fi-sr-messages:before {
    content: "\f7d1";
    }
        .fi-sr-meteor:before {
    content: "\f7d2";
    }
        .fi-sr-meter-bolt:before {
    content: "\f7d3";
    }
        .fi-sr-meter-droplet:before {
    content: "\f7d4";
    }
        .fi-sr-meter-fire:before {
    content: "\f7d5";
    }
        .fi-sr-meter:before {
    content: "\f7d6";
    }
        .fi-sr-microchip-ai:before {
    content: "\f7d7";
    }
        .fi-sr-microchip:before {
    content: "\f7d8";
    }
        .fi-sr-microphone-alt:before {
    content: "\f7d9";
    }
        .fi-sr-microphone-slash:before {
    content: "\f7da";
    }
        .fi-sr-microphone:before {
    content: "\f7db";
    }
        .fi-sr-microscope:before {
    content: "\f7dc";
    }
        .fi-sr-microwave:before {
    content: "\f7dd";
    }
        .fi-sr-mill-sign:before {
    content: "\f7de";
    }
        .fi-sr-mind-share:before {
    content: "\f7df";
    }
        .fi-sr-minus-circle:before {
    content: "\f7e0";
    }
        .fi-sr-minus-hexagon:before {
    content: "\f7e1";
    }
        .fi-sr-minus-small:before {
    content: "\f7e2";
    }
        .fi-sr-minus:before {
    content: "\f7e3";
    }
        .fi-sr-mistletoe:before {
    content: "\f7e4";
    }
        .fi-sr-mobile-button:before {
    content: "\f7e5";
    }
        .fi-sr-mobile-notch:before {
    content: "\f7e6";
    }
        .fi-sr-mobile:before {
    content: "\f7e7";
    }
        .fi-sr-mockup:before {
    content: "\f7e8";
    }
        .fi-sr-mode-alt:before {
    content: "\f7e9";
    }
        .fi-sr-mode-landscape:before {
    content: "\f7ea";
    }
        .fi-sr-mode-portrait:before {
    content: "\f7eb";
    }
        .fi-sr-mode:before {
    content: "\f7ec";
    }
        .fi-sr-money-bill-simple:before {
    content: "\f7ed";
    }
        .fi-sr-money-bill-wave-alt:before {
    content: "\f7ee";
    }
        .fi-sr-money-bill-wave:before {
    content: "\f7ef";
    }
        .fi-sr-money-bills-simple:before {
    content: "\f7f0";
    }
        .fi-sr-money-bills:before {
    content: "\f7f1";
    }
        .fi-sr-money-check-edit-alt:before {
    content: "\f7f2";
    }
        .fi-sr-money-check-edit:before {
    content: "\f7f3";
    }
        .fi-sr-money-check:before {
    content: "\f7f4";
    }
        .fi-sr-money-from-bracket:before {
    content: "\f7f5";
    }
        .fi-sr-money-simple-from-bracket:before {
    content: "\f7f6";
    }
        .fi-sr-money:before {
    content: "\f7f7";
    }
        .fi-sr-monkey:before {
    content: "\f7f8";
    }
        .fi-sr-monument:before {
    content: "\f7f9";
    }
        .fi-sr-moon-stars:before {
    content: "\f7fa";
    }
        .fi-sr-moon:before {
    content: "\f7fb";
    }
        .fi-sr-moped:before {
    content: "\f7fc";
    }
        .fi-sr-mortar-pestle:before {
    content: "\f7fd";
    }
        .fi-sr-mosquito-net:before {
    content: "\f7fe";
    }
        .fi-sr-mosquito:before {
    content: "\f7ff";
    }
        .fi-sr-motorcycle:before {
    content: "\f800";
    }
        .fi-sr-mound:before {
    content: "\f801";
    }
        .fi-sr-mountain-city:before {
    content: "\f802";
    }
        .fi-sr-mountain:before {
    content: "\f803";
    }
        .fi-sr-mountains:before {
    content: "\f804";
    }
        .fi-sr-mouse:before {
    content: "\f805";
    }
        .fi-sr-move-to-folder-2:before {
    content: "\f806";
    }
        .fi-sr-move-to-folder:before {
    content: "\f807";
    }
        .fi-sr-mp3-player:before {
    content: "\f808";
    }
        .fi-sr-mug-alt:before {
    content: "\f809";
    }
        .fi-sr-mug-hot-alt:before {
    content: "\f80a";
    }
        .fi-sr-mug-hot:before {
    content: "\f80b";
    }
        .fi-sr-mug-marshmallows:before {
    content: "\f80c";
    }
        .fi-sr-mug-tea-saucer:before {
    content: "\f80d";
    }
        .fi-sr-mug-tea:before {
    content: "\f80e";
    }
        .fi-sr-mug:before {
    content: "\f80f";
    }
        .fi-sr-mushroom-alt:before {
    content: "\f810";
    }
        .fi-sr-mushroom:before {
    content: "\f811";
    }
        .fi-sr-music-alt-slash:before {
    content: "\f812";
    }
        .fi-sr-music-alt:before {
    content: "\f813";
    }
        .fi-sr-music-file:before {
    content: "\f814";
    }
        .fi-sr-music-note:before {
    content: "\f815";
    }
        .fi-sr-music-slash:before {
    content: "\f816";
    }
        .fi-sr-music:before {
    content: "\f817";
    }
        .fi-sr-n:before {
    content: "\f818";
    }
        .fi-sr-naira-sign:before {
    content: "\f819";
    }
        .fi-sr-narwhal:before {
    content: "\f81a";
    }
        .fi-sr-navigation:before {
    content: "\f81b";
    }
        .fi-sr-nesting-dolls:before {
    content: "\f81c";
    }
        .fi-sr-network-cloud:before {
    content: "\f81d";
    }
        .fi-sr-network:before {
    content: "\f81e";
    }
        .fi-sr-neuter:before {
    content: "\f81f";
    }
        .fi-sr-newspaper-open:before {
    content: "\f820";
    }
        .fi-sr-newspaper:before {
    content: "\f821";
    }
        .fi-sr-nfc-lock:before {
    content: "\f822";
    }
        .fi-sr-nfc-magnifying-glass:before {
    content: "\f823";
    }
        .fi-sr-nfc-pen:before {
    content: "\f824";
    }
        .fi-sr-nfc-slash:before {
    content: "\f825";
    }
        .fi-sr-nfc-trash:before {
    content: "\f826";
    }
        .fi-sr-nfc:before {
    content: "\f827";
    }
        .fi-sr-no-people:before {
    content: "\f828";
    }
        .fi-sr-noodles:before {
    content: "\f829";
    }
        .fi-sr-notdef:before {
    content: "\f82a";
    }
        .fi-sr-note-medical:before {
    content: "\f82b";
    }
        .fi-sr-note-sticky:before {
    content: "\f82c";
    }
        .fi-sr-note:before {
    content: "\f82d";
    }
        .fi-sr-notebook-alt:before {
    content: "\f82e";
    }
        .fi-sr-notebook:before {
    content: "\f82f";
    }
        .fi-sr-notes-medical:before {
    content: "\f830";
    }
        .fi-sr-notes:before {
    content: "\f831";
    }
        .fi-sr-o:before {
    content: "\f832";
    }
        .fi-sr-object-exclude:before {
    content: "\f833";
    }
        .fi-sr-object-group:before {
    content: "\f834";
    }
        .fi-sr-object-intersect:before {
    content: "\f835";
    }
        .fi-sr-object-subtract:before {
    content: "\f836";
    }
        .fi-sr-object-ungroup:before {
    content: "\f837";
    }
        .fi-sr-object-union:before {
    content: "\f838";
    }
        .fi-sr-objects-column:before {
    content: "\f839";
    }
        .fi-sr-octagon:before {
    content: "\f83a";
    }
        .fi-sr-oil-can:before {
    content: "\f83b";
    }
        .fi-sr-oil-temp:before {
    content: "\f83c";
    }
        .fi-sr-olive-oil:before {
    content: "\f83d";
    }
        .fi-sr-olive:before {
    content: "\f83e";
    }
        .fi-sr-olives:before {
    content: "\f83f";
    }
        .fi-sr-onion:before {
    content: "\f840";
    }
        .fi-sr-opacity:before {
    content: "\f841";
    }
        .fi-sr-operation:before {
    content: "\f842";
    }
        .fi-sr-ornament:before {
    content: "\f843";
    }
        .fi-sr-otter:before {
    content: "\f844";
    }
        .fi-sr-outdent:before {
    content: "\f845";
    }
        .fi-sr-oven:before {
    content: "\f846";
    }
        .fi-sr-overline:before {
    content: "\f847";
    }
        .fi-sr-overview:before {
    content: "\f848";
    }
        .fi-sr-p:before {
    content: "\f849";
    }
        .fi-sr-package:before {
    content: "\f84a";
    }
        .fi-sr-page-break:before {
    content: "\f84b";
    }
        .fi-sr-pager:before {
    content: "\f84c";
    }
        .fi-sr-paint-roller:before {
    content: "\f84d";
    }
        .fi-sr-paint:before {
    content: "\f84e";
    }
        .fi-sr-paintbrush-pencil:before {
    content: "\f84f";
    }
        .fi-sr-palette:before {
    content: "\f850";
    }
        .fi-sr-pallet-alt:before {
    content: "\f851";
    }
        .fi-sr-pallet:before {
    content: "\f852";
    }
        .fi-sr-pan-food:before {
    content: "\f853";
    }
        .fi-sr-pan-frying:before {
    content: "\f854";
    }
        .fi-sr-pan:before {
    content: "\f855";
    }
        .fi-sr-pancakes:before {
    content: "\f856";
    }
        .fi-sr-panorama:before {
    content: "\f857";
    }
        .fi-sr-paper-plane-top:before {
    content: "\f858";
    }
        .fi-sr-paper-plane:before {
    content: "\f859";
    }
        .fi-sr-paperclip-vertical:before {
    content: "\f85a";
    }
        .fi-sr-parachute-box:before {
    content: "\f85b";
    }
        .fi-sr-paragraph-left:before {
    content: "\f85c";
    }
        .fi-sr-paragraph:before {
    content: "\f85d";
    }
        .fi-sr-parking-circle-slash:before {
    content: "\f85e";
    }
        .fi-sr-parking-circle:before {
    content: "\f85f";
    }
        .fi-sr-parking-slash:before {
    content: "\f860";
    }
        .fi-sr-parking:before {
    content: "\f861";
    }
        .fi-sr-party-bell:before {
    content: "\f862";
    }
        .fi-sr-party-horn:before {
    content: "\f863";
    }
        .fi-sr-party:before {
    content: "\f864";
    }
        .fi-sr-passport:before {
    content: "\f865";
    }
        .fi-sr-password:before {
    content: "\f866";
    }
        .fi-sr-paste:before {
    content: "\f867";
    }
        .fi-sr-pattern:before {
    content: "\f868";
    }
        .fi-sr-pause-circle:before {
    content: "\f869";
    }
        .fi-sr-pause:before {
    content: "\f86a";
    }
        .fi-sr-paw-claws:before {
    content: "\f86b";
    }
        .fi-sr-paw:before {
    content: "\f86c";
    }
        .fi-sr-peace:before {
    content: "\f86d";
    }
        .fi-sr-peach:before {
    content: "\f86e";
    }
        .fi-sr-peanut:before {
    content: "\f86f";
    }
        .fi-sr-peanuts:before {
    content: "\f870";
    }
        .fi-sr-peapod:before {
    content: "\f871";
    }
        .fi-sr-pear:before {
    content: "\f872";
    }
        .fi-sr-pedestal:before {
    content: "\f873";
    }
        .fi-sr-pen-circle:before {
    content: "\f874";
    }
        .fi-sr-pen-clip-slash:before {
    content: "\f875";
    }
        .fi-sr-pen-clip:before {
    content: "\f876";
    }
        .fi-sr-pen-fancy-slash:before {
    content: "\f877";
    }
        .fi-sr-pen-fancy:before {
    content: "\f878";
    }
        .fi-sr-pen-field:before {
    content: "\f879";
    }
        .fi-sr-pen-nib-slash:before {
    content: "\f87a";
    }
        .fi-sr-pen-nib:before {
    content: "\f87b";
    }
        .fi-sr-pen-slash:before {
    content: "\f87c";
    }
        .fi-sr-pen-square:before {
    content: "\f87d";
    }
        .fi-sr-pen-swirl:before {
    content: "\f87e";
    }
        .fi-sr-pencil-paintbrush:before {
    content: "\f87f";
    }
        .fi-sr-pencil-ruler:before {
    content: "\f880";
    }
        .fi-sr-pencil-slash:before {
    content: "\f881";
    }
        .fi-sr-pencil:before {
    content: "\f882";
    }
        .fi-sr-pennant:before {
    content: "\f883";
    }
        .fi-sr-people-arrows-left-right:before {
    content: "\f884";
    }
        .fi-sr-people-carry-box:before {
    content: "\f885";
    }
        .fi-sr-people-dress:before {
    content: "\f886";
    }
        .fi-sr-people-pants:before {
    content: "\f887";
    }
        .fi-sr-people-poll:before {
    content: "\f888";
    }
        .fi-sr-people:before {
    content: "\f889";
    }
        .fi-sr-pepper-alt:before {
    content: "\f88a";
    }
        .fi-sr-pepper-hot:before {
    content: "\f88b";
    }
        .fi-sr-pepper:before {
    content: "\f88c";
    }
        .fi-sr-percentage:before {
    content: "\f88d";
    }
        .fi-sr-person-carry-box:before {
    content: "\f88e";
    }
        .fi-sr-person-circle-check:before {
    content: "\f88f";
    }
        .fi-sr-person-circle-exclamation:before {
    content: "\f890";
    }
        .fi-sr-person-circle-minus:before {
    content: "\f891";
    }
        .fi-sr-person-circle-plus:before {
    content: "\f892";
    }
        .fi-sr-person-circle-question:before {
    content: "\f893";
    }
        .fi-sr-person-circle-xmark:before {
    content: "\f894";
    }
        .fi-sr-person-dolly-empty:before {
    content: "\f895";
    }
        .fi-sr-person-dolly:before {
    content: "\f896";
    }
        .fi-sr-person-dress-simple:before {
    content: "\f897";
    }
        .fi-sr-person-dress:before {
    content: "\f898";
    }
        .fi-sr-person-praying:before {
    content: "\f899";
    }
        .fi-sr-person-pregnant:before {
    content: "\f89a";
    }
        .fi-sr-person-seat-reclined:before {
    content: "\f89b";
    }
        .fi-sr-person-seat:before {
    content: "\f89c";
    }
        .fi-sr-person-sign:before {
    content: "\f89d";
    }
        .fi-sr-person-simple:before {
    content: "\f89e";
    }
        .fi-sr-person-walking-with-cane:before {
    content: "\f89f";
    }
        .fi-sr-peseta-sign:before {
    content: "\f8a0";
    }
        .fi-sr-peso-sign:before {
    content: "\f8a1";
    }
        .fi-sr-pharmacy:before {
    content: "\f8a2";
    }
        .fi-sr-phone-call:before {
    content: "\f8a3";
    }
        .fi-sr-phone-cross:before {
    content: "\f8a4";
    }
        .fi-sr-phone-flip:before {
    content: "\f8a5";
    }
        .fi-sr-phone-office:before {
    content: "\f8a6";
    }
        .fi-sr-phone-pause:before {
    content: "\f8a7";
    }
        .fi-sr-phone-plus:before {
    content: "\f8a8";
    }
        .fi-sr-phone-rotary:before {
    content: "\f8a9";
    }
        .fi-sr-phone-slash:before {
    content: "\f8aa";
    }
        .fi-sr-photo-film-music:before {
    content: "\f8ab";
    }
        .fi-sr-photo-video:before {
    content: "\f8ac";
    }
        .fi-sr-physics:before {
    content: "\f8ad";
    }
        .fi-sr-Pi:before {
    content: "\f8ae";
    }
        .fi-sr-piano-keyboard:before {
    content: "\f8af";
    }
        .fi-sr-piano:before {
    content: "\f8b0";
    }
        .fi-sr-pickaxe:before {
    content: "\f8b1";
    }
        .fi-sr-picnic:before {
    content: "\f8b2";
    }
        .fi-sr-picpeople-filled:before {
    content: "\f8b3";
    }
        .fi-sr-picpeople:before {
    content: "\f8b4";
    }
        .fi-sr-picture:before {
    content: "\f8b5";
    }
        .fi-sr-pie:before {
    content: "\f8b6";
    }
        .fi-sr-pig-face:before {
    content: "\f8b7";
    }
        .fi-sr-pig:before {
    content: "\f8b8";
    }
        .fi-sr-piggy-bank:before {
    content: "\f8b9";
    }
        .fi-sr-pills:before {
    content: "\f8ba";
    }
        .fi-sr-pinata:before {
    content: "\f8bb";
    }
        .fi-sr-pineapple-alt:before {
    content: "\f8bc";
    }
        .fi-sr-pineapple:before {
    content: "\f8bd";
    }
        .fi-sr-pipe-smoking:before {
    content: "\f8be";
    }
        .fi-sr-pipette:before {
    content: "\f8bf";
    }
        .fi-sr-pizza-slice:before {
    content: "\f8c0";
    }
        .fi-sr-plane-alt:before {
    content: "\f8c1";
    }
        .fi-sr-plane-arrival:before {
    content: "\f8c2";
    }
        .fi-sr-plane-departure:before {
    content: "\f8c3";
    }
        .fi-sr-plane-prop:before {
    content: "\f8c4";
    }
        .fi-sr-plane-slash:before {
    content: "\f8c5";
    }
        .fi-sr-plane-tail:before {
    content: "\f8c6";
    }
        .fi-sr-plane:before {
    content: "\f8c7";
    }
        .fi-sr-planet-moon:before {
    content: "\f8c8";
    }
        .fi-sr-planet-ringed:before {
    content: "\f8c9";
    }
        .fi-sr-plant-wilt:before {
    content: "\f8ca";
    }
        .fi-sr-plate-wheat:before {
    content: "\f8cb";
    }
        .fi-sr-plate:before {
    content: "\f8cc";
    }
        .fi-sr-play-alt:before {
    content: "\f8cd";
    }
        .fi-sr-play-circle:before {
    content: "\f8ce";
    }
        .fi-sr-play-pause:before {
    content: "\f8cf";
    }
        .fi-sr-play:before {
    content: "\f8d0";
    }
        .fi-sr-plug-alt:before {
    content: "\f8d1";
    }
        .fi-sr-plug-cable:before {
    content: "\f8d2";
    }
        .fi-sr-plug-connection:before {
    content: "\f8d3";
    }
        .fi-sr-plug:before {
    content: "\f8d4";
    }
        .fi-sr-plus-hexagon:before {
    content: "\f8d5";
    }
        .fi-sr-plus-minus:before {
    content: "\f8d6";
    }
        .fi-sr-plus-small:before {
    content: "\f8d7";
    }
        .fi-sr-plus:before {
    content: "\f8d8";
    }
        .fi-sr-podcast:before {
    content: "\f8d9";
    }
        .fi-sr-podium-star:before {
    content: "\f8da";
    }
        .fi-sr-podium:before {
    content: "\f8db";
    }
        .fi-sr-police-box:before {
    content: "\f8dc";
    }
        .fi-sr-poll-h:before {
    content: "\f8dd";
    }
        .fi-sr-pompebled:before {
    content: "\f8de";
    }
        .fi-sr-poo-bolt:before {
    content: "\f8df";
    }
        .fi-sr-poo:before {
    content: "\f8e0";
    }
        .fi-sr-pool-8-ball:before {
    content: "\f8e1";
    }
        .fi-sr-poop:before {
    content: "\f8e2";
    }
        .fi-sr-popcorn:before {
    content: "\f8e3";
    }
        .fi-sr-popsicle:before {
    content: "\f8e4";
    }
        .fi-sr-portal-enter:before {
    content: "\f8e5";
    }
        .fi-sr-portal-exit:before {
    content: "\f8e6";
    }
        .fi-sr-portrait:before {
    content: "\f8e7";
    }
        .fi-sr-pot:before {
    content: "\f8e8";
    }
        .fi-sr-potato:before {
    content: "\f8e9";
    }
        .fi-sr-pound:before {
    content: "\f8ea";
    }
        .fi-sr-power:before {
    content: "\f8eb";
    }
        .fi-sr-prescription-bottle-alt:before {
    content: "\f8ec";
    }
        .fi-sr-prescription-bottle-pill:before {
    content: "\f8ed";
    }
        .fi-sr-prescription-bottle:before {
    content: "\f8ee";
    }
        .fi-sr-prescription:before {
    content: "\f8ef";
    }
        .fi-sr-presentation:before {
    content: "\f8f0";
    }
        .fi-sr-preview:before {
    content: "\f8f1";
    }
        .fi-sr-print-magnifying-glass:before {
    content: "\f8f2";
    }
        .fi-sr-print-slash:before {
    content: "\f8f3";
    }
        .fi-sr-print:before {
    content: "\f8f4";
    }
        .fi-sr-priority-arrow:before {
    content: "\f8f5";
    }
        .fi-sr-priority-arrows:before {
    content: "\f8f6";
    }
        .fi-sr-priority-importance:before {
    content: "\f8f7";
    }
        .fi-sr-procedures:before {
    content: "\f8f8";
    }
        .fi-sr-process:before {
    content: "\f8f9";
    }
        .fi-sr-projector:before {
    content: "\f8fa";
    }
        .fi-sr-protractor:before {
    content: "\f8fb";
    }
        .fi-sr-pulse:before {
    content: "\f8fc";
    }
        .fi-sr-pump-medical:before {
    content: "\f8fd";
    }
        .fi-sr-pump:before {
    content: "\f8fe";
    }
        .fi-sr-pumpkin-alt-2:before {
    content: "\f8ff";
    }
        .fi-sr-pumpkin-alt:before {
    content: "\f900";
    }
        .fi-sr-pumpkin:before {
    content: "\f901";
    }
        .fi-sr-puzzle-alt:before {
    content: "\f902";
    }
        .fi-sr-puzzle-piece:before {
    content: "\f903";
    }
        .fi-sr-puzzle-pieces:before {
    content: "\f904";
    }
        .fi-sr-pyramid:before {
    content: "\f905";
    }
        .fi-sr-q:before {
    content: "\f906";
    }
        .fi-sr-qr-scan:before {
    content: "\f907";
    }
        .fi-sr-QR:before {
    content: "\f908";
    }
        .fi-sr-qrcode:before {
    content: "\f909";
    }
        .fi-sr-question-square:before {
    content: "\f90a";
    }
        .fi-sr-question:before {
    content: "\f90b";
    }
        .fi-sr-quiz-alt:before {
    content: "\f90c";
    }
        .fi-sr-quiz:before {
    content: "\f90d";
    }
        .fi-sr-quote-right:before {
    content: "\f90e";
    }
        .fi-sr-r:before {
    content: "\f90f";
    }
        .fi-sr-rabbit-fast:before {
    content: "\f910";
    }
        .fi-sr-rabbit:before {
    content: "\f911";
    }
        .fi-sr-racquet:before {
    content: "\f912";
    }
        .fi-sr-radar:before {
    content: "\f913";
    }
        .fi-sr-radiation-alt:before {
    content: "\f914";
    }
        .fi-sr-radiation:before {
    content: "\f915";
    }
        .fi-sr-radio-alt:before {
    content: "\f916";
    }
        .fi-sr-radio:before {
    content: "\f917";
    }
        .fi-sr-radish:before {
    content: "\f918";
    }
        .fi-sr-rainbow:before {
    content: "\f919";
    }
        .fi-sr-raindrops:before {
    content: "\f91a";
    }
        .fi-sr-ram:before {
    content: "\f91b";
    }
        .fi-sr-ramp-loading:before {
    content: "\f91c";
    }
        .fi-sr-rank:before {
    content: "\f91d";
    }
        .fi-sr-ranking-podium:before {
    content: "\f91e";
    }
        .fi-sr-ranking-star:before {
    content: "\f91f";
    }
        .fi-sr-ranking-stars:before {
    content: "\f920";
    }
        .fi-sr-raygun:before {
    content: "\f921";
    }
        .fi-sr-react:before {
    content: "\f922";
    }
        .fi-sr-rec:before {
    content: "\f923";
    }
        .fi-sr-receipt:before {
    content: "\f924";
    }
        .fi-sr-record-vinyl:before {
    content: "\f925";
    }
        .fi-sr-rectabgle-vertical:before {
    content: "\f926";
    }
        .fi-sr-rectangle-barcode:before {
    content: "\f927";
    }
        .fi-sr-rectangle-code:before {
    content: "\f928";
    }
        .fi-sr-rectangle-history-circle-plus:before {
    content: "\f929";
    }
        .fi-sr-rectangle-horizontal:before {
    content: "\f92a";
    }
        .fi-sr-rectangle-list:before {
    content: "\f92b";
    }
        .fi-sr-rectangle-panoramic:before {
    content: "\f92c";
    }
        .fi-sr-rectangle-pro:before {
    content: "\f92d";
    }
        .fi-sr-rectangle-vertical-history:before {
    content: "\f92e";
    }
        .fi-sr-rectangle-vertical:before {
    content: "\f92f";
    }
        .fi-sr-rectangle-xmark:before {
    content: "\f930";
    }
        .fi-sr-rectangles-mixed:before {
    content: "\f931";
    }
        .fi-sr-recycle:before {
    content: "\f932";
    }
        .fi-sr-redo-alt:before {
    content: "\f933";
    }
        .fi-sr-redo:before {
    content: "\f934";
    }
        .fi-sr-reel:before {
    content: "\f935";
    }
        .fi-sr-refer-arrow:before {
    content: "\f936";
    }
        .fi-sr-refer:before {
    content: "\f937";
    }
        .fi-sr-reflect-horizontal:before {
    content: "\f938";
    }
        .fi-sr-reflect-vertical:before {
    content: "\f939";
    }
        .fi-sr-reflect:before {
    content: "\f93a";
    }
        .fi-sr-refresh:before {
    content: "\f93b";
    }
        .fi-sr-refrigerator:before {
    content: "\f93c";
    }
        .fi-sr-refund-alt:before {
    content: "\f93d";
    }
        .fi-sr-refund:before {
    content: "\f93e";
    }
        .fi-sr-registered:before {
    content: "\f93f";
    }
        .fi-sr-registration-paper:before {
    content: "\f940";
    }
        .fi-sr-remove-folder:before {
    content: "\f941";
    }
        .fi-sr-remove-user:before {
    content: "\f942";
    }
        .fi-sr-rent-signal:before {
    content: "\f943";
    }
        .fi-sr-rent:before {
    content: "\f944";
    }
        .fi-sr-replace:before {
    content: "\f945";
    }
        .fi-sr-reply-all:before {
    content: "\f946";
    }
        .fi-sr-republican:before {
    content: "\f947";
    }
        .fi-sr-resize:before {
    content: "\f948";
    }
        .fi-sr-resources:before {
    content: "\f949";
    }
        .fi-sr-restaurant:before {
    content: "\f94a";
    }
        .fi-sr-restroom-simple:before {
    content: "\f94b";
    }
        .fi-sr-revenue-alt:before {
    content: "\f94c";
    }
        .fi-sr-Revenue:before {
    content: "\f94d";
    }
        .fi-sr-review:before {
    content: "\f94e";
    }
        .fi-sr-rewind:before {
    content: "\f94f";
    }
        .fi-sr-rhombus:before {
    content: "\f950";
    }
        .fi-sr-ribbon:before {
    content: "\f951";
    }
        .fi-sr-right:before {
    content: "\f952";
    }
        .fi-sr-ring-diamond:before {
    content: "\f953";
    }
        .fi-sr-ring:before {
    content: "\f954";
    }
        .fi-sr-rings-wedding:before {
    content: "\f955";
    }
        .fi-sr-risk-alt:before {
    content: "\f956";
    }
        .fi-sr-risk:before {
    content: "\f957";
    }
        .fi-sr-road:before {
    content: "\f958";
    }
        .fi-sr-robot:before {
    content: "\f959";
    }
        .fi-sr-rocket-lunch:before {
    content: "\f95a";
    }
        .fi-sr-rocket:before {
    content: "\f95b";
    }
        .fi-sr-roller-coaster:before {
    content: "\f95c";
    }
        .fi-sr-room-service:before {
    content: "\f95d";
    }
        .fi-sr-rotate-exclamation:before {
    content: "\f95e";
    }
        .fi-sr-rotate-left:before {
    content: "\f95f";
    }
        .fi-sr-rotate-right:before {
    content: "\f960";
    }
        .fi-sr-route-highway:before {
    content: "\f961";
    }
        .fi-sr-route-interstate:before {
    content: "\f962";
    }
        .fi-sr-route:before {
    content: "\f963";
    }
        .fi-sr-router:before {
    content: "\f964";
    }
        .fi-sr-rss-alt:before {
    content: "\f965";
    }
        .fi-sr-rss:before {
    content: "\f966";
    }
        .fi-sr-ruble-sign:before {
    content: "\f967";
    }
        .fi-sr-rugby-helmet:before {
    content: "\f968";
    }
        .fi-sr-rugby:before {
    content: "\f969";
    }
        .fi-sr-ruler-combined:before {
    content: "\f96a";
    }
        .fi-sr-ruler-horizontal:before {
    content: "\f96b";
    }
        .fi-sr-ruler-triangle:before {
    content: "\f96c";
    }
        .fi-sr-ruler-vertical:before {
    content: "\f96d";
    }
        .fi-sr-running:before {
    content: "\f96e";
    }
        .fi-sr-rupee-sign:before {
    content: "\f96f";
    }
        .fi-sr-rupiah-sign:before {
    content: "\f970";
    }
        .fi-sr-rv:before {
    content: "\f971";
    }
        .fi-sr-s:before {
    content: "\f972";
    }
        .fi-sr-sack-dollar:before {
    content: "\f973";
    }
        .fi-sr-sack:before {
    content: "\f974";
    }
        .fi-sr-sad-cry:before {
    content: "\f975";
    }
        .fi-sr-sad-tear:before {
    content: "\f976";
    }
        .fi-sr-sad:before {
    content: "\f977";
    }
        .fi-sr-safe-box:before {
    content: "\f978";
    }
        .fi-sr-sailboat:before {
    content: "\f979";
    }
        .fi-sr-salad:before {
    content: "\f97a";
    }
        .fi-sr-salt-pepper:before {
    content: "\f97b";
    }
        .fi-sr-salt-shaker:before {
    content: "\f97c";
    }
        .fi-sr-sandwich-alt:before {
    content: "\f97d";
    }
        .fi-sr-sandwich:before {
    content: "\f97e";
    }
        .fi-sr-satellite-dish:before {
    content: "\f97f";
    }
        .fi-sr-satellite:before {
    content: "\f980";
    }
        .fi-sr-sauce:before {
    content: "\f981";
    }
        .fi-sr-sausage:before {
    content: "\f982";
    }
        .fi-sr-sax-hot:before {
    content: "\f983";
    }
        .fi-sr-saxophone:before {
    content: "\f984";
    }
        .fi-sr-scale:before {
    content: "\f985";
    }
        .fi-sr-scalpel-path:before {
    content: "\f986";
    }
        .fi-sr-scalpel:before {
    content: "\f987";
    }
        .fi-sr-scanner-gun:before {
    content: "\f988";
    }
        .fi-sr-scanner-image:before {
    content: "\f989";
    }
        .fi-sr-scanner-keyboard:before {
    content: "\f98a";
    }
        .fi-sr-scanner-touchscreen:before {
    content: "\f98b";
    }
        .fi-sr-scarecrow:before {
    content: "\f98c";
    }
        .fi-sr-scarf:before {
    content: "\f98d";
    }
        .fi-sr-school-bus:before {
    content: "\f98e";
    }
        .fi-sr-school:before {
    content: "\f98f";
    }
        .fi-sr-scissors:before {
    content: "\f990";
    }
        .fi-sr-screen:before {
    content: "\f991";
    }
        .fi-sr-screencast:before {
    content: "\f992";
    }
        .fi-sr-screwdriver:before {
    content: "\f993";
    }
        .fi-sr-scribble:before {
    content: "\f994";
    }
        .fi-sr-scroll-old:before {
    content: "\f995";
    }
        .fi-sr-scroll-torah:before {
    content: "\f996";
    }
        .fi-sr-scroll:before {
    content: "\f997";
    }
        .fi-sr-scrubber:before {
    content: "\f998";
    }
        .fi-sr-scythe:before {
    content: "\f999";
    }
        .fi-sr-sd-card:before {
    content: "\f99a";
    }
        .fi-sr-sd-cards:before {
    content: "\f99b";
    }
        .fi-sr-seal-exclamation:before {
    content: "\f99c";
    }
        .fi-sr-seal-question:before {
    content: "\f99d";
    }
        .fi-sr-search-alt:before {
    content: "\f99e";
    }
        .fi-sr-search-dollar:before {
    content: "\f99f";
    }
        .fi-sr-search-heart:before {
    content: "\f9a0";
    }
        .fi-sr-search-location:before {
    content: "\f9a1";
    }
        .fi-sr-search:before {
    content: "\f9a2";
    }
        .fi-sr-seat-airline:before {
    content: "\f9a3";
    }
        .fi-sr-second-award:before {
    content: "\f9a4";
    }
        .fi-sr-second-laurel:before {
    content: "\f9a5";
    }
        .fi-sr-second-medal:before {
    content: "\f9a6";
    }
        .fi-sr-second:before {
    content: "\f9a7";
    }
        .fi-sr-seedling:before {
    content: "\f9a8";
    }
        .fi-sr-select:before {
    content: "\f9a9";
    }
        .fi-sr-selection:before {
    content: "\f9aa";
    }
        .fi-sr-send-back:before {
    content: "\f9ab";
    }
        .fi-sr-send-backward:before {
    content: "\f9ac";
    }
        .fi-sr-sensor-alert:before {
    content: "\f9ad";
    }
        .fi-sr-sensor-fire:before {
    content: "\f9ae";
    }
        .fi-sr-sensor-on:before {
    content: "\f9af";
    }
        .fi-sr-sensor-smoke:before {
    content: "\f9b0";
    }
        .fi-sr-sensor:before {
    content: "\f9b1";
    }
        .fi-sr-settings-sliders:before {
    content: "\f9b2";
    }
        .fi-sr-settings:before {
    content: "\f9b3";
    }
        .fi-sr-share-alt-square:before {
    content: "\f9b4";
    }
        .fi-sr-share-square:before {
    content: "\f9b5";
    }
        .fi-sr-share:before {
    content: "\f9b6";
    }
        .fi-sr-sheep:before {
    content: "\f9b7";
    }
        .fi-sr-shekel-sign:before {
    content: "\f9b8";
    }
        .fi-sr-shelves:before {
    content: "\f9b9";
    }
        .fi-sr-shield-alt:before {
    content: "\f9ba";
    }
        .fi-sr-shield-check:before {
    content: "\f9bb";
    }
        .fi-sr-shield-cross:before {
    content: "\f9bc";
    }
        .fi-sr-shield-exclamation:before {
    content: "\f9bd";
    }
        .fi-sr-shield-interrogation:before {
    content: "\f9be";
    }
        .fi-sr-shield-keyhole:before {
    content: "\f9bf";
    }
        .fi-sr-shield-minus:before {
    content: "\f9c0";
    }
        .fi-sr-shield-plus:before {
    content: "\f9c1";
    }
        .fi-sr-shield-slash:before {
    content: "\f9c2";
    }
        .fi-sr-shield-virus:before {
    content: "\f9c3";
    }
        .fi-sr-shield-xmark:before {
    content: "\f9c4";
    }
        .fi-sr-shield:before {
    content: "\f9c5";
    }
        .fi-sr-ship-side:before {
    content: "\f9c6";
    }
        .fi-sr-ship:before {
    content: "\f9c7";
    }
        .fi-sr-shipping-fast:before {
    content: "\f9c8";
    }
        .fi-sr-shipping-timed:before {
    content: "\f9c9";
    }
        .fi-sr-shirt-long-sleeve:before {
    content: "\f9ca";
    }
        .fi-sr-shirt-running:before {
    content: "\f9cb";
    }
        .fi-sr-shirt-tank-top:before {
    content: "\f9cc";
    }
        .fi-sr-shish-kebab:before {
    content: "\f9cd";
    }
        .fi-sr-shoe-prints:before {
    content: "\f9ce";
    }
        .fi-sr-shop-lock:before {
    content: "\f9cf";
    }
        .fi-sr-shop-slash:before {
    content: "\f9d0";
    }
        .fi-sr-shop:before {
    content: "\f9d1";
    }
        .fi-sr-shopping-bag-add:before {
    content: "\f9d2";
    }
        .fi-sr-shopping-bag:before {
    content: "\f9d3";
    }
        .fi-sr-shopping-basket:before {
    content: "\f9d4";
    }
        .fi-sr-shopping-cart-add:before {
    content: "\f9d5";
    }
        .fi-sr-shopping-cart-check:before {
    content: "\f9d6";
    }
        .fi-sr-shopping-cart:before {
    content: "\f9d7";
    }
        .fi-sr-shovel-snow:before {
    content: "\f9d8";
    }
        .fi-sr-shovel:before {
    content: "\f9d9";
    }
        .fi-sr-shower-down:before {
    content: "\f9da";
    }
        .fi-sr-shower:before {
    content: "\f9db";
    }
        .fi-sr-shredder:before {
    content: "\f9dc";
    }
        .fi-sr-shrimp:before {
    content: "\f9dd";
    }
        .fi-sr-shuffle:before {
    content: "\f9de";
    }
        .fi-sr-shuttle-van:before {
    content: "\f9df";
    }
        .fi-sr-shuttlecock:before {
    content: "\f9e0";
    }
        .fi-sr-Sickle:before {
    content: "\f9e1";
    }
        .fi-sr-sidebar-flip:before {
    content: "\f9e2";
    }
        .fi-sr-sidebar:before {
    content: "\f9e3";
    }
        .fi-sr-sigma:before {
    content: "\f9e4";
    }
        .fi-sr-sign-hanging:before {
    content: "\f9e5";
    }
        .fi-sr-sign-in-alt:before {
    content: "\f9e6";
    }
        .fi-sr-sign-out-alt:before {
    content: "\f9e7";
    }
        .fi-sr-sign-up:before {
    content: "\f9e8";
    }
        .fi-sr-signal-alt-1:before {
    content: "\f9e9";
    }
        .fi-sr-signal-alt-2:before {
    content: "\f9ea";
    }
        .fi-sr-signal-alt-slash:before {
    content: "\f9eb";
    }
        .fi-sr-signal-alt:before {
    content: "\f9ec";
    }
        .fi-sr-signal-bars-fair:before {
    content: "\f9ed";
    }
        .fi-sr-signal-bars-good:before {
    content: "\f9ee";
    }
        .fi-sr-signal-bars-weak:before {
    content: "\f9ef";
    }
        .fi-sr-signal-stream-slash:before {
    content: "\f9f0";
    }
        .fi-sr-signal-stream:before {
    content: "\f9f1";
    }
        .fi-sr-signature-lock:before {
    content: "\f9f2";
    }
        .fi-sr-signature-slash:before {
    content: "\f9f3";
    }
        .fi-sr-signature:before {
    content: "\f9f4";
    }
        .fi-sr-sim-card:before {
    content: "\f9f5";
    }
        .fi-sr-sim-cards:before {
    content: "\f9f6";
    }
        .fi-sr-sink:before {
    content: "\f9f7";
    }
        .fi-sr-siren-on:before {
    content: "\f9f8";
    }
        .fi-sr-Siren:before {
    content: "\f9f9";
    }
        .fi-sr-site-alt:before {
    content: "\f9fa";
    }
        .fi-sr-site-browser:before {
    content: "\f9fb";
    }
        .fi-sr-site:before {
    content: "\f9fc";
    }
        .fi-sr-sitemap:before {
    content: "\f9fd";
    }
        .fi-sr-skateboard:before {
    content: "\f9fe";
    }
        .fi-sr-skating:before {
    content: "\f9ff";
    }
        .fi-sr-skeleton:before {
    content: "\fa00";
    }
        .fi-sr-skewer:before {
    content: "\fa01";
    }
        .fi-sr-ski-boot-ski:before {
    content: "\fa02";
    }
        .fi-sr-ski-jump:before {
    content: "\fa03";
    }
        .fi-sr-ski-lift:before {
    content: "\fa04";
    }
        .fi-sr-skiing-nordic:before {
    content: "\fa05";
    }
        .fi-sr-skiing:before {
    content: "\fa06";
    }
        .fi-sr-skull-cow:before {
    content: "\fa07";
    }
        .fi-sr-skull-crossbones:before {
    content: "\fa08";
    }
        .fi-sr-skull:before {
    content: "\fa09";
    }
        .fi-sr-slash:before {
    content: "\fa0a";
    }
        .fi-sr-sledding:before {
    content: "\fa0b";
    }
        .fi-sr-sleigh:before {
    content: "\fa0c";
    }
        .fi-sr-sliders-h-square:before {
    content: "\fa0d";
    }
        .fi-sr-sliders-v-square:before {
    content: "\fa0e";
    }
        .fi-sr-sliders-v:before {
    content: "\fa0f";
    }
        .fi-sr-smartphone:before {
    content: "\fa10";
    }
        .fi-sr-smile-beam:before {
    content: "\fa11";
    }
        .fi-sr-smile-plus:before {
    content: "\fa12";
    }
        .fi-sr-smile-wink:before {
    content: "\fa13";
    }
        .fi-sr-smile:before {
    content: "\fa14";
    }
        .fi-sr-smiley-comment-alt:before {
    content: "\fa15";
    }
        .fi-sr-smog:before {
    content: "\fa16";
    }
        .fi-sr-smoke:before {
    content: "\fa17";
    }
        .fi-sr-smoking-ban:before {
    content: "\fa18";
    }
        .fi-sr-smoking:before {
    content: "\fa19";
    }
        .fi-sr-snake:before {
    content: "\fa1a";
    }
        .fi-sr-snooze:before {
    content: "\fa1b";
    }
        .fi-sr-snow-blowing:before {
    content: "\fa1c";
    }
        .fi-sr-snowboarding:before {
    content: "\fa1d";
    }
        .fi-sr-snowflake-droplets:before {
    content: "\fa1e";
    }
        .fi-sr-snowflake:before {
    content: "\fa1f";
    }
        .fi-sr-snowflakes:before {
    content: "\fa20";
    }
        .fi-sr-snowman-alt:before {
    content: "\fa21";
    }
        .fi-sr-snowman-head:before {
    content: "\fa22";
    }
        .fi-sr-snowmobile:before {
    content: "\fa23";
    }
        .fi-sr-snowplow:before {
    content: "\fa24";
    }
        .fi-sr-soap-alt:before {
    content: "\fa25";
    }
        .fi-sr-soap:before {
    content: "\fa26";
    }
        .fi-sr-socks:before {
    content: "\fa27";
    }
        .fi-sr-solar-panel:before {
    content: "\fa28";
    }
        .fi-sr-solar-system:before {
    content: "\fa29";
    }
        .fi-sr-sort-alpha-down-alt:before {
    content: "\fa2a";
    }
        .fi-sr-sort-alpha-down:before {
    content: "\fa2b";
    }
        .fi-sr-sort-alpha-up-alt:before {
    content: "\fa2c";
    }
        .fi-sr-sort-alpha-up:before {
    content: "\fa2d";
    }
        .fi-sr-sort-alt:before {
    content: "\fa2e";
    }
        .fi-sr-sort-amount-down-alt:before {
    content: "\fa2f";
    }
        .fi-sr-sort-amount-down:before {
    content: "\fa30";
    }
        .fi-sr-sort-amount-up-alt:before {
    content: "\fa31";
    }
        .fi-sr-sort-amount-up:before {
    content: "\fa32";
    }
        .fi-sr-sort-circle-down:before {
    content: "\fa33";
    }
        .fi-sr-sort-circle-up:before {
    content: "\fa34";
    }
        .fi-sr-sort-circle:before {
    content: "\fa35";
    }
        .fi-sr-sort-down:before {
    content: "\fa36";
    }
        .fi-sr-sort-numeric-down-alt:before {
    content: "\fa37";
    }
        .fi-sr-sort-numeric-down:before {
    content: "\fa38";
    }
        .fi-sr-sort-shapes-down:before {
    content: "\fa39";
    }
        .fi-sr-sort-shapes-up:before {
    content: "\fa3a";
    }
        .fi-sr-sort-size-down:before {
    content: "\fa3b";
    }
        .fi-sr-sort-size-up:before {
    content: "\fa3c";
    }
        .fi-sr-sort:before {
    content: "\fa3d";
    }
        .fi-sr-soup:before {
    content: "\fa3e";
    }
        .fi-sr-source-data:before {
    content: "\fa3f";
    }
        .fi-sr-spa:before {
    content: "\fa40";
    }
        .fi-sr-space-shuttle:before {
    content: "\fa41";
    }
        .fi-sr-space-station-moon-alt:before {
    content: "\fa42";
    }
        .fi-sr-space-station-moon:before {
    content: "\fa43";
    }
        .fi-sr-spade:before {
    content: "\fa44";
    }
        .fi-sr-spaghetti-monster-flying:before {
    content: "\fa45";
    }
        .fi-sr-sparkles:before {
    content: "\fa46";
    }
        .fi-sr-speaker:before {
    content: "\fa47";
    }
        .fi-sr-speakers:before {
    content: "\fa48";
    }
        .fi-sr-sphere:before {
    content: "\fa49";
    }
        .fi-sr-spider-black-widow:before {
    content: "\fa4a";
    }
        .fi-sr-spider-web:before {
    content: "\fa4b";
    }
        .fi-sr-spider:before {
    content: "\fa4c";
    }
        .fi-sr-spinner:before {
    content: "\fa4d";
    }
        .fi-sr-split:before {
    content: "\fa4e";
    }
        .fi-sr-splotch:before {
    content: "\fa4f";
    }
        .fi-sr-spoon:before {
    content: "\fa50";
    }
        .fi-sr-spray-can-sparkles:before {
    content: "\fa51";
    }
        .fi-sr-spray-can:before {
    content: "\fa52";
    }
        .fi-sr-sprinkler:before {
    content: "\fa53";
    }
        .fi-sr-square-0:before {
    content: "\fa54";
    }
        .fi-sr-square-1:before {
    content: "\fa55";
    }
        .fi-sr-square-2:before {
    content: "\fa56";
    }
        .fi-sr-square-3:before {
    content: "\fa57";
    }
        .fi-sr-square-4:before {
    content: "\fa58";
    }
        .fi-sr-square-5:before {
    content: "\fa59";
    }
        .fi-sr-square-6:before {
    content: "\fa5a";
    }
        .fi-sr-square-7:before {
    content: "\fa5b";
    }
        .fi-sr-square-8:before {
    content: "\fa5c";
    }
        .fi-sr-square-9:before {
    content: "\fa5d";
    }
        .fi-sr-square-a:before {
    content: "\fa5e";
    }
        .fi-sr-square-b:before {
    content: "\fa5f";
    }
        .fi-sr-square-bolt:before {
    content: "\fa60";
    }
        .fi-sr-square-c:before {
    content: "\fa61";
    }
        .fi-sr-square-code:before {
    content: "\fa62";
    }
        .fi-sr-square-d:before {
    content: "\fa63";
    }
        .fi-sr-square-dashed:before {
    content: "\fa64";
    }
        .fi-sr-square-e:before {
    content: "\fa65";
    }
        .fi-sr-square-ellipsis-vertical:before {
    content: "\fa66";
    }
        .fi-sr-square-ellipsis:before {
    content: "\fa67";
    }
        .fi-sr-square-exclamation:before {
    content: "\fa68";
    }
        .fi-sr-square-f:before {
    content: "\fa69";
    }
        .fi-sr-square-g:before {
    content: "\fa6a";
    }
        .fi-sr-square-h:before {
    content: "\fa6b";
    }
        .fi-sr-square-heart:before {
    content: "\fa6c";
    }
        .fi-sr-square-i:before {
    content: "\fa6d";
    }
        .fi-sr-square-info:before {
    content: "\fa6e";
    }
        .fi-sr-square-j:before {
    content: "\fa6f";
    }
        .fi-sr-square-k:before {
    content: "\fa70";
    }
        .fi-sr-square-kanban:before {
    content: "\fa71";
    }
        .fi-sr-square-l:before {
    content: "\fa72";
    }
        .fi-sr-square-m:before {
    content: "\fa73";
    }
        .fi-sr-square-minus:before {
    content: "\fa74";
    }
        .fi-sr-square-n:before {
    content: "\fa75";
    }
        .fi-sr-square-o:before {
    content: "\fa76";
    }
        .fi-sr-square-p:before {
    content: "\fa77";
    }
        .fi-sr-square-phone-hangup:before {
    content: "\fa78";
    }
        .fi-sr-square-plus:before {
    content: "\fa79";
    }
        .fi-sr-square-poll-horizontal:before {
    content: "\fa7a";
    }
        .fi-sr-square-poll-vertical:before {
    content: "\fa7b";
    }
        .fi-sr-square-q:before {
    content: "\fa7c";
    }
        .fi-sr-square-quote:before {
    content: "\fa7d";
    }
        .fi-sr-square-r:before {
    content: "\fa7e";
    }
        .fi-sr-square-root:before {
    content: "\fa7f";
    }
        .fi-sr-square-s:before {
    content: "\fa80";
    }
        .fi-sr-square-small:before {
    content: "\fa81";
    }
        .fi-sr-square-star:before {
    content: "\fa82";
    }
        .fi-sr-square-t:before {
    content: "\fa83";
    }
        .fi-sr-square-terminal:before {
    content: "\fa84";
    }
        .fi-sr-square-u:before {
    content: "\fa85";
    }
        .fi-sr-square-up-right:before {
    content: "\fa86";
    }
        .fi-sr-square-v:before {
    content: "\fa87";
    }
        .fi-sr-square-w:before {
    content: "\fa88";
    }
        .fi-sr-square-x:before {
    content: "\fa89";
    }
        .fi-sr-square-y:before {
    content: "\fa8a";
    }
        .fi-sr-square-z:before {
    content: "\fa8b";
    }
        .fi-sr-square:before {
    content: "\fa8c";
    }
        .fi-sr-squid:before {
    content: "\fa8d";
    }
        .fi-sr-squircle:before {
    content: "\fa8e";
    }
        .fi-sr-squirrel:before {
    content: "\fa8f";
    }
        .fi-sr-staff:before {
    content: "\fa90";
    }
        .fi-sr-stairs:before {
    content: "\fa91";
    }
        .fi-sr-stamp:before {
    content: "\fa92";
    }
        .fi-sr-standard-definition:before {
    content: "\fa93";
    }
        .fi-sr-star-and-crescent:before {
    content: "\fa94";
    }
        .fi-sr-star-christmas:before {
    content: "\fa95";
    }
        .fi-sr-star-comment-alt:before {
    content: "\fa96";
    }
        .fi-sr-star-exclamation:before {
    content: "\fa97";
    }
        .fi-sr-star-octogram:before {
    content: "\fa98";
    }
        .fi-sr-star-of-david:before {
    content: "\fa99";
    }
        .fi-sr-star-sharp-half-stroke:before {
    content: "\fa9a";
    }
        .fi-sr-star-sharp-half:before {
    content: "\fa9b";
    }
        .fi-sr-star-shooting:before {
    content: "\fa9c";
    }
        .fi-sr-star:before {
    content: "\fa9d";
    }
        .fi-sr-starfighter:before {
    content: "\fa9e";
    }
        .fi-sr-stars:before {
    content: "\fa9f";
    }
        .fi-sr-state-country:before {
    content: "\faa0";
    }
        .fi-sr-stats:before {
    content: "\faa1";
    }
        .fi-sr-steak:before {
    content: "\faa2";
    }
        .fi-sr-steering-wheel:before {
    content: "\faa3";
    }
        .fi-sr-step-backward:before {
    content: "\faa4";
    }
        .fi-sr-step-forward:before {
    content: "\faa5";
    }
        .fi-sr-sterling-sign:before {
    content: "\faa6";
    }
        .fi-sr-stethoscope:before {
    content: "\faa7";
    }
        .fi-sr-sticker:before {
    content: "\faa8";
    }
        .fi-sr-stocking:before {
    content: "\faa9";
    }
        .fi-sr-stomach:before {
    content: "\faaa";
    }
        .fi-sr-stop-circle:before {
    content: "\faab";
    }
        .fi-sr-stop:before {
    content: "\faac";
    }
        .fi-sr-stopwatch:before {
    content: "\faad";
    }
        .fi-sr-store-alt:before {
    content: "\faae";
    }
        .fi-sr-store-lock:before {
    content: "\faaf";
    }
        .fi-sr-store-slash:before {
    content: "\fab0";
    }
        .fi-sr-strawberry:before {
    content: "\fab1";
    }
        .fi-sr-street-view:before {
    content: "\fab2";
    }
        .fi-sr-stretcher:before {
    content: "\fab3";
    }
        .fi-sr-strikethrough:before {
    content: "\fab4";
    }
        .fi-sr-stroopwafel:before {
    content: "\fab5";
    }
        .fi-sr-subscript:before {
    content: "\fab6";
    }
        .fi-sr-subscription-alt:before {
    content: "\fab7";
    }
        .fi-sr-subscription:before {
    content: "\fab8";
    }
        .fi-sr-subtitles:before {
    content: "\fab9";
    }
        .fi-sr-subway:before {
    content: "\faba";
    }
        .fi-sr-suitcase-alt:before {
    content: "\fabb";
    }
        .fi-sr-summer:before {
    content: "\fabc";
    }
        .fi-sr-sun-dust:before {
    content: "\fabd";
    }
        .fi-sr-sun-plant-wilt:before {
    content: "\fabe";
    }
        .fi-sr-sun:before {
    content: "\fabf";
    }
        .fi-sr-sunglasses:before {
    content: "\fac0";
    }
        .fi-sr-sunrise-alt:before {
    content: "\fac1";
    }
        .fi-sr-sunrise:before {
    content: "\fac2";
    }
        .fi-sr-sunset:before {
    content: "\fac3";
    }
        .fi-sr-superscript:before {
    content: "\fac4";
    }
        .fi-sr-surfing:before {
    content: "\fac5";
    }
        .fi-sr-surprise:before {
    content: "\fac6";
    }
        .fi-sr-sushi-alt:before {
    content: "\fac7";
    }
        .fi-sr-sushi-roll:before {
    content: "\fac8";
    }
        .fi-sr-sushi:before {
    content: "\fac9";
    }
        .fi-sr-swatchbook:before {
    content: "\faca";
    }
        .fi-sr-swimmer:before {
    content: "\facb";
    }
        .fi-sr-swimming-pool:before {
    content: "\facc";
    }
        .fi-sr-swipe-down:before {
    content: "\facd";
    }
        .fi-sr-swipe-left:before {
    content: "\face";
    }
        .fi-sr-swipe-right:before {
    content: "\facf";
    }
        .fi-sr-swipe-up:before {
    content: "\fad0";
    }
        .fi-sr-sword:before {
    content: "\fad1";
    }
        .fi-sr-symbol:before {
    content: "\fad2";
    }
        .fi-sr-symbols:before {
    content: "\fad3";
    }
        .fi-sr-syringe:before {
    content: "\fad4";
    }
        .fi-sr-t:before {
    content: "\fad5";
    }
        .fi-sr-tab-folder:before {
    content: "\fad6";
    }
        .fi-sr-table-columns:before {
    content: "\fad7";
    }
        .fi-sr-table-layout:before {
    content: "\fad8";
    }
        .fi-sr-table-list:before {
    content: "\fad9";
    }
        .fi-sr-table-picnic:before {
    content: "\fada";
    }
        .fi-sr-table-pivot:before {
    content: "\fadb";
    }
        .fi-sr-table-rows:before {
    content: "\fadc";
    }
        .fi-sr-table-tree:before {
    content: "\fadd";
    }
        .fi-sr-table:before {
    content: "\fade";
    }
        .fi-sr-tablet-android-alt:before {
    content: "\fadf";
    }
        .fi-sr-tablet-android:before {
    content: "\fae0";
    }
        .fi-sr-tablet-rugged:before {
    content: "\fae1";
    }
        .fi-sr-tablet:before {
    content: "\fae2";
    }
        .fi-sr-tachometer-alt-average:before {
    content: "\fae3";
    }
        .fi-sr-tachometer-alt-fastest:before {
    content: "\fae4";
    }
        .fi-sr-tachometer-alt-slow:before {
    content: "\fae5";
    }
        .fi-sr-tachometer-alt-slowest:before {
    content: "\fae6";
    }
        .fi-sr-tachometer-average:before {
    content: "\fae7";
    }
        .fi-sr-tachometer-fast:before {
    content: "\fae8";
    }
        .fi-sr-tachometer-fastest:before {
    content: "\fae9";
    }
        .fi-sr-tachometer-slow:before {
    content: "\faea";
    }
        .fi-sr-tachometer-slowest:before {
    content: "\faeb";
    }
        .fi-sr-tachometer:before {
    content: "\faec";
    }
        .fi-sr-taco:before {
    content: "\faed";
    }
        .fi-sr-tags:before {
    content: "\faee";
    }
        .fi-sr-tally-1:before {
    content: "\faef";
    }
        .fi-sr-tally-2:before {
    content: "\faf0";
    }
        .fi-sr-tally-3:before {
    content: "\faf1";
    }
        .fi-sr-tally-4:before {
    content: "\faf2";
    }
        .fi-sr-tally:before {
    content: "\faf3";
    }
        .fi-sr-tamale:before {
    content: "\faf4";
    }
        .fi-sr-tank-water:before {
    content: "\faf5";
    }
        .fi-sr-tap:before {
    content: "\faf6";
    }
        .fi-sr-tape:before {
    content: "\faf7";
    }
        .fi-sr-target:before {
    content: "\faf8";
    }
        .fi-sr-tax-alt:before {
    content: "\faf9";
    }
        .fi-sr-tax:before {
    content: "\fafa";
    }
        .fi-sr-taxi-bus:before {
    content: "\fafb";
    }
        .fi-sr-taxi:before {
    content: "\fafc";
    }
        .fi-sr-team-check-alt:before {
    content: "\fafd";
    }
        .fi-sr-team-check:before {
    content: "\fafe";
    }
        .fi-sr-teddy-bear:before {
    content: "\faff";
    }
        .fi-sr-teeth-open:before {
    content: "\fb00";
    }
        .fi-sr-telescope:before {
    content: "\fb01";
    }
        .fi-sr-temperature-down:before {
    content: "\fb02";
    }
        .fi-sr-temperature-frigid:before {
    content: "\fb03";
    }
        .fi-sr-temperature-high:before {
    content: "\fb04";
    }
        .fi-sr-temperature-list:before {
    content: "\fb05";
    }
        .fi-sr-temperature-low:before {
    content: "\fb06";
    }
        .fi-sr-temperature-up:before {
    content: "\fb07";
    }
        .fi-sr-template-alt:before {
    content: "\fb08";
    }
        .fi-sr-template:before {
    content: "\fb09";
    }
        .fi-sr-tenge:before {
    content: "\fb0a";
    }
        .fi-sr-tennis:before {
    content: "\fb0b";
    }
        .fi-sr-tent-arrow-down-to-line:before {
    content: "\fb0c";
    }
        .fi-sr-tent-arrow-left-right:before {
    content: "\fb0d";
    }
        .fi-sr-tent-arrow-turn-left:before {
    content: "\fb0e";
    }
        .fi-sr-tent-arrows-down:before {
    content: "\fb0f";
    }
        .fi-sr-tents:before {
    content: "\fb10";
    }
        .fi-sr-terminal:before {
    content: "\fb11";
    }
        .fi-sr-terrace:before {
    content: "\fb12";
    }
        .fi-sr-test-tube:before {
    content: "\fb13";
    }
        .fi-sr-test:before {
    content: "\fb14";
    }
        .fi-sr-text-box-dots:before {
    content: "\fb15";
    }
        .fi-sr-text-box-edit:before {
    content: "\fb16";
    }
        .fi-sr-text-box:before {
    content: "\fb17";
    }
        .fi-sr-text-check:before {
    content: "\fb18";
    }
        .fi-sr-text-height:before {
    content: "\fb19";
    }
        .fi-sr-text-shadow:before {
    content: "\fb1a";
    }
        .fi-sr-text-size:before {
    content: "\fb1b";
    }
        .fi-sr-text-slash:before {
    content: "\fb1c";
    }
        .fi-sr-text-width:before {
    content: "\fb1d";
    }
        .fi-sr-text:before {
    content: "\fb1e";
    }
        .fi-sr-theater-masks:before {
    content: "\fb1f";
    }
        .fi-sr-thermometer-alt:before {
    content: "\fb20";
    }
        .fi-sr-thermometer-empty:before {
    content: "\fb21";
    }
        .fi-sr-thermometer-full:before {
    content: "\fb22";
    }
        .fi-sr-thermometer-half:before {
    content: "\fb23";
    }
        .fi-sr-thermometer-quarter:before {
    content: "\fb24";
    }
        .fi-sr-thermometer-three-quarters:before {
    content: "\fb25";
    }
        .fi-sr-theta:before {
    content: "\fb26";
    }
        .fi-sr-third-award:before {
    content: "\fb27";
    }
        .fi-sr-third-laurel:before {
    content: "\fb28";
    }
        .fi-sr-third-medal:before {
    content: "\fb29";
    }
        .fi-sr-third:before {
    content: "\fb2a";
    }
        .fi-sr-thought-bubble:before {
    content: "\fb2b";
    }
        .fi-sr-thumbs-down:before {
    content: "\fb2c";
    }
        .fi-sr-thumbs-up:before {
    content: "\fb2d";
    }
        .fi-sr-thumbtack:before {
    content: "\fb2e";
    }
        .fi-sr-thunderstorm-moon:before {
    content: "\fb2f";
    }
        .fi-sr-thunderstorm-sun:before {
    content: "\fb30";
    }
        .fi-sr-thunderstorm:before {
    content: "\fb31";
    }
        .fi-sr-ticket-airline:before {
    content: "\fb32";
    }
        .fi-sr-ticket-alt:before {
    content: "\fb33";
    }
        .fi-sr-ticket:before {
    content: "\fb34";
    }
        .fi-sr-tickets-airline:before {
    content: "\fb35";
    }
        .fi-sr-tilde:before {
    content: "\fb36";
    }
        .fi-sr-time-add:before {
    content: "\fb37";
    }
        .fi-sr-time-check:before {
    content: "\fb38";
    }
        .fi-sr-time-delete:before {
    content: "\fb39";
    }
        .fi-sr-time-fast:before {
    content: "\fb3a";
    }
        .fi-sr-time-forward-sixty:before {
    content: "\fb3b";
    }
        .fi-sr-time-forward-ten:before {
    content: "\fb3c";
    }
        .fi-sr-time-forward:before {
    content: "\fb3d";
    }
        .fi-sr-time-half-past:before {
    content: "\fb3e";
    }
        .fi-sr-time-oclock:before {
    content: "\fb3f";
    }
        .fi-sr-time-past:before {
    content: "\fb40";
    }
        .fi-sr-time-quarter-past:before {
    content: "\fb41";
    }
        .fi-sr-time-quarter-to:before {
    content: "\fb42";
    }
        .fi-sr-time-twenty-four:before {
    content: "\fb43";
    }
        .fi-sr-times-hexagon:before {
    content: "\fb44";
    }
        .fi-sr-tint-slash:before {
    content: "\fb45";
    }
        .fi-sr-tire-flat:before {
    content: "\fb46";
    }
        .fi-sr-tire-pressure-warning:before {
    content: "\fb47";
    }
        .fi-sr-tire-rugged:before {
    content: "\fb48";
    }
        .fi-sr-tire:before {
    content: "\fb49";
    }
        .fi-sr-tired:before {
    content: "\fb4a";
    }
        .fi-sr-to-do-alt:before {
    content: "\fb4b";
    }
        .fi-sr-to-do:before {
    content: "\fb4c";
    }
        .fi-sr-toggle-off:before {
    content: "\fb4d";
    }
        .fi-sr-toggle-on:before {
    content: "\fb4e";
    }
        .fi-sr-toilet-paper-blank-under:before {
    content: "\fb4f";
    }
        .fi-sr-toilet-paper-blank:before {
    content: "\fb50";
    }
        .fi-sr-toilet-paper-slash:before {
    content: "\fb51";
    }
        .fi-sr-toilet-paper-under-slash:before {
    content: "\fb52";
    }
        .fi-sr-toilet-paper-under:before {
    content: "\fb53";
    }
        .fi-sr-toilet:before {
    content: "\fb54";
    }
        .fi-sr-tomato:before {
    content: "\fb55";
    }
        .fi-sr-tombstone-alt:before {
    content: "\fb56";
    }
        .fi-sr-tombstone:before {
    content: "\fb57";
    }
        .fi-sr-tool-box:before {
    content: "\fb58";
    }
        .fi-sr-tool-crop:before {
    content: "\fb59";
    }
        .fi-sr-tool-marquee:before {
    content: "\fb5a";
    }
        .fi-sr-tools:before {
    content: "\fb5b";
    }
        .fi-sr-tooth:before {
    content: "\fb5c";
    }
        .fi-sr-toothbrush:before {
    content: "\fb5d";
    }
        .fi-sr-tornado:before {
    content: "\fb5e";
    }
        .fi-sr-total:before {
    content: "\fb5f";
    }
        .fi-sr-tour-guide-people:before {
    content: "\fb60";
    }
        .fi-sr-tour-virtual:before {
    content: "\fb61";
    }
        .fi-sr-tower-control:before {
    content: "\fb62";
    }
        .fi-sr-track:before {
    content: "\fb63";
    }
        .fi-sr-tractor:before {
    content: "\fb64";
    }
        .fi-sr-trademark:before {
    content: "\fb65";
    }
        .fi-sr-traffic-cone:before {
    content: "\fb66";
    }
        .fi-sr-traffic-light-go:before {
    content: "\fb67";
    }
        .fi-sr-traffic-light-slow:before {
    content: "\fb68";
    }
        .fi-sr-traffic-light-stop:before {
    content: "\fb69";
    }
        .fi-sr-traffic-light:before {
    content: "\fb6a";
    }
        .fi-sr-trailer:before {
    content: "\fb6b";
    }
        .fi-sr-train-journey:before {
    content: "\fb6c";
    }
        .fi-sr-train-side:before {
    content: "\fb6d";
    }
        .fi-sr-train-station-building:before {
    content: "\fb6e";
    }
        .fi-sr-train-station:before {
    content: "\fb6f";
    }
        .fi-sr-train-subway-tunnel:before {
    content: "\fb70";
    }
        .fi-sr-train-tram:before {
    content: "\fb71";
    }
        .fi-sr-train:before {
    content: "\fb72";
    }
        .fi-sr-tram:before {
    content: "\fb73";
    }
        .fi-sr-transform:before {
    content: "\fb74";
    }
        .fi-sr-transformer-bolt:before {
    content: "\fb75";
    }
        .fi-sr-transgender:before {
    content: "\fb76";
    }
        .fi-sr-transporter-1:before {
    content: "\fb77";
    }
        .fi-sr-transporter-2:before {
    content: "\fb78";
    }
        .fi-sr-transporter-3:before {
    content: "\fb79";
    }
        .fi-sr-transporter-4:before {
    content: "\fb7a";
    }
        .fi-sr-transporter-5:before {
    content: "\fb7b";
    }
        .fi-sr-transporter-6:before {
    content: "\fb7c";
    }
        .fi-sr-transporter-7:before {
    content: "\fb7d";
    }
        .fi-sr-transporter-empty:before {
    content: "\fb7e";
    }
        .fi-sr-transporter:before {
    content: "\fb7f";
    }
        .fi-sr-trash-can-check:before {
    content: "\fb80";
    }
        .fi-sr-trash-can-clock:before {
    content: "\fb81";
    }
        .fi-sr-trash-can-list:before {
    content: "\fb82";
    }
        .fi-sr-trash-can-plus:before {
    content: "\fb83";
    }
        .fi-sr-trash-can-slash:before {
    content: "\fb84";
    }
        .fi-sr-trash-check:before {
    content: "\fb85";
    }
        .fi-sr-trash-clock:before {
    content: "\fb86";
    }
        .fi-sr-trash-list:before {
    content: "\fb87";
    }
        .fi-sr-trash-plus:before {
    content: "\fb88";
    }
        .fi-sr-trash-restore-alt:before {
    content: "\fb89";
    }
        .fi-sr-trash-restore:before {
    content: "\fb8a";
    }
        .fi-sr-trash-slash:before {
    content: "\fb8b";
    }
        .fi-sr-trash-undo-alt:before {
    content: "\fb8c";
    }
        .fi-sr-trash-undo:before {
    content: "\fb8d";
    }
        .fi-sr-trash-xmark:before {
    content: "\fb8e";
    }
        .fi-sr-trash:before {
    content: "\fb8f";
    }
        .fi-sr-treasure-chest:before {
    content: "\fb90";
    }
        .fi-sr-treatment:before {
    content: "\fb91";
    }
        .fi-sr-tree-alt:before {
    content: "\fb92";
    }
        .fi-sr-tree-christmas:before {
    content: "\fb93";
    }
        .fi-sr-tree-deciduous:before {
    content: "\fb94";
    }
        .fi-sr-tree:before {
    content: "\fb95";
    }
        .fi-sr-trees-alt:before {
    content: "\fb96";
    }
        .fi-sr-trees:before {
    content: "\fb97";
    }
        .fi-sr-triangle-music:before {
    content: "\fb98";
    }
        .fi-sr-triangle-person-digging:before {
    content: "\fb99";
    }
        .fi-sr-triangle-warning:before {
    content: "\fb9a";
    }
        .fi-sr-triangle:before {
    content: "\fb9b";
    }
        .fi-sr-trillium:before {
    content: "\fb9c";
    }
        .fi-sr-trophy-star:before {
    content: "\fb9d";
    }
        .fi-sr-trophy:before {
    content: "\fb9e";
    }
        .fi-sr-truck-bolt:before {
    content: "\fb9f";
    }
        .fi-sr-truck-container-empty:before {
    content: "\fba0";
    }
        .fi-sr-truck-container:before {
    content: "\fba1";
    }
        .fi-sr-truck-couch:before {
    content: "\fba2";
    }
        .fi-sr-truck-flatbed:before {
    content: "\fba3";
    }
        .fi-sr-truck-front:before {
    content: "\fba4";
    }
        .fi-sr-truck-loading:before {
    content: "\fba5";
    }
        .fi-sr-truck-medical:before {
    content: "\fba6";
    }
        .fi-sr-truck-monster:before {
    content: "\fba7";
    }
        .fi-sr-truck-moving:before {
    content: "\fba8";
    }
        .fi-sr-truck-pickup:before {
    content: "\fba9";
    }
        .fi-sr-truck-plow:before {
    content: "\fbaa";
    }
        .fi-sr-truck-ramp:before {
    content: "\fbab";
    }
        .fi-sr-truck-side:before {
    content: "\fbac";
    }
        .fi-sr-truck-tow:before {
    content: "\fbad";
    }
        .fi-sr-trumpet:before {
    content: "\fbae";
    }
        .fi-sr-tshirt:before {
    content: "\fbaf";
    }
        .fi-sr-tty-answer:before {
    content: "\fbb0";
    }
        .fi-sr-tty:before {
    content: "\fbb1";
    }
        .fi-sr-tubes:before {
    content: "\fbb2";
    }
        .fi-sr-tugrik-sign:before {
    content: "\fbb3";
    }
        .fi-sr-turkey:before {
    content: "\fbb4";
    }
        .fi-sr-turntable:before {
    content: "\fbb5";
    }
        .fi-sr-turtle:before {
    content: "\fbb6";
    }
        .fi-sr-tv-music:before {
    content: "\fbb7";
    }
        .fi-sr-tv-retro:before {
    content: "\fbb8";
    }
        .fi-sr-typewriter:before {
    content: "\fbb9";
    }
        .fi-sr-u:before {
    content: "\fbba";
    }
        .fi-sr-ufo-beam:before {
    content: "\fbbb";
    }
        .fi-sr-ufo:before {
    content: "\fbbc";
    }
        .fi-sr-umbrella-beach:before {
    content: "\fbbd";
    }
        .fi-sr-umbrella:before {
    content: "\fbbe";
    }
        .fi-sr-underline:before {
    content: "\fbbf";
    }
        .fi-sr-undo-alt:before {
    content: "\fbc0";
    }
        .fi-sr-undo:before {
    content: "\fbc1";
    }
        .fi-sr-unicorn:before {
    content: "\fbc2";
    }
        .fi-sr-uniform-martial-arts:before {
    content: "\fbc3";
    }
        .fi-sr-universal-access:before {
    content: "\fbc4";
    }
        .fi-sr-unlock:before {
    content: "\fbc5";
    }
        .fi-sr-up-left:before {
    content: "\fbc6";
    }
        .fi-sr-up-right-from-square:before {
    content: "\fbc7";
    }
        .fi-sr-up-right:before {
    content: "\fbc8";
    }
        .fi-sr-up:before {
    content: "\fbc9";
    }
        .fi-sr-upload:before {
    content: "\fbca";
    }
        .fi-sr-url:before {
    content: "\fbcb";
    }
        .fi-sr-usb-pendrive:before {
    content: "\fbcc";
    }
        .fi-sr-usd-circle:before {
    content: "\fbcd";
    }
        .fi-sr-usd-square:before {
    content: "\fbce";
    }
        .fi-sr-user-add:before {
    content: "\fbcf";
    }
        .fi-sr-user-alien:before {
    content: "\fbd0";
    }
        .fi-sr-user-astronaut:before {
    content: "\fbd1";
    }
        .fi-sr-user-check:before {
    content: "\fbd2";
    }
        .fi-sr-user-chef:before {
    content: "\fbd3";
    }
        .fi-sr-user-cowboy:before {
    content: "\fbd4";
    }
        .fi-sr-user-crown:before {
    content: "\fbd5";
    }
        .fi-sr-user-gear:before {
    content: "\fbd6";
    }
        .fi-sr-user-headset:before {
    content: "\fbd7";
    }
        .fi-sr-user-helmet-safety:before {
    content: "\fbd8";
    }
        .fi-sr-user-injured:before {
    content: "\fbd9";
    }
        .fi-sr-user-interface-ui:before {
    content: "\fbda";
    }
        .fi-sr-user-lock:before {
    content: "\fbdb";
    }
        .fi-sr-user-md-chat:before {
    content: "\fbdc";
    }
        .fi-sr-user-md:before {
    content: "\fbdd";
    }
        .fi-sr-user-minus:before {
    content: "\fbde";
    }
        .fi-sr-user-music:before {
    content: "\fbdf";
    }
        .fi-sr-user-ninja:before {
    content: "\fbe0";
    }
        .fi-sr-user-nurse:before {
    content: "\fbe1";
    }
        .fi-sr-user-pen:before {
    content: "\fbe2";
    }
        .fi-sr-user-pilot-tie:before {
    content: "\fbe3";
    }
        .fi-sr-user-pilot:before {
    content: "\fbe4";
    }
        .fi-sr-user-police:before {
    content: "\fbe5";
    }
        .fi-sr-user-robot-xmarks:before {
    content: "\fbe6";
    }
        .fi-sr-user-robot:before {
    content: "\fbe7";
    }
        .fi-sr-user-shield:before {
    content: "\fbe8";
    }
        .fi-sr-user-slash:before {
    content: "\fbe9";
    }
        .fi-sr-user-tag:before {
    content: "\fbea";
    }
        .fi-sr-user-time:before {
    content: "\fbeb";
    }
        .fi-sr-user-unlock:before {
    content: "\fbec";
    }
        .fi-sr-user-visor:before {
    content: "\fbed";
    }
        .fi-sr-user-xmark:before {
    content: "\fbee";
    }
        .fi-sr-user:before {
    content: "\fbef";
    }
        .fi-sr-users-alt:before {
    content: "\fbf0";
    }
        .fi-sr-users-gear:before {
    content: "\fbf1";
    }
        .fi-sr-users-medical:before {
    content: "\fbf2";
    }
        .fi-sr-users-slash:before {
    content: "\fbf3";
    }
        .fi-sr-users:before {
    content: "\fbf4";
    }
        .fi-sr-utensils:before {
    content: "\fbf5";
    }
        .fi-sr-utility-pole-double:before {
    content: "\fbf6";
    }
        .fi-sr-utility-pole:before {
    content: "\fbf7";
    }
        .fi-sr-v:before {
    content: "\fbf8";
    }
        .fi-sr-vacuum-robot:before {
    content: "\fbf9";
    }
        .fi-sr-vacuum:before {
    content: "\fbfa";
    }
        .fi-sr-value-absolute:before {
    content: "\fbfb";
    }
        .fi-sr-vault:before {
    content: "\fbfc";
    }
        .fi-sr-vector-alt:before {
    content: "\fbfd";
    }
        .fi-sr-vector-circle:before {
    content: "\fbfe";
    }
        .fi-sr-vector-polygon:before {
    content: "\fbff";
    }
        .fi-sr-vector:before {
    content: "\fc00";
    }
        .fi-sr-venus-double:before {
    content: "\fc01";
    }
        .fi-sr-venus-mars:before {
    content: "\fc02";
    }
        .fi-sr-venus:before {
    content: "\fc03";
    }
        .fi-sr-vest-patches:before {
    content: "\fc04";
    }
        .fi-sr-vest:before {
    content: "\fc05";
    }
        .fi-sr-video-arrow-down-left:before {
    content: "\fc06";
    }
        .fi-sr-video-arrow-up-right:before {
    content: "\fc07";
    }
        .fi-sr-video-camera-alt:before {
    content: "\fc08";
    }
        .fi-sr-video-camera:before {
    content: "\fc09";
    }
        .fi-sr-video-plus:before {
    content: "\fc0a";
    }
        .fi-sr-video-slash:before {
    content: "\fc0b";
    }
        .fi-sr-videoconference:before {
    content: "\fc0c";
    }
        .fi-sr-virus-slash:before {
    content: "\fc0d";
    }
        .fi-sr-virus:before {
    content: "\fc0e";
    }
        .fi-sr-viruses:before {
    content: "\fc0f";
    }
        .fi-sr-voicemail:before {
    content: "\fc10";
    }
        .fi-sr-volcano:before {
    content: "\fc11";
    }
        .fi-sr-volleyball:before {
    content: "\fc12";
    }
        .fi-sr-volume-down:before {
    content: "\fc13";
    }
        .fi-sr-volume-mute:before {
    content: "\fc14";
    }
        .fi-sr-volume-off:before {
    content: "\fc15";
    }
        .fi-sr-volume-slash:before {
    content: "\fc16";
    }
        .fi-sr-volume:before {
    content: "\fc17";
    }
        .fi-sr-vote-nay:before {
    content: "\fc18";
    }
        .fi-sr-vote-yea:before {
    content: "\fc19";
    }
        .fi-sr-vr-cardboard:before {
    content: "\fc1a";
    }
        .fi-sr-w:before {
    content: "\fc1b";
    }
        .fi-sr-waffle:before {
    content: "\fc1c";
    }
        .fi-sr-wagon-covered:before {
    content: "\fc1d";
    }
        .fi-sr-walker:before {
    content: "\fc1e";
    }
        .fi-sr-walkie-talkie:before {
    content: "\fc1f";
    }
        .fi-sr-walking:before {
    content: "\fc20";
    }
        .fi-sr-wallet:before {
    content: "\fc21";
    }
        .fi-sr-warehouse-alt:before {
    content: "\fc22";
    }
        .fi-sr-washer:before {
    content: "\fc23";
    }
        .fi-sr-watch-calculator:before {
    content: "\fc24";
    }
        .fi-sr-watch-fitness:before {
    content: "\fc25";
    }
        .fi-sr-watch-smart:before {
    content: "\fc26";
    }
        .fi-sr-watch:before {
    content: "\fc27";
    }
        .fi-sr-water-bottle:before {
    content: "\fc28";
    }
        .fi-sr-water-ladder:before {
    content: "\fc29";
    }
        .fi-sr-water-lower:before {
    content: "\fc2a";
    }
        .fi-sr-water-rise:before {
    content: "\fc2b";
    }
        .fi-sr-water:before {
    content: "\fc2c";
    }
        .fi-sr-watermelon:before {
    content: "\fc2d";
    }
        .fi-sr-wave-sine:before {
    content: "\fc2e";
    }
        .fi-sr-wave-square:before {
    content: "\fc2f";
    }
        .fi-sr-wave-triangle:before {
    content: "\fc30";
    }
        .fi-sr-waveform-path:before {
    content: "\fc31";
    }
        .fi-sr-waveform:before {
    content: "\fc32";
    }
        .fi-sr-webcam-slash:before {
    content: "\fc33";
    }
        .fi-sr-webcam:before {
    content: "\fc34";
    }
        .fi-sr-whale:before {
    content: "\fc35";
    }
        .fi-sr-wheat-awn-circle-exclamation:before {
    content: "\fc36";
    }
        .fi-sr-wheat-awn-slash:before {
    content: "\fc37";
    }
        .fi-sr-wheat-awn:before {
    content: "\fc38";
    }
        .fi-sr-wheat-slash:before {
    content: "\fc39";
    }
        .fi-sr-wheat:before {
    content: "\fc3a";
    }
        .fi-sr-wheelchair-move:before {
    content: "\fc3b";
    }
        .fi-sr-wheelchair:before {
    content: "\fc3c";
    }
        .fi-sr-whistle:before {
    content: "\fc3d";
    }
        .fi-sr-wifi-1:before {
    content: "\fc3e";
    }
        .fi-sr-wifi-2:before {
    content: "\fc3f";
    }
        .fi-sr-wifi-alt:before {
    content: "\fc40";
    }
        .fi-sr-wifi-exclamation:before {
    content: "\fc41";
    }
        .fi-sr-wifi-slash:before {
    content: "\fc42";
    }
        .fi-sr-wifi:before {
    content: "\fc43";
    }
        .fi-sr-wind-turbine:before {
    content: "\fc44";
    }
        .fi-sr-wind-warning:before {
    content: "\fc45";
    }
        .fi-sr-wind:before {
    content: "\fc46";
    }
        .fi-sr-window-alt:before {
    content: "\fc47";
    }
        .fi-sr-window-frame-open:before {
    content: "\fc48";
    }
        .fi-sr-window-frame:before {
    content: "\fc49";
    }
        .fi-sr-window-maximize:before {
    content: "\fc4a";
    }
        .fi-sr-window-minimize:before {
    content: "\fc4b";
    }
        .fi-sr-window-restore:before {
    content: "\fc4c";
    }
        .fi-sr-windsock:before {
    content: "\fc4d";
    }
        .fi-sr-wine-bottle:before {
    content: "\fc4e";
    }
        .fi-sr-wine-glass-crack:before {
    content: "\fc4f";
    }
        .fi-sr-wine-glass-empty:before {
    content: "\fc50";
    }
        .fi-sr-wishlist-heart:before {
    content: "\fc51";
    }
        .fi-sr-wishlist-star:before {
    content: "\fc52";
    }
        .fi-sr-woman-head:before {
    content: "\fc53";
    }
        .fi-sr-won-sign:before {
    content: "\fc54";
    }
        .fi-sr-workflow-alt:before {
    content: "\fc55";
    }
        .fi-sr-workflow-setting-alt:before {
    content: "\fc56";
    }
        .fi-sr-workflow-setting:before {
    content: "\fc57";
    }
        .fi-sr-workflow:before {
    content: "\fc58";
    }
        .fi-sr-world:before {
    content: "\fc59";
    }
        .fi-sr-worm:before {
    content: "\fc5a";
    }
        .fi-sr-wreath:before {
    content: "\fc5b";
    }
        .fi-sr-wrench-alt:before {
    content: "\fc5c";
    }
        .fi-sr-wrench-simple:before {
    content: "\fc5d";
    }
        .fi-sr-x-ray:before {
    content: "\fc5e";
    }
        .fi-sr-x:before {
    content: "\fc5f";
    }
        .fi-sr-y:before {
    content: "\fc60";
    }
        .fi-sr-yen:before {
    content: "\fc61";
    }
        .fi-sr-yin-yang:before {
    content: "\fc62";
    }
        .fi-sr-z:before {
    content: "\fc63";
    }
        .fi-sr-zoom-in:before {
    content: "\fc64";
    }
        .fi-sr-zoom-out:before {
    content: "\fc65";
    }
